import React from 'react';
const Store = ({store, district, city}) => {
    return <div className="col-12">
        <div className="item-ads-table mt-3">
            <table>
                <tbody>
                    <tr>
                        <td>Nhà thuốc</td>
                        <td>{store.name}</td>
                    </tr>
                    <tr>
                        <td>Địa chỉ</td>
                        <td>{`${store.streetNumber ? store.streetNumber : '-'} ${store.street ? store.street : '-'}, ${store.wardName ? store.wardName : '-'}, ${district ? district.name : '-'}, ${city ? city.name : '-'}`}</td>
                    </tr>
                    <tr>
                        <td>Người hưởng phí quảng cáo</td>
                        <td>{store.paymentAccountName}</td>
                    </tr>
                    <tr>
                        <td>CMND số</td>
                        <td>{store.paymentIdentityCard}</td>
                    </tr>
                    <tr>
                        <td>Tài khoản ngân hàng</td>
                        <td>{store.paymentAccountNumber}</td>
                    </tr>
                    <tr>
                        <td>Ngân hàng</td>
                        <td>{store.paymentBankName}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
}

export default Store;