import Swal from 'sweetalert2';
import * as Constants from 'constants/Constants';
import store from 'reduxs/index';

export default class CommonService {

    getHeader(hasCache = true, token = '') {
        const cacheControl = !hasCache ? { 'cache-control': 'no-cache' } : {};
        const state = store.getState();
        return {
            'Content-Type': 'multipart/form-data;charset=UTF-8',
            'Token': token ? token : localStorage.token,
            'Lat': state.settings.coord.lat,
            'Long': state.settings.coord.long,
            accept: 'application/json',
            ...cacheControl
        };
    }

    handleErrorHttp(err) {
        const errorResponse = err.response ? err.response.data : err.response;
        const statusCode = err.response && err.response.status ? err.response.status : 0;
        
        if (JSON.stringify(err) !== '{}') {
            if (errorResponse) {
                if (statusCode === 500) {
                    Swal.fire({
                        ...Constants.SWAL_COMMON_STYLE,
                        text: 'Có lỗi xảy ra. Vui lòng liên hệ Administrator để được giải quyết.',
                        type: 'error',
                        confirmButtonText: 'Đóng'
                    });
                } else {
                    Swal.fire({
                        ...Constants.SWAL_COMMON_STYLE,
                        title: errorResponse.title ? errorResponse.title : '',
                        text: errorResponse.message ? errorResponse.message : '',
                        type: 'error',
                        confirmButtonText: 'Đóng'
                    }).then(() => {
                        if (statusCode === 401) {
                            localStorage.token = '';
                            localStorage.cityId = '';
                            localStorage.districtId = '';
                            document.location.reload();
                        }
                    });
                }
            } else if (!errorResponse && err) {
                Swal.fire({
                    ...Constants.SWAL_COMMON_STYLE,
                    text: 'Không thể kết nối đến máy chủ.',
                    type: 'error',
                    confirmButtonText: 'Đóng'
                });
            }
        }
        throw errorResponse;
    }
}
