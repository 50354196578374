import React from 'react';
import { PageProps } from 'interfaces';
import { withRouter } from 'react-router-dom';
import store from 'reduxs/index';
import { setLoading } from 'reduxs/actions/Settings';

interface State {
    page: number;
    showMore: boolean;
    data: {
        name: string;
        url: string;
    }[];
}

interface Props {
    index: number;
    documentGroupId: string;
    documentGroupName: string;
}

class Section extends React.Component<PageProps & Props, State> {
    constructor(props: PageProps & Props) {
        super(props);
        this.state = {
            page: 0,
            data: [],
            showMore: false
        }
    }

    componentDidMount() {
        this.getData();
    }

    getData = () => {
        const { page } = this.state;
        const { documentGroupId } = this.props;
        store.dispatch(setLoading(true));
        this.props.apiService.getDocument(page, documentGroupId).then(res => {
            this.setState(prevState => ({ data: [...prevState.data, ...res], showMore: res.length === 3 }));
            store.dispatch(setLoading(false));
        });
    }

    loadMore = () => {
        this.setState(prevState => ({ page: prevState.page + 1 }), () => this.getData());
    }


    // Custom Code
    render() {
        const { data, showMore } = this.state;
        const { documentGroupName, index } = this.props;
        return (
            <React.Fragment>
                <div className="item-ads-table mt-3">
                    <table>
                        <thead>
                            <tr>
                                <th colSpan={2}>{index}. {documentGroupName}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.length !== 0 && data.map((e, key) =>
                                <tr key={key}>
                                    <td><div>
                                        {e.url && <a href={e.url} className="no-wrap" target="_blank" rel="noopener noreferrer">Xem chi tiết</a>}
                                        {!e.url && <>-</>}
                                    </div></td>
                                    <td><div className="text-value bigger">{e.name}</div></td>
                                </tr>)}

                            {data.length === 0 && <tr><td colSpan={2}>Không có dữ liệu</td></tr>}
                        </tbody>
                    </table>
                </div>
                {showMore && <div className="mt-1">
                    <button onClick={() => this.loadMore()} className="btn btn-primary btn-block">Xem thêm</button>
                </div>}
            </React.Fragment>
        );
    }
}

export default withRouter(Section);