import { Field } from "models/Settings";
import Calendar from "models/Calendar";

export const getFields = () => {
    const fields: Field[] =  [
        {
            key: "calendar.place",
            isRequired: false,
            isVisible: true,
            regex: "",
            maxLength: 50,
            name: "Place"
        },
        {
            key: "calendar.visited",
            isRequired: false,
            isVisible: true,
            regex: "",
            maxLength: 50,
            name: "Visited"
        },
        {
            key: "calendar.sale_item_count",
            isRequired: false,
            isVisible: true,
            regex: "",
            maxLength: 50,
            name: "Sale Item Count"
        },
        {
            key: "calendar.sale_ads_count",
            isRequired: false,
            isVisible: true,
            regex: "",
            maxLength: 50,
            name: "Sale Ads Count"
        },
        {
            key: "calendar.revenue",
            isRequired: false,
            isVisible: true,
            regex: "",
            maxLength: 50,
            name: "Revenue"
        },
        {
            key: "calendar.note",
            isRequired: false,
            isVisible: true,
            regex: "",
            maxLength: 50,
            name: "Note"
        }
    ];

    return fields;
}


export const getFormFields = (formData, cells) => {
    let formFields: any[] = [];
    cells.forEach(cell => {

        const calendar: Calendar = formData.calendar[cell.day] ? formData.calendar[cell.day] : new Calendar();
        formFields.push({
            row1: {
                props: {
                    type: 'text',
                    className: '',
                    data: calendar.place,
                    htmlFor: 'calendar[' + cell.day + '][place]',
                    fieldName: 'calendar[' + cell.day + '][place]'
                },
            },
            row2: {
                props: {
                    type: 'number',
                    className: '',
                    data: calendar.visited !== 0 ? calendar.visited : '',
                    htmlFor: 'calendar[' + cell.day + '][visited]',
                    fieldName: 'calendar[' + cell.day + '][visited]'
                },
            },
            row3: {
                props: {
                    type: 'number',
                    className: '',
                    data: calendar.saleItemCount !== 0 ? calendar.saleItemCount : '',
                    htmlFor: 'calendar[' + cell.day + '][sale_item_count]',
                    fieldName: 'calendar[' + cell.day + '][sale_item_count]'
                },
            },
            row4: {
                props: {
                    type: 'number',
                    className: '',
                    data: calendar.saleAdsCount !== 0 ? calendar.saleAdsCount : '',
                    htmlFor: 'calendar[' + cell.day + '][sale_ads_count]',
                    fieldName: 'calendar[' + cell.day + '][sale_ads_count]'
                },
            },
            row5: {
                props: {
                    type: 'number',
                    className: '',
                    data: calendar.revenuePlan !== 0 ? calendar.revenuePlan : '',
                    htmlFor: 'calendar[' + cell.day + '][revenuePlan]',
                    fieldName: 'calendar[' + cell.day + '][revenuePlan]'
                },
            },
            row6: {
                props: {
                    type: 'number',
                    className: '',
                    data: calendar.revenueReport !== 0 ? calendar.revenueReport : '',
                    htmlFor: 'calendar[' + cell.day + '][revenueReport]',
                    fieldName: 'calendar[' + cell.day + '][revenueReport]'
                },
            },
            row7: {
                props: {
                    type: 'text',
                    className: '',
                    data: calendar.note,
                    htmlFor: 'calendar[' + cell.day + '][note]',
                    fieldName: 'calendar[' + cell.day + '][note]'
                },
            }
        });
    });

    return formFields;
}