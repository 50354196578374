import React from 'react';
import { connect } from 'react-redux';
import { PageProps } from 'interfaces';
import { withRouter } from 'react-router-dom';
import * as Routes from 'pages/RouteLoader';
import store from 'reduxs/index';
import Swal from 'sweetalert2';
import { setLoading } from 'reduxs/actions/Settings';
import FormItem, { setStateByProperty, checkValidations, getIdOrUndefined } from 'components/Form';
import Store from 'models/Store';
import { Field, City, District } from 'models/Settings';
import { getFields, getFormFieldsRoute } from './fields';
import { getFormFieldsSearchStore } from 'pages/RequestStorePage/fields';
import * as Constants from 'constants/Constants';

interface StoreRow {
    storeId: string;
    storename: string;
    streetNumber: string;
    street: string;
    wardName: string;
    districtName: string;
    cityName: string;
}

interface State {
    disabledSent: boolean;
    disabledDelete: boolean;
    isEdit: boolean;
    data: StoreRow[];
    fields: Field[];
    formData: {
        store: Store;
        city: City;
        district: District;
        wardName: string;
        street: string;
        streetNumber: string;
        name: string;
        routeName: string;
    };
}

class RouteCreatePage extends React.Component<PageProps, State> {
    constructor(props: PageProps) {
        super(props);

        let fields = getFields();
        fields = fields.map(e => ({
            ...new Field(), ...e,
            isRequired: e.key !== 'route.routeName' ? false : true,
            isValid: false,
            isValidType: 'required'
        }));

        this.state = {
            disabledSent: true,
            disabledDelete: true,
            isEdit: false,
            data: [],
            fields: fields,
            formData: {
                store: new Store(),
                city: new City(),
                district: new District(),
                wardName: '',
                street: '',
                streetNumber: '',
                name: '',
                routeName: ''
            },
        }
    }

    getData = () => {
        const { formData: { routeName } } = this.state;
        store.dispatch(setLoading(true));
        this.props.apiService.getRouteStores(routeName).then(res => {
            this.setState({
                data: res,
                disabledSent: true,
                disabledDelete: routeName ? false : true
            });
            store.dispatch(setLoading(false));
        })
    }

    resetSearchForm = () => {
        this.setState(prevState => ({formData: {...prevState.formData, store: new Store()}}));
    }

    // Custom Code
    onChangeHandle = (nameHtml, valueHtml) => {
        const { settings: { stores } } = this.props;

        switch (nameHtml) {
            case 'searchStore[name]':
                setStateByProperty(this, 'store', nameHtml, { id: valueHtml, name: '' });
                const existStoreName = stores.find(e => e.id === valueHtml);
                if (existStoreName) {
                    setStateByProperty(this, 'city', 'searchStore[city]', { id: existStoreName.cityId, name: '' });
                    setStateByProperty(this, 'district', 'searchStore[district]', { id: existStoreName.districtId, name: '' });
                    setStateByProperty(this, 'name', 'store[name]', existStoreName.name);
                    setStateByProperty(this, 'wardName', 'store[wardName]', existStoreName.wardName);
                    setStateByProperty(this, 'street', 'store[street]', existStoreName.street);
                    setStateByProperty(this, 'streetNumber', 'store[streetNumber]', existStoreName.streetNumber);
                } else {
                    setStateByProperty(this, 'city', 'searchStore[city]', { id: '', name: '' });
                    setStateByProperty(this, 'district', 'searchStore[district]', { id: '', name: '' });
                    setStateByProperty(this, 'name', 'store[name]', '');
                    setStateByProperty(this, 'wardName', 'store[wardName]', '');
                    setStateByProperty(this, 'street', 'store[street]', '');
                    setStateByProperty(this, 'streetNumber', 'store[streetNumber]', '');
                }
                break;
            case 'searchStore[city]':
                setStateByProperty(this, 'city', nameHtml, { id: valueHtml, name: '' });
                setStateByProperty(this, 'district', 'searchStore[district]', { id: '', name: '' });
                setStateByProperty(this, 'wardName', 'searchStore[wardName]', '');
                setStateByProperty(this, 'street', 'searchStore[street]', '');
                setStateByProperty(this, 'streetNumber', 'searchStore[streetNumber]', '');
                this.resetSearchForm();
                break;
            case 'searchStore[district]':
                setStateByProperty(this, 'district', nameHtml, { id: valueHtml, name: '' });
                setStateByProperty(this, 'wardName', 'searchStore[wardName]', '');
                setStateByProperty(this, 'street', 'searchStore[street]', '');
                setStateByProperty(this, 'streetNumber', 'searchStore[streetNumber]', '');
                this.resetSearchForm();
                break;
            case 'searchStore[wardName]':
                setStateByProperty(this, 'wardName', nameHtml, valueHtml);
                setStateByProperty(this, 'street', 'searchStore[street]', '');
                setStateByProperty(this, 'streetNumber', 'searchStore[streetNumber]', '');
                this.resetSearchForm();
                break;
            case 'searchStore[street]':
                setStateByProperty(this, 'street', nameHtml, valueHtml);
                setStateByProperty(this, 'streetNumber', 'searchStore[streetNumber]', '');
                this.resetSearchForm();
                break;
            case 'searchStore[streetNumber]':
                setStateByProperty(this, 'streetNumber', nameHtml, valueHtml);
                const existStoreStreetNumber = stores.find(e => e.streetNumber === valueHtml);
                if (existStoreStreetNumber) {
                    setStateByProperty(this, 'store', nameHtml, { id: existStoreStreetNumber.id, name: '' });
                    setStateByProperty(this, 'city', 'searchStore[city]', { id: existStoreStreetNumber.cityId, name: '' });
                    setStateByProperty(this, 'district', 'searchStore[district]', { id: existStoreStreetNumber.districtId, name: '' });
                    setStateByProperty(this, 'name', 'store[name]', existStoreStreetNumber.name);
                    setStateByProperty(this, 'wardName', 'store[wardName]', existStoreStreetNumber.wardName);
                    setStateByProperty(this, 'street', 'store[street]', existStoreStreetNumber.street);
                } else {
                    setStateByProperty(this, 'store', nameHtml, { id: '', name: '' });
                    setStateByProperty(this, 'city', 'searchStore[city]', { id: '', name: '' });
                    setStateByProperty(this, 'district', 'searchStore[district]', { id: '', name: '' });
                    setStateByProperty(this, 'name', 'store[name]', '');
                    setStateByProperty(this, 'wardName', 'store[wardName]', '');
                    setStateByProperty(this, 'street', 'store[street]', '');
                }
                break;
            case 'route[routeName]':
                setStateByProperty(this, 'routeName', nameHtml, valueHtml);
                setTimeout(() => this.getData(), 0);
                break;
            default:
                break;
        }
    }

    doDelete = (storeId) => {
        this.setState(prevState => ({
            data: prevState.data.filter(e => parseInt(e.storeId) !== parseInt(storeId)),
            disabledSent: false,
            disabledDelete: false,
        }));
    }

    addStore = () => {
        const { formData: { store } } = this.state;
        const { settings: { stores, metadata: { city } } } = this.props;
        const existStore = stores.find(e => e.id === getIdOrUndefined(store));
        let existCity = new City();
        let existDistrict = new District();
        if (existStore) {
            existCity = city.find(e => e.id === existStore.cityId) || new City();
            if (existCity) {
                existDistrict = existCity.district.find(e => e.id === existStore.districtId) || new District();
            }
            const newStore: StoreRow = {
                storeId: String(existStore.id),
                storename: existStore.name,
                streetNumber:  existStore.streetNumber,
                street: existStore.street,
                wardName: existStore.wardName,
                districtName: existDistrict.name,
                cityName: existCity.name
            };
            this.setState(prevState => ({
                data: [...prevState.data.filter(e => e.storeId !== newStore.storeId), newStore],
                formData: {
                    ...prevState.formData,
                    store: new Store(),
                    streetNumber: '',
                    street: ''
                },
                disabledSent: false,
                disabledDelete: false,
            }));
        }
    }

    onSubmit = () => {
        const isValid = checkValidations(this);
        const { formData: { routeName }, data } = this.state;
        const storeIds = data.map(e => e.storeId);
        if (isValid) {
            Swal.fire({
                html: 'Vui lòng chờ ...<br/><div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>',
                showConfirmButton: false,
                allowOutsideClick: false
            })
            this.props.apiService.postRouteStores(routeName, storeIds).then(() => {
                Swal.fire({
                    ...Constants.SWAL_COMMON_STYLE,
                    text: 'Lưu thành công.',
                    type: 'success',
                    confirmButtonText: 'Đóng'
                }).then(() => {
                    this.props.history.push('/' + Routes.HomePageRoute.url);
                    this.props.history.push('/' + Routes.RouteCreatePageRoute.url);
                });
            });
        }
    }

    onDelete = () => {
        const { formData: { routeName } } = this.state;
        Swal.fire({
            text: 'Bạn có chắc chắn muốn xoá tuyến này?',
            type: 'info',
            confirmButtonText: 'Có',
            cancelButtonText: 'Không',
            showCancelButton: true,
            focusConfirm: false
        }).then((result) => {
            if (result.value) {
                Swal.fire({
                    html: 'Vui lòng chờ ...<br/><div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>',
                    showConfirmButton: false,
                    allowOutsideClick: false
                })
                this.props.apiService.deleteRouteStores(routeName).then(() => {
                    Swal.fire({
                        ...Constants.SWAL_COMMON_STYLE,
                        text: 'Xoá thành công.',
                        type: 'success',
                        confirmButtonText: 'Đóng'
                    }).then(() => {
                        this.props.history.push('/' + Routes.HomePageRoute.url);
                        this.props.history.push('/' + Routes.RouteCreatePageRoute.url);
                    });
                });
            }
        });
    }

    // Custom Code
    render() {
        const { settings: { metadata } } = this.props;
        const { data, formData, disabledSent, disabledDelete, fields } = this.state;
        
        const formFieldsRoute = getFormFieldsRoute(formData);
        const formFieldsSearchStore = getFormFieldsSearchStore(formData, metadata);

        return (
            <div className="route-page">
                <div className="sticky-top">
                    <div className="row pt-2">
                        <div className="col-6">TDV: {metadata.userInfo.name}</div>
                        <div className="col-6 text-right">Nhóm: {metadata.userInfo.groupName}</div>
                    </div>
                    <h3 className="head-title mt-0 mb-0 pt-3">TẠO / SỬA TUYẾN</h3>
                    
                </div>

                <div className="holder">
                    <div className="item-ads row">
                        <div className="mt-3 col-12">
                            <div className="row">
                                {formFieldsRoute.map((field, key) =>
                                    <div key={`${formData.routeName}-${key}`} className="mt-1 col-12">
                                        <FormItem
                                            {...field.props}
                                            onChangeHandle={this.onChangeHandle}
                                            fields={fields}
                                        />
                                    </div>
                                )}
                                {formFieldsSearchStore.map((field, key) =>
                                    <div key={`${getIdOrUndefined(formData.city)}-${getIdOrUndefined(formData.district)}-${formData.wardName}-${formData.street}-${formData.streetNumber}-${key}`} className="mt-1 col-6 col-lg-2">
                                        <FormItem
                                            {...field.props}
                                            onChangeHandle={this.onChangeHandle}
                                            fields={fields}
                                        />
                                    </div>
                                )}
                                <div className="mt-3 col-12">
                                    <button disabled={!getIdOrUndefined(formData.store)} className="btn btn-block btn-primary" onClick={() => this.addStore()}>Thêm Nhà thuốc vào Danh sách Tuyến</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="report-table mt-3">
                        <table>
                            <thead>
                                <tr>
                                    <th className="text-center">STT</th>
                                    <th className="text-center sticky-column">Tên NT</th>
                                    <th className="text-center">Số nhà</th>
                                    <th className="text-center">Đường</th>
                                    <th className="text-center">Phường/Xã</th>
                                    <th className="text-center">Quận/Huyện</th>
                                    <th className="text-center">Tỉnh/TP</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.map((row, key) =>
                                    <tr key={key}>
                                        <td>{(key + 1)}</td>
                                        <td className="no-wrap sticky-column">{row.storename}</td>
                                        <td className="no-wrap">{row.streetNumber}</td>
                                        <td className="no-wrap">{row.street}</td>
                                        <td className="no-wrap">{row.wardName}</td>
                                        <td className="no-wrap">{row.districtName}</td>
                                        <td className="no-wrap">{row.cityName}</td>
                                        <td><div className="no-wrap"><button className="btn btn-danger" onClick={() => this.doDelete(row.storeId)}>Xoá</button></div></td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                    
                </div>
                <div className="sticky-bottom">
                    <div className="group-btn">
                        <button className="btn btn-dark" onClick={() => this.props.history.push('/' + Routes.RouteHomePageRoute.url)}>Quay lại</button>
                        <button disabled={disabledDelete} className="btn btn-danger" onClick={() => this.onDelete()}>Xoá</button>
                        <button disabled={disabledSent} className="btn btn-primary" onClick={() => this.onSubmit()}>Lưu</button>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ settings }) => ({ settings });
export default withRouter(connect(mapStateToProps)(RouteCreatePage));