import React, { Component } from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

import { history, configureStore } from 'reduxs/index';
import AppLocale from 'util/lngProvider';
import ApiContext, { services } from 'util/ApiContext';

import Layout from 'pages/Layout';

const store = configureStore();

interface State {
    currentAppLocale: Record<string, React.ReactNode>;
}

class App extends Component<{}, State> {
    constructor(props) {
        super(props);
        this.state = {
            currentAppLocale: AppLocale['en-US']
        };
    }

    render = () => {
        return <Provider store={store}>
            <ConnectedRouter history={history}>
                <ApiContext.Provider value={services}>
                    <Switch>
                        <Route
                            path="/"
                            render={props => {
                                return <Layout {...props} {...services} />;
                            }}
                        />
                    </Switch>
                </ApiContext.Provider>
            </ConnectedRouter>
        </Provider>;
    };
}

export default App;
