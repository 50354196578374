import React, { useState } from 'react';
import { DisplayDateTimeFormat } from 'util/ControlUtils';

const UploadImgGroup: React.SFC<{ 
    previews: (string | ArrayBuffer | null)[]; 
    files: (File | null)[]; 
    disabled: boolean; 
    onFileChanged: () => void; }> = ({ previews, files, disabled, onFileChanged }) => {

    const [ createDate, setCreateDate ] = useState([new Date(), new Date(), new Date()]);

    const deleteImage = (index) => {
        let filesReturn: (File | null)[] = [];
        if (files) {
            filesReturn = files;
        }
        filesReturn[index] = null;

        let previewsReturn: (string | ArrayBuffer | null)[] = [];
        if (previews) {
            previewsReturn = previews;
        }
        previewsReturn[index] = '';
        
        onFileChanged();
    }
    
    const fileChangedHandler = (event, index) => {
        const file = event.target.files[0];
        if (file) {
            let filesReturn: (File | null)[] = [];
            if (files) {
                filesReturn = files;
            }
            filesReturn[index] = file;

            let reader = new FileReader();

            reader.onloadend = () => {
                let previewsReturn: (string | ArrayBuffer | null)[] = [];
                if (previews) {
                    previewsReturn = previews;
                }
                previewsReturn[index] = reader.result;
                
                const newCreatedDate = [...createDate];
                newCreatedDate[index] = new Date();
                setCreateDate(newCreatedDate);

                onFileChanged();
            }

            reader.readAsDataURL(file);
        } else {
            deleteImage(index);
        }
    }

    return (
        <>
            <div className="img-group">
                {[...Array(3).keys()].map((index, key) =>
                    <div key={key}>
                        <div className="btn-group">
                            <input disabled={disabled} className="btn btn-white btn-block mt-1 mb-1" type="file" accept="image/*" onChange={(e) => fileChangedHandler(e, index)} />
                            {previews[index] && <button onClick={() => deleteImage(index)} className="btn btn-danger btn-block mt-1 mb-1">Xoá</button>}
                        </div>
                        {previews[index] && <img className="mt-1 mb-1 img-center" src={previews[index]!.toString()} />}
                        {previews[index] && <div className="date-overlay">{DisplayDateTimeFormat(createDate[index], 'DD/MM/YYYY HH:mm:ss')}</div>}
                    </div>)}
            </div>
        </>
    );
};

export default UploadImgGroup;
