import React from 'react';
import { connect } from 'react-redux';
import { PageProps } from 'interfaces';
import { Field, City, District, PeriodOption } from 'models/Settings';
import { withRouter } from 'react-router-dom';
import { setStateByProperty, checkValidations, forceAbs, getIdOrUndefined } from 'components/Form';
import Swal from 'sweetalert2';
import { getFields, getFormFieldsAdses } from './fields';
import { getFormFieldsSearchStore } from 'pages/RequestStorePage/fields';
import { interpret } from 'util/ControlUtils';
import store from 'reduxs/index';
import { setLoading, setStore } from 'reduxs/actions/Settings';
import * as Routes from 'pages/RouteLoader';
import Store, { Ads, TargetActualIncome } from 'models/Store';
import * as Constants from 'constants/Constants';
import _ from 'lodash';
import Step1 from './step1';
import Step2 from './step2';
import Step3 from './step3';

interface State {
    disabledDraft: boolean;
    disabledSent: boolean;
    fields: Field[];

    // Custom Code
    formData: {
        store: Store;
        city: City;
        district: District;
        period: PeriodOption;
        wardName: string;
        street: string;
        streetNumber: string;
        adses: Ads[];
    };
    adsImageInsidePreview: (string | ArrayBuffer | null)[];
    adsImageOutsidePreview: (string | ArrayBuffer | null)[];
    store: Store;
    targetActualIncome: TargetActualIncome;
    adses: Ads[];
    step: number;
    initLoaded: boolean;
}

class RequestPaymentPage extends React.Component<PageProps, State> {
    signaturePad;

    constructor(props: PageProps) {
        super(props);
        store.dispatch(setStore([]));
        let fields = getFields();
        fields = fields.map(e => ({
            ...new Field(), ...e,
            isValid: false,
            isValidType: 'required'
        }));

        this.state = {
            disabledDraft: true,
            disabledSent: true,
            fields: fields,

            // Custom Code
            formData: {
                store: new Store(),
                city: localStorage.cityId ? {...new City(), id: localStorage.cityId} : new City(),
                district: localStorage.districtId ? {...new District(), id: localStorage.districtId} : new District(),
                period: new PeriodOption(),
                wardName: '',
                street: '',
                streetNumber: '',
                adses: []
            },
            adsImageInsidePreview: [],
            adsImageOutsidePreview: [],
            store: new Store(),
            targetActualIncome: new TargetActualIncome(),
            adses: [],
            step: 1,
            initLoaded: true
        }

        if (props.match.params.storeId) {
            this.state = {
                ...this.state,
                formData: {
                    ...this.state.formData,
                    store: {...new Store(), id: parseInt(props.match.params.storeId)}
                },
                initLoaded: false
            }

            this.getData();
        }
    }

    static getDerivedStateFromProps(props, state) {
        const { initLoaded,formData } = state;
        const { settings: { stores, metadata } } = props;
        if (!initLoaded && stores.length && metadata.city.length) {
            const findStore = stores.find(e => e.id === getIdOrUndefined(formData.store));
            const selectedCity = metadata.city.find(e => findStore && e.id === findStore.cityId) || new City();
            const selectedDistrict = selectedCity ? selectedCity.district.find(e => findStore && e.id === findStore.districtId) || new District() : new District();
            localStorage.cityId = selectedCity.id;
            localStorage.districtId = selectedDistrict.id;

            let storeField = {};
            if (findStore) {
                storeField = {
                    wardName:findStore.wardName,
                    street: findStore.street,
                    streetNumber: findStore.streetNumber,
                }
                localStorage.wardName = findStore.wardName;
                localStorage.street = findStore.street;
                localStorage.streetNumber = findStore.streetNumber;
            }

            return { 
                ...state,
                formData: {
                    ...state.formData,
                    ...storeField,
                    city: selectedCity,
                    district: selectedDistrict
                },
                initLoaded: true
            };
        }
        return null;
    }

    componentDidMount() {
        store.dispatch(setLoading(true));
        this.props.apiService.adses('requestPayment').then(res => {
            this.setState({
                adses: res
            });
            store.dispatch(setLoading(false));
        });
    }

    resetSearchForm = () => {
        this.setState(prevState => ({
            formData: {
                ...prevState.formData,
                store: new Store(),
                adses: []
            },
            adsImageInsidePreview: [],
            adsImageOutsidePreview: [],
            store: new Store(),
            targetActualIncome: new TargetActualIncome(),
            disabledDraft: true,
            disabledSent: true
        }));
        (document.getElementById('file') as any).reset();
    }

    getData = () => {
        setTimeout(() => {
            const { formData } = this.state;
            const storeId = getIdOrUndefined(formData.store);
            if (storeId <= 0) {
                this.setState({
                    formData: {
                        store: new Store(),
                        city: new City(),
                        district: new District(),
                        period: new PeriodOption(),
                        wardName: '',
                        street: '',
                        streetNumber: '',
                        adses: []
                    },
                    adsImageInsidePreview: [],
                    adsImageOutsidePreview: [],
                    store: new Store(),
                    targetActualIncome: new TargetActualIncome(),
                    disabledDraft: true,
                    disabledSent: true
                });

                (document.getElementById('file') as any).reset();
            } else {
                this.props.apiService.getRequestPaymentByPeriod(storeId, getIdOrUndefined(formData.period)).then(res => {
                    this.setState(prevState => ({
                        formData: {
                            ...prevState.formData,
                            adses: res.adses
                        },
                        targetActualIncome: res.targetActualIncome,
                        adsImageInsidePreview: res.adsImageInsidePreview,
                        adsImageOutsidePreview: res.adsImageOutsidePreview,
                        disabledDraft: true,
                        disabledSent: !_.isEmpty(res.adses) || !_.isEmpty(res.adsImageInsidePreview) || !_.isEmpty(res.adsImageOutsidePreview) ? false : true
                    }), () => this.setPeriod(res.requestPaymentConfigId))
                });

                (document.getElementById('file') as any).reset();
            }
        }, 0);
    }

    setPeriod = (requestPaymentConfigId) => {
        const { settings: { metadata } } = this.props;
        if (requestPaymentConfigId) {
            setStateByProperty(this, 'period', 'requestPayment[period]', { id: requestPaymentConfigId, name: '' });
        } else if (metadata.allPeriods.length) {
            setStateByProperty(this, 'period', 'requestPayment[period]', { id: metadata.allPeriods[0].id, name: '' });
        }
    }

    // Custom Code
    onChangeHandle = (nameHtml, valueHtml) => {
        const { settings: { stores } } = this.props;
        const { formData } = this.state;
        let adses: Ads[] = [];

        switch (nameHtml) {
            case 'requestPayment[period]':
                setStateByProperty(this, 'period', 'requestPayment[period]', { id: valueHtml, name: '' });
                this.getData();
                break;
            case 'searchStore[name]':
                setStateByProperty(this, 'store', nameHtml, { id: valueHtml, name: '' });
                const existStoreName = stores.find(e => e.id === valueHtml);
                if (existStoreName) {
                    setStateByProperty(this, 'city', 'searchStore[city]', { id: existStoreName.cityId, name: '' });
                    setStateByProperty(this, 'district', 'searchStore[district]', { id: existStoreName.districtId, name: '' });
                    setStateByProperty(this, 'name', 'store[name]', existStoreName.name);
                    setStateByProperty(this, 'wardName', 'store[wardName]', existStoreName.wardName);
                    setStateByProperty(this, 'street', 'store[street]', existStoreName.street);
                    setStateByProperty(this, 'streetNumber', 'store[streetNumber]', existStoreName.streetNumber);
                    localStorage.cityId = existStoreName.cityId;
                    localStorage.districtId = existStoreName.districtId;
                    localStorage.wardName = existStoreName.wardName;
                    localStorage.street = existStoreName.street;
                    localStorage.streetNumber = existStoreName.streetNumber;
                    this.setState({ store: existStoreName });
                } else {
                    setStateByProperty(this, 'city', 'searchStore[city]', { id: '', name: '' });
                    setStateByProperty(this, 'district', 'searchStore[district]', { id: '', name: '' });
                    setStateByProperty(this, 'name', 'store[name]', '');
                    setStateByProperty(this, 'wardName', 'store[wardName]', '');
                    setStateByProperty(this, 'street', 'store[street]', '');
                    setStateByProperty(this, 'streetNumber', 'store[streetNumber]', '');
                }
                this.getData();
                break;
            case 'searchStore[city]':
                setStateByProperty(this, 'city', nameHtml, { id: valueHtml, name: '' });
                setStateByProperty(this, 'district', 'searchStore[district]', { id: '', name: '' });
                setStateByProperty(this, 'wardName', 'searchStore[wardName]', '');
                setStateByProperty(this, 'street', 'searchStore[street]', '');
                setStateByProperty(this, 'streetNumber', 'searchStore[streetNumber]', '');
                localStorage.cityId = valueHtml;
                localStorage.districtId = '';
                localStorage.wardName = '';
                localStorage.street = '';
                this.resetSearchForm();
                break;
            case 'searchStore[district]':
                setStateByProperty(this, 'district', nameHtml, { id: valueHtml, name: '' });
                setStateByProperty(this, 'wardName', 'searchStore[wardName]', '');
                setStateByProperty(this, 'street', 'searchStore[street]', '');
                setStateByProperty(this, 'streetNumber', 'searchStore[streetNumber]', '');
                localStorage.districtId = valueHtml;
                localStorage.wardName = '';
                localStorage.street = '';
                this.resetSearchForm();
                break;
            case 'searchStore[wardName]':
                setStateByProperty(this, 'wardName', nameHtml, valueHtml);
                setStateByProperty(this, 'street', 'searchStore[street]', '');
                setStateByProperty(this, 'streetNumber', 'searchStore[streetNumber]', '');
                localStorage.wardName = valueHtml;
                localStorage.street = '';
                this.resetSearchForm();
                break;
            case 'searchStore[street]':
                setStateByProperty(this, 'street', nameHtml, valueHtml);
                setStateByProperty(this, 'streetNumber', 'searchStore[streetNumber]', '');
                localStorage.street = valueHtml;
                this.resetSearchForm();
                break;
            case 'searchStore[streetNumber]':
                setStateByProperty(this, 'streetNumber', nameHtml, valueHtml);
                const existStoreStreetNumber = stores.find(e => e.streetNumber === valueHtml);
                if (existStoreStreetNumber) {
                    setStateByProperty(this, 'store', nameHtml, { id: existStoreStreetNumber.id, name: '' });
                    setStateByProperty(this, 'city', 'searchStore[city]', { id: existStoreStreetNumber.cityId, name: '' });
                    setStateByProperty(this, 'district', 'searchStore[district]', { id: existStoreStreetNumber.districtId, name: '' });
                    setStateByProperty(this, 'name', 'store[name]', existStoreStreetNumber.name);
                    setStateByProperty(this, 'wardName', 'store[wardName]', existStoreStreetNumber.wardName);
                    setStateByProperty(this, 'street', 'store[street]', existStoreStreetNumber.street);
                    this.setState({ store: existStoreStreetNumber });
                } else {
                    setStateByProperty(this, 'store', nameHtml, { id: '', name: '' });
                    setStateByProperty(this, 'city', 'searchStore[city]', { id: '', name: '' });
                    setStateByProperty(this, 'district', 'searchStore[district]', { id: '', name: '' });
                    setStateByProperty(this, 'name', 'store[name]', '');
                    setStateByProperty(this, 'wardName', 'store[wardName]', '');
                    setStateByProperty(this, 'street', 'store[street]', '');
                }
                this.getData();
                break;
            default:
                const extractNameHTML = interpret(nameHtml);
                if (extractNameHTML) {
                    if (extractNameHTML['name'] === 'adses' &&
                        extractNameHTML['indices'] &&
                        extractNameHTML['indices'].length === 2) { // array of sub field
                        const extractName = extractNameHTML['indices'][1];
                        const extractIndex = extractNameHTML['indices'][0];

                        if (formData.adses) {
                            adses = formData.adses;
                        }
                        if (!adses[extractIndex]) {
                            adses[extractIndex] = new Ads();
                            adses[extractIndex].id = extractIndex;
                        }
                        if (extractName === 'quantity') {
                            adses[extractIndex].quantity = forceAbs(valueHtml);
                            setStateByProperty(this, 'adses', nameHtml, adses, 'quantity');
                            this.setState({ disabledDraft: false, disabledSent: false });
                        }
                        if (extractName === 'option') {
                            adses[extractIndex].optionId = valueHtml;
                            setStateByProperty(this, 'adses', nameHtml, adses, 'option');
                            this.setState({ disabledDraft: false, disabledSent: false });
                        }
                    }
                }
                break;
        }
    }

    fileChangedHandler = (event, name) => {
        this.setState({
            disabledDraft: false,
            disabledSent: false
        });

        if (name === 'adsImageInside') {
            this.setState({
                adsImageInsidePreview: event
            });
        } else if (name === 'adsImageOutside') {
            this.setState({
                adsImageOutsidePreview: event
            });
        }
    }

    sigCanvas = (ref) => {
        this.signaturePad = ref;
    }

    checkValidCapturePicture = (step) => {
        const { adsImageInsidePreview, adsImageOutsidePreview } = this.state;
        if (step > 1) {
            if (!_.isEmpty(adsImageInsidePreview) && !_.isEmpty(adsImageOutsidePreview)) {
                return true;
            } else {
                return false;
            }
        } else {
            return true;
        }
    }

    // Custom Code
    onSubmit = (type: 'draft' | 'sent', step) => {
        const isValid = checkValidations(this);
        const { formData, adsImageInsidePreview, adsImageOutsidePreview } = this.state;

        if (isValid && this.checkValidCapturePicture(step)) {
            Swal.fire({
                html: 'Vui lòng chờ ...<br/><div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>',
                showConfirmButton: false,
                allowOutsideClick: false
            })
            let signatureData = '';
            if (type === 'sent') {
                signatureData = this.signaturePad.toDataURL();
            }
            this.props.apiService.postRequestPayment(formData, adsImageInsidePreview, adsImageOutsidePreview, signatureData, type).then(() => {
                if (type === 'sent') {
                    Swal.fire({
                        ...Constants.SWAL_COMMON_STYLE,
                        text: 'Gửi yêu cầu thành công.',
                        type: 'success',
                        confirmButtonText: 'Đóng'
                    }).then(() => {
                        this.props.history.push('/' + Routes.HomePageRoute.url);
                        this.props.history.push('/' + Routes.RequestPaymentPageAddRoute.url);
                    });
                } else {
                    this.getData();
                    this.setState({ step: step });
                    Swal.close();
                }
            });
        } else if (!this.checkValidCapturePicture(step)) {
            Swal.fire({
                ...Constants.SWAL_COMMON_STYLE,
                text: 'Bạn cần chụp hình bên trong + bên ngoài nhà thuốc trước khi gửi yêu cầu.',
                type: 'warning',
                confirmButtonText: 'Đóng'
            })
        }
    }

    // Custom Code
    render() {
        const { fields, formData, disabledDraft, disabledSent, adses, adsImageOutsidePreview, adsImageInsidePreview, targetActualIncome, store, step } = this.state;
        const { settings: { metadata }, match: { params } } = this.props;
        const formFieldsSearchStore = getFormFieldsSearchStore(formData, metadata);
        const formFieldsAdses = getFormFieldsAdses(formData, adses);

        const formPeriod = {
            props: {
                type: 'select',
                htmlFor: 'requestPayment[period]',
                fieldName: 'requestPayment[period]',
                className: 'select-custom',
                data: getIdOrUndefined(formData.period),
                fieldLabel: 'Phí quảng cáo quý',
                selectAllLabel: '- Chọn -',
                options: metadata.allPeriods.map((e: PeriodOption) => ({ value: e.id, label: e.name })),
                attribute: {
                    disabled: getIdOrUndefined(formData.store) <= 0
                },
            }
        };

        return (
            <div className="request-payment-page">
                {step === 1 && <Step1
                    metadata={metadata}
                    params={params}
                    formFieldsSearchStore={formFieldsSearchStore}
                    formFieldsAdses={formFieldsAdses}
                    formPeriod={formPeriod}
                    formData={formData}
                    fields={fields}
                    adsImageInsidePreview={adsImageInsidePreview}
                    adsImageOutsidePreview={adsImageOutsidePreview}
                    targetActualIncome={targetActualIncome}
                    disabledDraft={disabledDraft}
                    disabledSent={disabledSent}
                    onChangeHandle={this.onChangeHandle}
                    fileChangedHandler={this.fileChangedHandler}
                    onSubmit={this.onSubmit}
                    history={this.props.history}
                />}
                {step === 2 && <Step2
                    metadata={metadata}
                    formFieldsAdses={formFieldsAdses}
                    formData={formData}
                    targetActualIncome={targetActualIncome}
                    store={store}
                    onSubmit={this.onSubmit}
                />}
                {step === 3 && <Step3
                    metadata={metadata}
                    onSubmit={this.onSubmit}
                    sigCanvas={this.sigCanvas}
                />}
            </div>
        );
    }
}

const mapStateToProps = ({ settings }) => ({ settings });
export default withRouter(connect(mapStateToProps)(RequestPaymentPage));