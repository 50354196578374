import React from 'react';
import { Input } from 'reactstrap';
import DatePicker, { registerLocale } from 'react-datepicker';
import { enUS } from 'date-fns/locale';
import moment from 'moment';

import { Field } from 'models/Settings';
import { FormItemInterface, isValidOrNot, renderErrorHtml } from './index';
import { DATE_FORMAT } from 'constants/Constants';

registerLocale('en-US', enUS);

interface Props extends FormItemInterface {
    locale?: string;
    selected: Date | undefined;
    isClearable?: boolean;
}
const CalendarGroup = ((props: Props) => {
    const {
        htmlFor,
        fieldLabel,
        fieldName,
        fieldValidation = new Field(),
        className = 'input-group mb-3',
        attribute,
        selected,
        onChangeHandle,
        htmlLabel,
        isClearable = false
    } = props;

    const onChange = event => {
        onChangeHandle(fieldName, event);
    };

    const isAllowBackDated = attribute && attribute.isAllowBackDated;
    const isAllowFutureDate = attribute && attribute.isAllowFutureDate;

    function allowDate(date) {
        if (isAllowBackDated && isAllowFutureDate) {
            return true;
        } else if (isAllowFutureDate) {
            return moment() < date;
        } else if (isAllowBackDated) {
            return moment() > date;
        }
        return true;
    }

    const {
        isValidType,
        isDirty,
        isValid,
        isVisible,
        isRequired,
        regex,
        customMsg
    } = fieldValidation;
    const errorClass =
    isDirty && isValidOrNot(isValid, isRequired, regex, customMsg)
        ? 'error-holder'
        : '';

    let _calendar;
    return !isVisible ? null : (
        <React.Fragment>
            {fieldLabel && (
                <label htmlFor={htmlFor}>
                    {fieldLabel} {isRequired && <span className="required">*</span>}
                </label>
            )}
            <div className={`${className} ${errorClass} form-date-picker`}>
                <DatePicker
                    dateFormat={DATE_FORMAT.toLowerCase().replace('mm', 'MM')}
                    popperPlacement={'bottom'}
                    {...attribute}
                    ref={c => (_calendar = c)}
                    id={fieldLabel}
                    autoComplete="off"
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    isClearable={isClearable}
                    placeholderText={
                        attribute && attribute.dateFormat && attribute.dateFormat !== ''
                            ? attribute.dateFormat.toLowerCase()
                            : DATE_FORMAT.toLowerCase()
                    }
                    selected={selected ? selected : null}
                    onChange={onChange}
                    customInput={
                        <Input
                            maxLength={fieldValidation.maxLength}
                            id={htmlFor}
                            name={fieldName}
                            calendar={_calendar}
                        />
                    }
                    className="calendar-fluid"
                    popperModifiers={{
                        offset: {
                            enabled: true,
                            offset: '1px, 1px'
                        },
                        preventOverflow: {
                            enabled: true,
                            escapeWithReference: false, // force popper to stay in viewport (even when input is scrolled out of view)
                            boundariesElement: 'viewport'
                        }
                    }}
                    filterDate={date => {
                        return allowDate(date);
                    }}
                />
                {renderErrorHtml(
                    isRequired,
                    isValidType,
                    isDirty,
                    fieldLabel,
                    customMsg,
                    htmlLabel
                )}
            </div>
        </React.Fragment>
    );
});

export default CalendarGroup;
