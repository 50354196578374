import React from 'react';
import { connect } from 'react-redux';
import { PageProps } from 'interfaces';
import { withRouter } from 'react-router-dom';
import * as Routes from 'pages/RouteLoader';
import Section from './section';


class HistoryPage extends React.Component<PageProps, {}> {
    constructor(props: PageProps) {
        super(props);
    }

    // Custom Code
    render() {
        const { settings: { metadata } } = this.props;

        return (
            <div className="history-page">
                <div className="sticky-top">
                    <div className="row pt-2">
                        <div className="col-6">TDV: {metadata.userInfo.name}</div>
                        <div className="col-6 text-right">Nhóm: {metadata.userInfo.groupName}</div>
                    </div>
                    <h3 className="head-title mt-0 mb-0 pt-3">
                        LỊCH SỬ LÀM VIỆC
                    </h3>
                </div>

                <div className="holder">
                    <div className="item-ads row">
                        <div className="mt-3 col-12">
                            <Section
                                {...this.props}
                                type={'planStore'}
                            />
                            <Section
                                {...this.props}
                                type={'reportStore'}
                            />
                            <Section
                                {...this.props}
                                type={'requestAds'}
                            />
                            <Section
                                {...this.props}
                                type={'requestPayment'}
                            />
                            <Section
                                {...this.props}
                                type={'requestStore'}
                            />
                        </div>
                    </div>
                </div>
                <div className="sticky-bottom">
                    <div className="group-btn">
                        <button className="btn btn-dark" onClick={() => this.props.history.push('/' + Routes.HomePageRoute.url)}>Home</button>
                    </div>  
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ settings }) => ({ settings });
export default withRouter(connect(mapStateToProps)(HistoryPage));