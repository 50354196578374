import React from 'react';
import { connect } from 'react-redux';
import { PageProps } from 'interfaces';
import { withRouter } from 'react-router-dom';
import * as Routes from 'pages/RouteLoader';
import store from 'reduxs/index';
import { setLoading } from 'reduxs/actions/Settings';
import { DATE_FORMAT } from 'constants/Constants';
import { DisplayDateFormat } from 'util/ControlUtils';

interface State {
    date: string;
    data?: {
        allItems: {
            name: string;
            shortname: string;
        }[];
        allAdses: {
            name: string;
            shortname: string;
        }[];
        rows: {
            id: string;
            storeId: string;
            storename: string;
            streetNumber: string;
            street: string;
            wardName: string;
            districtName: string;
            cityName: string;
            phone: string;
            visitedCount: number;
            quantities: number[];
            quantitiesAds: number[];
            totalPrice: string;
        }[];
        totalAllQuantity: number[];
        totalAllQuantityAds: number[];
        totalAllPrice: string;
        totalAllVisited: string;
    };
}

class CalendarPlanPage extends React.Component<PageProps, State> {
    constructor(props: PageProps) {
        super(props);

        this.state = {
            date: props.match.params.date
        }
    }

    componentDidMount = () => {
        this.getData();
    }

    getData = () => {
        const { date } = this.state;

        store.dispatch(setLoading(true));
        this.props.apiService.getCalendarDetail(DisplayDateFormat(date, DATE_FORMAT), 'plan').then(res => {
            this.setState({
                data: res
            });
            store.dispatch(setLoading(false));
        })
    }

    // Custom Code
    goToDetail = (storeId = '') => {
        const { date } = this.state;
        if (storeId === '') {
            this.props.history.push(`/${Routes.PlanStorePageAddRoute.url}`, {returnURLType: 'calendar_detail', returnURLParams: {date: date}});
        } else {
            this.props.history.push(`/${Routes.PlanStorePageEditRoute.url.replace(':storeId', storeId).replace(':date', date)}`, {returnURLType: 'calendar_detail', returnURLParams: {date: date}});
        }
    }
    deletePlan = (storeId = '') => {
        const { date } = this.state;
        this.props.apiService.deletePlanStore(storeId, date).then(() => {
            this.getData();
        });
    }

    // Custom Code
    render() {
        const { settings: { metadata } } = this.props;
        const { date, data } = this.state;

        return (
            <div className="manager-report-page">
                <div className="sticky-top">
                    <div className="row pt-2">
                        <div className="col-6">TDV: {metadata.userInfo.name}</div>
                        <div className="col-6 text-right">Nhóm: {metadata.userInfo.groupName}</div>
                    </div>
                    <h3 className="head-title mt-0 mb-0 pt-3">KẾ HOẠCH HOẠT ĐỘNG NHÀ THUỐC</h3>
                    {data && <p className="text-center">Ngày: <b>{DisplayDateFormat(date, DATE_FORMAT)}</b></p>}
                    
                </div>
                <div className="holder">
                    <div className="report-table mt-3">
                        {data && <table>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th colSpan={data.allItems.length} className="text-center"><span>Doanh số</span></th>
                                    <th></th>
                                    <th colSpan={data.allAdses.length} className="text-center"><span>Vật dụng quảng cáo</span></th>
                                    <th></th>
                                </tr>
                                <tr>
                                    <th className="text-center">STT</th>
                                    <th className="text-center sticky-column">Tên NT</th>
                                    <th className="text-center">Số nhà</th>
                                    <th className="text-center">Đường</th>
                                    <th className="text-center">Phường/Xã</th>
                                    <th className="text-center">Quận/Huyện</th>
                                    <th className="text-center">Tỉnh/TP</th>
                                    <th className="text-center">Số ĐT</th>
                                    <th className="text-center">Viếng thăm</th>
                                    {data.allItems.map((e, key) =>
                                        <th key={key}><span className="item-name">{e.shortname}</span></th>
                                    )}
                                    <th className="text-center"><span className="item-name">Thành tiền (VNĐ)</span></th>
                                    {data.allAdses.map((e, key) =>
                                        <th key={key}><span className="item-name">{e.shortname}</span></th>
                                    )}
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.rows.map((row, key) =>
                                    <tr key={key}>
                                        <td>{(key + 1)}</td>
                                        <td className="no-wrap sticky-column">{row.storename}</td>
                                        <td className="no-wrap">{row.streetNumber}</td>
                                        <td className="no-wrap">{row.street}</td>
                                        <td className="no-wrap">{row.wardName}</td>
                                        <td className="no-wrap">{row.districtName}</td>
                                        <td className="no-wrap">{row.cityName}</td>
                                        <td className="no-wrap">{row.phone}</td>
                                        <td className="text-center">{row.visitedCount > 0 ? 'x' : ''}</td>
                                        {row.quantities.map((quantity, quantityKey) =>
                                            <td key={quantityKey} className="text-center">{quantity ? quantity : ''}</td>
                                        )}
                                        <td className="text-right">{row.totalPrice}</td>
                                        {row.quantitiesAds.map((quantity, quantityKey) =>
                                            <td key={quantityKey} className="text-center">{quantity ? quantity : ''}</td>
                                        )}
                                        <td className="no-wrap">
                                            <button className="btn btn-primary" onClick={() => this.goToDetail(row.storeId)}>Sửa</button>&nbsp;
                                            <button className="btn btn-danger" onClick={() => this.deletePlan(row.storeId)}>Xoá</button>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td colSpan={8}><b>TỔNG CỘNG</b></td>
                                    <td className="text-center"><b>{data.totalAllVisited}</b></td>
                                    {Object.keys(data.totalAllQuantity).map((k, key) =>
                                        <td key={key} className="text-center"><b>{data.totalAllQuantity[k] ? data.totalAllQuantity[k] : ''}</b></td>
                                    )}
                                    <td className="text-right"><b>{data.totalAllPrice}</b></td>
                                    {Object.keys(data.totalAllQuantityAds).map((k, key) =>
                                        <td key={key} className="text-center"><b>{data.totalAllQuantityAds[k] ? data.totalAllQuantityAds[k] : ''}</b></td>
                                    )}
                                    <td></td>
                                </tr>
                            </tfoot>
                        </table>}
                    </div>
                </div>
                <div className="sticky-bottom">
                    <div className="group-btn">
                        <button className="btn btn-dark" onClick={() => this.props.history.push('/' + Routes.HomePageRoute.url)}>Home</button>
                        <button className="btn btn-dark" onClick={() => this.props.history.push('/' + Routes.StaffCalendarPageRoute.url, {date: date})}>Quay lại</button>
                        <button className="btn btn-primary" onClick={() => this.goToDetail()}>Tạo kế hoạch</button>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ settings }) => ({ settings });
export default withRouter(connect(mapStateToProps)(CalendarPlanPage));