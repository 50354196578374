import * as Redux from 'redux';
import { createBrowserHistory } from 'history';

import { routerMiddleware } from 'connected-react-router';
import logger from 'redux-logger';
import reducers from './reducers/index';


const history = createBrowserHistory();
const routeMiddleware = routerMiddleware(history);
let middlewares = [routeMiddleware];
const isDisabledLogger = true;
if (process.env.NODE_ENV !== 'production' && !isDisabledLogger) {
    middlewares = [...middlewares, logger];
}

const store = Redux.createStore(
    reducers(history),
    {},
    Redux.compose(Redux.applyMiddleware(...middlewares))
);
export default store;

export function configureStore() {
    return store;
}

export { history };