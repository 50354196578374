import React from 'react';
import { PageProps } from 'interfaces';
import { withRouter } from 'react-router-dom';
import * as Routes from 'pages/RouteLoader';
import store from 'reduxs/index';
import { setLoading } from 'reduxs/actions/Settings';
import { Field } from 'models/Settings';
import { getFields, getFormFields } from './fields';
import { DATE_FORMAT_FOR_DATABASE } from 'constants/Constants';
import { DisplayDateFormat } from 'util/ControlUtils';
import FormItem, { setStateByProperty } from 'components/Form';

interface State {
    fields: Field[];
    page: number;
    showMore: boolean;
    formData: {
        fromDate: string;
        toDate: string;
    };
    data: {
        id: string;
        storeId: string;
        date: string;
        name: string;
        status: string;
        isHighlight: boolean;
    }[];
}

interface Props {
    type: 'reportStore' | 'requestAds' | 'requestPayment' | 'requestStore' | 'planStore';
}

class Section extends React.Component<PageProps & Props, State> {
    constructor(props: PageProps & Props) {
        super(props);

        let fields = getFields();
        fields = fields.map(e => ({
            ...new Field(), ...e,
            isValid: true,
            isValidType: 'none'
        }));


        this.state = {
            fields: fields,
            page: 0,
            data: [],
            showMore: false,
            formData: {
                fromDate: '',
                toDate: ''
            }
        }
    }

    componentDidMount() {
        this.getData();
    }

    resetData = () => {
        this.setState({
            page: 0,
            data: [],
            showMore: false
        }, () => this.getData());
    }

    getData = () => {
        const { page, formData: { fromDate, toDate } } = this.state;
        const { type } = this.props;
        store.dispatch(setLoading(true));
        this.props.apiService.history(page, type, fromDate, toDate).then(res => {
            this.setState(prevState => ({ data: [...prevState.data, ...res], showMore: res.length === 3 }));
            store.dispatch(setLoading(false));
        });
    }


    // Custom Code
    onChangeHandle = (nameHtml, valueHtml) => {
        switch (nameHtml) {
            case 'filter[fromDate]':
                let fromDate = '';
                if (!isNaN(Date.parse(valueHtml))) {
                    fromDate = DisplayDateFormat(valueHtml, DATE_FORMAT_FOR_DATABASE);
                    if (isNaN(Date.parse(fromDate))) {
                        fromDate = '';
                    }
                }
                setStateByProperty(this, 'fromDate', nameHtml, fromDate);
                break;
            case 'filter[toDate]':
                let toDate = '';
                if (!isNaN(Date.parse(valueHtml))) {
                    toDate = DisplayDateFormat(valueHtml, DATE_FORMAT_FOR_DATABASE);
                    if (isNaN(Date.parse(toDate))) {
                        toDate = '';
                    }
                }
                setStateByProperty(this, 'toDate', nameHtml, toDate);
                break;
            default:
                break;
        }
        this.resetData();
    }

    loadMore = () => {
        this.setState(prevState => ({ page: prevState.page + 1 }), () => this.getData());
    }


    // Custom Code
    render() {
        const { type } = this.props;
        const { data, showMore, fields, formData } = this.state;

        const formFields = getFormFields(formData);

        return (
            <React.Fragment>
                <div className="item-ads history-filter">
                    <div className="row p-2">
                        <div className="col-sm-6 col-12">
                            {type === 'planStore' && <b>1. KẾ HOẠCH HOẠT ĐỘNG NHÀ THUỐC</b>}
                            {type === 'reportStore' && <b>2. BÁO CÁO HOẠT ĐỘNG NHÀ THUỐC</b>}
                            {type === 'requestAds' && <b>3. YÊU CẦU THI CÔNG</b>}
                            {type === 'requestPayment' && <b>4. YÊU CẦU TRẢ PHÍ QUẢNG CÁO</b>}
                            {type === 'requestStore' && <b>5. YÊU CẦU TẠO NHÀ THUỐC MỚI</b>}
                        </div>
                        {formFields.map((field, key) =>
                            <div className="col-sm-3 col-6 history-filter-input" key={key}>
                                <FormItem
                                    {...field.props}
                                    onChangeHandle={this.onChangeHandle}
                                    fields={fields}
                                />
                            </div>
                        )}
                    </div>
                </div>
                <div className="item-ads-table">
                    <table>
                        <thead>
                        </thead>
                        <tbody>
                            {data.length !== 0 && data.map((e, key) =>
                                <tr key={key}>
                                    <td><div onClick={() => this.props.history.push('/' + Routes.HistoryDetailPageRoute.url.replace(':storeId', e.storeId).replace(':id', e.id).replace(':type', type))}>Xem chi tiết</div></td>
                                    <td><div className="text-value">{e.date}</div></td>
                                    <td><div className="text-value bigger">{e.name}</div></td>
                                    <td><div className={`text-value bigger ${e.isHighlight ? 'text-danger' : ''}`}>{e.status}</div></td>
                                </tr>)}

                            {data.length === 0 && <tr><td colSpan={4}>Không có dữ liệu</td></tr>}
                        </tbody>
                    </table>
                </div>
                {showMore && <div className="mt-1 mb-3">
                    <button onClick={() => this.loadMore()} className="btn btn-primary btn-block">Xem thêm</button>
                </div>}
            </React.Fragment>
        );
    }
}

export default withRouter(Section);