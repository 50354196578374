import React from 'react';
import { connect } from 'react-redux';
import { PageProps } from 'interfaces';
import { Field, City, District } from 'models/Settings';
import { withRouter } from 'react-router-dom';
import FormItem, { setStateByProperty, checkValidations, forceAbs, getIdOrUndefined } from 'components/Form';
import Swal from 'sweetalert2';
import * as Routes from 'pages/RouteLoader';
import { getFields, getFormFieldsItemsOrder, getFormFieldsAdses, getFormFieldsItemsStocked } from './fields';
import { getFormFieldsSearchStore } from 'pages/RequestStorePage/fields';
import { interpret, formatMoney, DisplayDateFormat } from 'util/ControlUtils';
import store from 'reduxs/index';
import { setLoading, setStore } from 'reduxs/actions/Settings';
import Store, { Item, Ads } from 'models/Store';
import * as Constants from 'constants/Constants';
import _ from 'lodash';
import moment from 'moment';

interface State {
    disabledDraft: boolean;
    disabledSent: boolean;
    fields: Field[];
    returnURLType?: 'calendar_detail' | 'history_detail';
    returnURLParams?: {
        date?: string;
    };

    // Custom Code
    formData: {
        store: Store;
        city: City;
        district: District;
        wardName: string;
        street: string;
        streetNumber: string;
        inStockDate: string;
        itemsStocked: Item[];
        itemsOrder: Item[];
        itemsPrice: Item[];
        adses: Ads[];
        isVisited: boolean;
        date: string;
    };
    items: Item[];
    adses: Ads[];
    totalOrderPrice: number;
    initLoaded: boolean;
}

class PlanDetailPage extends React.Component<PageProps, State> {
    constructor(props: PageProps) {
        super(props);
        store.dispatch(setStore([]));
        let fields = getFields();
        fields = fields.map(e => ({
            ...new Field(), ...e,
            isValid: e.key === 'planStore.date' ? true : false,
            isValidType: e.key === 'planStore.date' ? 'none' : 'required'
        }));

        const { location: { state } } = props;

        this.state = {
            disabledDraft: true,
            disabledSent: true,
            fields: fields,
            returnURLType: state ? state.returnURLType : null,
            returnURLParams: state ? state.returnURLParams : null,

            // Custom Code
            formData: {
                store: new Store(),
                city: localStorage.cityId ? {...new City(), id: localStorage.cityId} : new City(),
                district: localStorage.districtId ? {...new District(), id: localStorage.districtId} : new District(),
                wardName: '',
                street: '',
                streetNumber: '',
                inStockDate: '',
                itemsStocked: [],
                itemsOrder: [],
                itemsPrice: [],
                adses: [],
                isVisited: true,
                date: state && state.returnURLParams && state.returnURLParams.date ? state.returnURLParams.date : moment(new Date()).format('YYYY-MM-DD')
            },
            items: [],
            adses: [],
            totalOrderPrice: 0,
            initLoaded: true
        }

        if (props.match.params.storeId && props.match.params.date) {
            this.state = {
                ...this.state,
                formData: {
                    ...this.state.formData,
                    date: props.match.params.date,
                    store: {...new Store(), id: parseInt(props.match.params.storeId)}
                },
                initLoaded: false
            }

            this.getData();
        }
    }

    static getDerivedStateFromProps(props, state) {
        const { initLoaded,formData } = state;
        const { settings: { stores, metadata } } = props;
        if (!initLoaded && stores.length && metadata.city.length) {
            const findStore = stores.find(e => e.id === getIdOrUndefined(formData.store));
            const selectedCity = metadata.city.find(e => findStore && e.id === findStore.cityId) || new City();
            const selectedDistrict = selectedCity ? selectedCity.district.find(e => findStore && e.id === findStore.districtId) || new District() : new District();
            localStorage.cityId = selectedCity.id;
            localStorage.districtId = selectedDistrict.id;

            let storeField = {};
            if (findStore) {
                storeField = {
                    wardName:findStore.wardName,
                    street: findStore.street,
                    streetNumber: findStore.streetNumber,
                }
                localStorage.wardName = findStore.wardName;
                localStorage.street = findStore.street;
                localStorage.streetNumber = findStore.streetNumber;
            }

            return { 
                ...state,
                formData: {
                    ...state.formData,
                    ...storeField,
                    city: selectedCity,
                    district: selectedDistrict
                },
                initLoaded: true
            };
        }
        return null;
    }

    componentDidMount() {
        store.dispatch(setLoading(true));
        const items = this.props.apiService.items();
        const adses = this.props.apiService.adses('planStore');
        Promise.all([items, adses]).then(([res1, res2]) => {
            this.setState({
                items: res1,
                adses: res2
            });
            store.dispatch(setLoading(false));
        })
    }

    resetSearchForm = () => {
        this.setState(prevState => ({
            formData: {
                ...prevState.formData,
                store: new Store(),
                inStockDate: '',
                itemsStocked: [],
                itemsOrder: [],
                itemsPrice: [],
                adses: [],
                isVisited: true
            },
            totalOrderPrice: 0,
            disabledDraft: true,
            disabledSent: true
        }));
    }

    getData = () => {
        setTimeout(() => {
            const { formData } = this.state;
            const storeId = getIdOrUndefined(formData.store);
            const date = formData.date;

            if (storeId <= 0) {
                this.setState({
                    formData: {
                        store: new Store(),
                        city: new City(),
                        district: new District(),
                        wardName: '',
                        street: '',
                        streetNumber: '',
                        inStockDate: '',
                        itemsStocked: [],
                        itemsOrder: [],
                        itemsPrice: [],
                        adses: [],
                        isVisited: true,
                        date: date
                    },
                    totalOrderPrice: 0,
                    disabledDraft: true,
                    disabledSent: true
                });
                
            } else {
                this.props.apiService.getPlanStore(storeId, date).then(res => {
                    this.setState(prevState => ({
                        formData: {
                            ...prevState.formData,
                            isVisited: res.isVisited,
                            inStockDate: res.inStockDate,
                            itemsStocked: res.itemsStocked,
                            itemsOrder: res.itemsOrder,
                            itemsPrice: res.itemsPrice,
                            adses: res.adses
                        },
                        totalOrderPrice: this.calculateTotalOrderPrice(res.itemsOrder, res.itemsPrice),
                        disabledDraft: true,
                        disabledSent: !_.isEmpty(res.itemsOrder) || !_.isEmpty(res.adses) ? false : true
                    }))
                });
                
            }
        }, 0);
    }

    calculateTotalOrderPrice = (itemsOrderData, itemsPriceData) => {
        let totalOrderPrice = 0;
        Object.keys(itemsOrderData).map(k => {
            const e = itemsOrderData[k];
            if (e.quantity) {
                const findPrice = itemsPriceData.find(f => f.id === e.id);
                if (findPrice && findPrice.price) {
                    totalOrderPrice += findPrice.price * e.quantity;
                }
            }
        });
        return totalOrderPrice;
    }

    // Custom Code
    onChangeHandle = (nameHtml, valueHtml) => {
        const { settings: { stores } } = this.props;
        const { formData } = this.state;
        const storeId = getIdOrUndefined(formData.store);
        let itemsOrder: Item[] = [];
        let adses: Ads[] = [];

        switch (nameHtml) {
            case 'searchStore[name]':
                setStateByProperty(this, 'store', nameHtml, { id: valueHtml, name: '' });
                const existStoreName = stores.find(e => e.id === valueHtml);
                if (existStoreName) {
                    setStateByProperty(this, 'city', 'searchStore[city]', { id: existStoreName.cityId, name: '' });
                    setStateByProperty(this, 'district', 'searchStore[district]', { id: existStoreName.districtId, name: '' });
                    setStateByProperty(this, 'name', 'store[name]', existStoreName.name);
                    setStateByProperty(this, 'wardName', 'store[wardName]', existStoreName.wardName);
                    setStateByProperty(this, 'street', 'store[street]', existStoreName.street);
                    setStateByProperty(this, 'streetNumber', 'store[streetNumber]', existStoreName.streetNumber);
                    localStorage.cityId = existStoreName.cityId;
                    localStorage.districtId = existStoreName.districtId;
                    localStorage.wardName = existStoreName.wardName;
                    localStorage.street = existStoreName.street;
                    localStorage.streetNumber = existStoreName.streetNumber;
                } else {
                    setStateByProperty(this, 'city', 'searchStore[city]', { id: '', name: '' });
                    setStateByProperty(this, 'district', 'searchStore[district]', { id: '', name: '' });
                    setStateByProperty(this, 'name', 'store[name]', '');
                    setStateByProperty(this, 'wardName', 'store[wardName]', '');
                    setStateByProperty(this, 'street', 'store[street]', '');
                    setStateByProperty(this, 'streetNumber', 'store[streetNumber]', '');
                }
                this.getData();
                break;
            case 'searchStore[city]':
                setStateByProperty(this, 'city', nameHtml, { id: valueHtml, name: '' });
                setStateByProperty(this, 'district', 'searchStore[district]', { id: '', name: '' });
                setStateByProperty(this, 'wardName', 'searchStore[wardName]', '');
                setStateByProperty(this, 'street', 'searchStore[street]', '');
                setStateByProperty(this, 'streetNumber', 'searchStore[streetNumber]', '');
                localStorage.cityId = valueHtml;
                localStorage.districtId = '';
                localStorage.wardName = '';
                localStorage.street = '';
                this.resetSearchForm();
                break;
            case 'searchStore[district]':
                setStateByProperty(this, 'district', nameHtml, { id: valueHtml, name: '' });
                setStateByProperty(this, 'wardName', 'searchStore[wardName]', '');
                setStateByProperty(this, 'street', 'searchStore[street]', '');
                setStateByProperty(this, 'streetNumber', 'searchStore[streetNumber]', '');
                localStorage.districtId = valueHtml;
                localStorage.wardName = '';
                localStorage.street = '';
                this.resetSearchForm();
                break;
            case 'searchStore[wardName]':
                setStateByProperty(this, 'wardName', nameHtml, valueHtml);
                setStateByProperty(this, 'street', 'searchStore[street]', '');
                setStateByProperty(this, 'streetNumber', 'searchStore[streetNumber]', '');
                localStorage.wardName = valueHtml;
                localStorage.street = '';
                this.resetSearchForm();
                break;
            case 'searchStore[street]':
                setStateByProperty(this, 'street', nameHtml, valueHtml);
                setStateByProperty(this, 'streetNumber', 'searchStore[streetNumber]', '');
                localStorage.street = valueHtml;
                this.resetSearchForm();
                break;
            case 'searchStore[streetNumber]':
                setStateByProperty(this, 'streetNumber', nameHtml, valueHtml);
                const existStoreStreetNumber = stores.find(e => e.streetNumber === valueHtml);
                if (existStoreStreetNumber) {
                    setStateByProperty(this, 'store', nameHtml, { id: existStoreStreetNumber.id, name: '' });
                    setStateByProperty(this, 'city', 'searchStore[city]', { id: existStoreStreetNumber.cityId, name: '' });
                    setStateByProperty(this, 'district', 'searchStore[district]', { id: existStoreStreetNumber.districtId, name: '' });
                    setStateByProperty(this, 'name', 'store[name]', existStoreStreetNumber.name);
                    setStateByProperty(this, 'wardName', 'store[wardName]', existStoreStreetNumber.wardName);
                    setStateByProperty(this, 'street', 'store[street]', existStoreStreetNumber.street);
                } else {
                    setStateByProperty(this, 'store', nameHtml, { id: '', name: '' });
                    setStateByProperty(this, 'city', 'searchStore[city]', { id: '', name: '' });
                    setStateByProperty(this, 'district', 'searchStore[district]', { id: '', name: '' });
                    setStateByProperty(this, 'name', 'store[name]', '');
                    setStateByProperty(this, 'wardName', 'store[wardName]', '');
                    setStateByProperty(this, 'street', 'store[street]', '');
                }
                this.getData();
                break;
            case 'planStore[date]':
                let date = '';
                if (!isNaN(Date.parse(valueHtml))) {
                    date = DisplayDateFormat(valueHtml, Constants.DATE_FORMAT_FOR_DATABASE);
                    if (isNaN(Date.parse(date))) {
                        date = '';
                    }
                }
                setStateByProperty(this, 'date', nameHtml, date);
                this.getData();
                break;
            case 'planStore[isVisited]':
                setStateByProperty(this, 'isVisited', nameHtml, !formData.isVisited);
                if (storeId > 0) {
                    this.setState({ disabledDraft: false, disabledSent: false });
                }
                break;
            default:
                const extractNameHTML = interpret(nameHtml);
                if (extractNameHTML) {
                    if (extractNameHTML['name'] === 'itemsOrder' &&
                        extractNameHTML['indices'] &&
                        extractNameHTML['indices'].length === 2) { // array of sub field
                        const extractName = extractNameHTML['indices'][1];
                        const extractIndex = extractNameHTML['indices'][0];

                        if (formData.itemsOrder) {
                            itemsOrder = formData.itemsOrder;
                        }
                        if (!itemsOrder[extractIndex]) {
                            itemsOrder[extractIndex] = new Item();
                            itemsOrder[extractIndex].id = extractIndex;
                        }
                        if (extractName === 'quantity') {
                            itemsOrder[extractIndex].quantity = forceAbs(valueHtml);
                            setStateByProperty(this, 'itemsOrder', nameHtml, itemsOrder, 'quantity');
                            const { formData } = this.state;
                            const totalOrderPrice = this.calculateTotalOrderPrice(formData.itemsOrder, formData.itemsPrice);
                            if (storeId > 0) {
                                this.setState({ disabledDraft: false, disabledSent: false, totalOrderPrice: totalOrderPrice });
                            }
                        }
                    }
                    if (extractNameHTML['name'] === 'adses' &&
                        extractNameHTML['indices'] &&
                        extractNameHTML['indices'].length === 2) { // array of sub field
                        const extractName = extractNameHTML['indices'][1];
                        const extractIndex = extractNameHTML['indices'][0];

                        if (formData.adses) {
                            adses = formData.adses;
                        }
                        if (!adses[extractIndex]) {
                            adses[extractIndex] = new Ads();
                            adses[extractIndex].id = extractIndex;
                        }
                        if (extractName === 'quantity') {
                            adses[extractIndex].quantity = forceAbs(valueHtml);
                            setStateByProperty(this, 'adses', nameHtml, adses, 'quantity');
                            if (storeId > 0) {
                                this.setState({ disabledDraft: false, disabledSent: false });
                            }
                        }
                    }
                }
                break;
        }
    }

    goBack = () => {
        const { formData, returnURLType } = this.state;
        if (returnURLType && returnURLType === 'calendar_detail') {
            this.props.history.push('/' + Routes.CalendarPlanPageRoute.url.replace(':date', formData.date));
        } else {
            this.props.history.push('/' + Routes.HistoryPageRoute.url);
        }
    }

    // Custom Code
    onSubmit = (type: 'draft' | 'sent') => {
        const isValid = checkValidations(this);
        const { formData, returnURLType } = this.state;

        if (isValid) {
            Swal.fire({
                html: 'Vui lòng chờ ...<br/><div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>',
                showConfirmButton: false,
                allowOutsideClick: false
            })
            this.props.apiService.postPlanStore(formData, type).then(() => {
                if (type === 'sent') {
                    Swal.fire({
                        ...Constants.SWAL_COMMON_STYLE,
                        text: 'Gửi báo cáo thành công.',
                        type: 'success',
                        confirmButtonText: 'Đóng'
                    }).then(() => {
                        this.props.history.push('/' + Routes.HomePageRoute.url);
                        if (this.props.match.params.date) {
                            if (returnURLType && returnURLType === 'calendar_detail') {
                                this.props.history.push('/' + Routes.CalendarPlanPageRoute.url.replace(':date', formData.date));
                            } else {
                                this.props.history.push('/' + Routes.HistoryPageRoute.url);
                            }
                        } else {
                            if (returnURLType && returnURLType === 'calendar_detail') {
                                this.props.history.push(`/${Routes.PlanStorePageAddRoute.url}`, {returnURLType: 'calendar_detail', returnURLParams: {date: formData.date}});
                            } else if (returnURLType && returnURLType === 'history_detail') {
                                this.props.history.push(`/${Routes.PlanStorePageAddRoute.url}`, {returnURLType: 'history_detail', returnURLParams: {date: formData.date}});
                            } else {
                                this.props.history.push(`/${Routes.PlanStorePageAddRoute.url}`);
                            }
                        }
                    });
                } else {
                    this.getData();
                    Swal.close();
                }
            });
        }
    }

    // Custom Code
    render() {
        const { fields, formData, disabledDraft, disabledSent, adses, items, totalOrderPrice, returnURLParams } = this.state;
        const { settings: { metadata }, match: { params } } = this.props;
        const formFieldsSearchStore = getFormFieldsSearchStore(formData, metadata);
        const formFieldsItemsOrder = getFormFieldsItemsOrder(formData, items);
        const formFieldsItemsStocked = getFormFieldsItemsStocked(formData, items);
        const formFieldsAdses = getFormFieldsAdses(formData, adses);

        const isVisited = {
            props: {
                type: 'checkbox',
                className: '',
                data: formData.isVisited ? 1 : 0,
                attribute: {
                    checked: formData.isVisited,
                    disabled: getIdOrUndefined(formData.store) <= 0
                },
                htmlFor: 'planStore[isVisited]',
                fieldName: 'planStore[isVisited]',
                fieldLabel: 'Viếng thăm'
            },
        };

        const date = {
            props: {
                type: 'calendar',
                className: 'mb-1',
                htmlFor: 'planStore[date]',
                fieldName: 'planStore[date]',
                attribute: {
                    disabled: !!((returnURLParams && returnURLParams.date) || this.props.match.params.date)
                },
                datePickerData: formData.date
                    ? new Date(formData.date)
                    : undefined,
                fieldLabel: 'Ngày'
            },
        };

        return (
            <div className="report-store-page">
                <div className="sticky-top">
                    <div className="row pt-2">
                        <div className="col-6">TDV: {metadata.userInfo.name}</div>
                        <div className="col-6 text-right">Nhóm: {metadata.userInfo.groupName}</div>
                    </div>
                    <h3 className="head-title mt-0 mb-0 pt-3">KẾ HOẠCH HOẠT ĐỘNG NHÀ THUỐC</h3>
                    <div className="action row">
                        {formFieldsSearchStore.map((field, key) =>
                            <div key={`${getIdOrUndefined(formData.city)}-${getIdOrUndefined(formData.district)}-${formData.wardName}-${formData.street}-${formData.streetNumber}-${key}`} className="mt-1 col-6 col-lg-2">
                                <FormItem
                                    {...field.props}
                                    attribute={{disabled: !!(params.storeId && params.date)}}
                                    onChangeHandle={this.onChangeHandle}
                                    fields={fields}
                                />
                            </div>
                        )}
                    </div>
                </div>
                <div className="holder">
                    <div className="item-ads row">
                        <div className="col-12 col-sm-6">
                            <div className="item-ads-table mt-3">
                                <table>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <FormItem
                                                    {...isVisited.props}
                                                    onChangeHandle={this.onChangeHandle}
                                                    fields={fields}
                                                />
                                                Tổng tiền: {formatMoney(totalOrderPrice, 0)}đ
                                            </td>
                                            <td>
                                                <FormItem
                                                    {...date.props}
                                                    onChangeHandle={this.onChangeHandle}
                                                    fields={fields}
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>ĐẶT HÀNG</th>
                                            <th className="text-center">SỐ LƯỢNG</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {formFieldsItemsOrder.map((field, key) =>
                                            <tr key={key}>
                                                <td>{field.name}</td>
                                                <td>
                                                    <FormItem
                                                        {...field.props}
                                                        onChangeHandle={this.onChangeHandle}
                                                        fields={fields}
                                                    /></td>
                                                <td>{field.unitName}</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6">
                            <div className="item-ads-table mt-3">
                                {formData.inStockDate && <p>{formData.inStockDate}</p>}
                                <table>
                                    <thead>
                                        <tr>
                                            <th>HÀNG TỒN</th>
                                            <th className="text-center">SỐ LƯỢNG</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {formFieldsItemsStocked.map((field, key) =>
                                            <tr key={key}>
                                                <td>{field.name}</td>
                                                <td>
                                                    <FormItem
                                                        {...field.props}
                                                        onChangeHandle={this.onChangeHandle}
                                                        fields={fields}
                                                    /></td>
                                                <td>{field.unitName}</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6">
                            <div className="item-ads-table mt-3">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>VDQC</th>
                                            <th className="text-center">SỐ LƯỢNG</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {formFieldsAdses.map((field, key) =>
                                            <tr key={key}>
                                                <td>{field.name}</td>
                                                <td>
                                                    <FormItem
                                                        {...field.props}
                                                        onChangeHandle={this.onChangeHandle}
                                                        fields={fields}
                                                    /></td>
                                                <td>{field.unitName}</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                </div>

                <div className="sticky-bottom">
                    <div className="group-btn">
                        {!((returnURLParams && returnURLParams.date) || this.props.match.params.date) && <button className="btn btn-dark" onClick={() => this.props.history.push('/' + Routes.RouteHomePageRoute.url)}>Quay lại</button>}
                        {((returnURLParams && returnURLParams.date) || this.props.match.params.date) && <button className="btn btn-dark" onClick={() => this.goBack()}>Quay lại</button>}
                        <button disabled={disabledDraft} className="btn btn-dark" onClick={() => this.onSubmit('draft')}>Lưu tạm</button>
                        <button disabled={disabledSent} className="btn btn-primary" onClick={() => this.onSubmit('sent')}>Lưu kế hoạch</button>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ settings }) => ({ settings });
export default withRouter(connect(mapStateToProps)(PlanDetailPage));