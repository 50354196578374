import Settings, { Field, Staff, District, City } from "models/Settings";
import { getIdOrUndefined } from "components/Form";

export const getFields = () => {
    const fields: Field[] = [
        {
            key: "filter.staff",
            isRequired: false,
            isVisible: true,
            regex: "",
            maxLength: 50,
            name: "Staff"
        },
        {
            key: "filter.city",
            isRequired: false,
            isVisible: true,
            regex: "",
            maxLength: 50,
            name: "City"
        },
        {
            key: "filter.district",
            isRequired: false,
            isVisible: true,
            regex: "",
            maxLength: 50,
            name: "District"
        },
        {
            key: "filter.fromDate",
            isRequired: true,
            isVisible: true,
            regex: "",
            maxLength: 50,
            name: "From Date"
        },
        {
            key: "filter.toDate",
            isRequired: true,
            isVisible: true,
            regex: "",
            maxLength: 50,
            name: "To Date"
        },
    ];

    return fields;
}

export const getFormFields = (formData, cities: City[], settings: Settings) => {
    const { metadata, role } = settings;
    const selectedCity = cities.find(e => e.id === getIdOrUndefined(formData.city));
    const filterFields = role === 'staff' ? [
        {
            props: {
                type: 'select',
                htmlFor: 'filter[city]',
                fieldName: 'filter[city]',
                className: 'select-custom',
                data: cities.length ? getIdOrUndefined(formData.city) : undefined,
                fieldLabel: 'Tỉnh / Thành phố',
                selectAllLabel: 'Tất cả',
                options: cities.map((e: City) => ({value: e.id, label: e.name}))
            },
        },
        {
            props: {
                type: 'select',
                htmlFor: 'filter[district]',
                fieldName: 'filter[district]',
                className: 'select-custom',
                data: cities.length ? getIdOrUndefined(formData.district) : undefined,
                fieldLabel: 'Quận / huyện',
                selectAllLabel: 'Tất cả',
                options: selectedCity ? selectedCity.district.map((e: District) => ({value: e.id, label: e.name})) : []
            },
        },
    ] : [
        {
            props: {
                type: 'select',
                htmlFor: 'filter[staff]',
                fieldName: 'filter[staff]',
                className: 'select-custom',
                data: getIdOrUndefined(formData.staff),
                fieldLabel: 'Chọn TDV',
                selectAllLabel: 'Cả nhóm',
                options: metadata.staffs.map((e: Staff) => ({ value: e.id, label: e.fullname }))
            },
        },
    ];
    const formFields = [
        ...filterFields,
        {
            props: {
                type: 'calendar',
                htmlFor: 'filter[fromDate]',
                fieldName: 'filter[fromDate]',
                className: '',
                datePickerData: formData.fromDate
                    ? new Date(formData.fromDate)
                    : undefined,
                fieldLabel: 'Từ ngày'
            },
            className: ''
        },
        {
            props: {
                type: 'calendar',
                htmlFor: 'filter[toDate]',
                fieldName: 'filter[toDate]',
                className: '',
                datePickerData: formData.toDate
                    ? new Date(formData.toDate)
                    : undefined,
                fieldLabel: 'Đến ngày'
            },
            className: ''
        },
    ];

    return formFields;
}