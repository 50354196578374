import { Field } from "models/Settings";
import { getIdOrUndefined } from "components/Form";
import { Item, Ads } from 'models/Store';
import { getSearchStoreFields } from "pages/RequestStorePage/fields";

export const getFields = () => {
    const fields: Field[] =  [
        ...getSearchStoreFields(),
        {
            key: "itemsStocked.quantity",
            isRequired: false,
            isVisible: true,
            regex: "",
            maxLength: 50,
            name: "Items Stocked Quantity"
        },
        {
            key: "itemsOrder.quantity",
            isRequired: false,
            isVisible: true,
            regex: "",
            maxLength: 50,
            name: "Items Order Quantity"
        },
        {
            key: "adses.quantity",
            isRequired: false,
            isVisible: true,
            regex: "",
            maxLength: 50,
            name: "Adses Quantity"
        },
        {
            key: "reportStore.isVisited",
            isRequired: false,
            isVisible: true,
            regex: "",
            maxLength: 50,
            name: "Is Visited"
        },
    ];

    return fields;
}

export const getFormFieldsItemsStocked = (formData, items: Item[]) => {
    let formFields: any[] = [];
    items.forEach(item => {

        const itemData: Item = formData.itemsStocked[item.id] ? formData.itemsStocked[item.id] : new Item();
        formFields.push({
            name: item.name,
            unitName: item.unitName,
            props: {
                type: 'number',
                className: 'text-right',
                data: itemData.quantity !== 0 ? itemData.quantity : '',
                htmlFor: 'itemsStocked[' + item.id + '][quantity]',
                fieldName: 'itemsStocked[' + item.id + '][quantity]',
                attribute: {
                    disabled: getIdOrUndefined(formData.store) <= 0
                }
            },
        });
    });

    return formFields;
}

export const getFormFieldsItemsOrder = (formData, items: Item[]) => {
    let formFields: any[] = [];
    items.forEach(item => {

        const itemData: Item = formData.itemsOrder[item.id] ? formData.itemsOrder[item.id] : new Item();
        formFields.push({
            name: item.name,
            unitName: item.unitName,
            props: {
                type: 'number',
                className: 'text-right',
                data: itemData.quantity !== 0 ? itemData.quantity : '',
                htmlFor: 'itemsOrder[' + item.id + '][quantity]',
                fieldName: 'itemsOrder[' + item.id + '][quantity]',
                attribute: {
                    disabled: getIdOrUndefined(formData.store) <= 0
                }
            },
        });
    });

    return formFields;
}

export const getFormFieldsAdses = (formData, adses: Ads[]) => {
    let formFields: any[] = [];
    adses.forEach(item => {

        const adsData: Ads = formData.adses[item.id] ? formData.adses[item.id] : new Ads();
        formFields.push({
            name: item.name,
            unitName: item.unitName,
            props: {
                type: 'number',
                className: 'text-right',
                data: adsData.quantity !== 0 ? adsData.quantity : '',
                htmlFor: 'adses[' + item.id + '][quantity]',
                fieldName: 'adses[' + item.id + '][quantity]',
                attribute: {
                    disabled: getIdOrUndefined(formData.store) <= 0
                }
            },
            propsIsReplace: {
                type: 'checkbox',
                className: '',
                data: adsData.isReplace ? 1 : 0,
                attribute: {
                    checked: adsData.isReplace,
                    disabled: getIdOrUndefined(formData.store) <= 0
                },
                htmlFor: 'adses[' + item.id + '][isReplace]',
                fieldName: 'adses[' + item.id + '][isReplace]',
                fieldLabel: 'Thay VD cũ'
            },
        });
    });

    return formFields;
}

export const getFormFieldsOther = (formData) => {
    const formFields = [
        {
            props: {
                type: 'textarea',
                className: '',
                data: formData.note,
                htmlFor: 'other[note]',
                fieldName: 'other[note]',
                attribute: {
                    disabled: getIdOrUndefined(formData.store) <= 0,
                    style: {
                        width: '100%'
                    },
                    rows: 3
                },
                fieldLabel: 'GHI CHÚ (nếu có)'
            },
        }
    ];

    return formFields;
}