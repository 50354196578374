import Store from "./Store";

export class UserInfo {
    name = '';
    groupName = '';
    type: 'leader' | 'manager' | 'general_manager' | '' = '';
}

export class City {
    id = 0;
    name = '';
    district: District[] = [];
}

export class District {
    id = 0;
    name = '';
}

export class Period {
    name = '';
    deadline = '';
}

export class PeriodOption {
    id = '';
    name = '';
    deadline = '';
}

export class Metadata {
    userInfo = new UserInfo();
    city: City[] = []; 
    period = new Period();
    allPeriods: PeriodOption[] = [];
    staffs: Staff[] = [];
}

export class Coord {
    lat = 0;
    long = 0;
}

export class Field {
    key = '';
    name = '';
    isRequired = false;
    isVisible = true;
    regex = '';
    maxLength = 5000;
    isDirty? = false;
    isValid? = false;
    isValidType?: 'required' | 'regex' | 'none' = 'none';
    customMsg? = '';
    nested?: Field[];
    static clone = (obj: Field) => {
        return {
            key: obj.key,
            name: obj.name,
            isRequired: obj.isRequired,
            isVisible: obj.isVisible,
            regex: obj.regex,
            maxLength: obj.maxLength,
            isDirty: obj.isDirty,
            isValid: obj.isValid,
            isValidType: obj.isValidType,
            customMsg: obj.customMsg
        }
    }
}

export class Option {
    value: number | string = '';
    label = '';
}

export class Staff {
    id = 0;
    fullname = '';
}

export class StaffGroup {
    id = 0;
    name = '';
}

export default class Settings {
    loading = false;
    metadata = new Metadata();
    stores: Store[] = [];
    coord = new Coord();
    role: 'staff' | 'user' = 'staff';
}