import { Field } from "models/Settings";
import { getIdOrUndefined } from "components/Form";
import { Ads } from 'models/Store';
import { getSearchStoreFields } from "pages/RequestStorePage/fields";

export const getFields = () => {
    const fields: Field[] = [
        ...getSearchStoreFields(),
        {
            key: "adses.quantity",
            isRequired: false,
            isVisible: true,
            regex: "",
            maxLength: 50,
            name: "Adses Quantity"
        },
        {
            key: "adses.option",
            isRequired: false,
            isVisible: true,
            regex: "",
            maxLength: 50,
            name: "Adses Option"
        },
    ];

    return fields;
}

export const getFormFieldsAdses = (formData, adses: Ads[]) => {
    let formFields: any[] = [];
    adses.forEach(item => {

        const adsData: Ads = formData.adses[item.id] ? formData.adses[item.id] : new Ads();
        let options: any[] = [];
        item.options.forEach((option, key) => {
            options.push({
                props: {
                    type: 'radio',
                    className: 'inline-block',
                    data: option.id,
                    htmlFor: 'adses[' + option.id + '][option]',
                    fieldName: 'adses[' + item.id + '][option]',
                    attribute: {
                        disabled: getIdOrUndefined(formData.store) <= 0,
                        checked: adsData.optionId ? (option.id === adsData.optionId) : (key === 0)
                    },
                    fieldLabel: option.name
                },
            })
        })
        formFields.push({
            name: item.name,
            unitName: item.unitName,
            props: {
                type: 'number',
                className: 'text-right',
                data: adsData.quantity !== 0 ? adsData.quantity : '',
                htmlFor: 'adses[' + item.id + '][quantity]',
                fieldName: 'adses[' + item.id + '][quantity]',
                attribute: {
                    disabled: getIdOrUndefined(formData.store) <= 0
                }
            },
            propsIsReplace: {
                type: 'checkbox',
                className: '',
                data: adsData.isReplace ? 1 : 0,
                attribute: {
                    checked: adsData.isReplace,
                    disabled: getIdOrUndefined(formData.store) <= 0
                },
                htmlFor: 'adses[' + item.id + '][isReplace]',
                fieldName: 'adses[' + item.id + '][isReplace]',
                fieldLabel: 'Thay VD cũ'
            },
            options: options
        });
    });

    return formFields;
}

export const getFormFieldsOther = (formData) => {
    const formFields = [
        {
            props: {
                type: 'textarea',
                className: '',
                data: formData.note,
                htmlFor: 'other[note]',
                fieldName: 'other[note]',
                attribute: {
                    disabled: getIdOrUndefined(formData.store) <= 0,
                    style: {
                        width: '100%'
                    },
                    rows: 3
                },
                fieldLabel: 'GHI CHÚ (nếu có)'
            },
        }
    ];

    return formFields;
}