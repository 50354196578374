import React from 'react';
import {
    Route,
    Switch,
    withRouter
} from 'react-router-dom';
import CurrentLocale from 'util/FormatMessages';
import { connect } from 'react-redux';
import Error404 from 'pages/Error404';
import ApiContext from 'util/ApiContext';
import HomePage from './HomePage';
import RouteLoader from './RouteLoader';
import { Metadata } from 'models/Settings';
import { PageProps } from 'interfaces';
import Loading from 'components/Loading';
import { VelocityComponent } from 'velocity-react';
import store from 'reduxs/index';
import { setLoading, setMetadata, setRole } from 'reduxs/actions/Settings';

class AppRoute extends React.Component<PageProps, {}> {
    constructor(props: PageProps) {
        super(props);
        store.dispatch(setLoading(true));
    }

    componentDidMount() {
        this.props.apiService.info().then(res => {
            const metadata = res as Metadata;
            if (metadata.city.length === 0) {
                localStorage.cityId = '';
                localStorage.districtId = '';
            }
            store.dispatch(setMetadata(metadata));
            store.dispatch(setRole(res.role));
            store.dispatch(setLoading(false));
        });
    }

    render() {
        const { match, settings: { loading } } = this.props;
        return (
            <div className="app-container">
                <CurrentLocale />
                <div className="page-container">
                    <main className="main-content">
                        <Switch>
                            <Route
                                path={`${match.url}`}
                                exact
                                render={props => (
                                    <ApiContext.Consumer>
                                        {svrs => <HomePage {...props} {...svrs} />}
                                    </ApiContext.Consumer>
                                )}
                            />
                            {RouteLoader.map(({ url, Component }) =>
                                <Route
                                    key={url}
                                    path={`${match.url}${url}`}
                                    exact={true}
                                    render={props => (
                                        <ApiContext.Consumer>
                                            {svrs => <Component {...props} {...svrs} />}
                                        </ApiContext.Consumer>
                                    )}
                                />)}
                            <Route component={Error404} />
                        </Switch>
                        <VelocityComponent animation={{ opacity: loading ? 1 : 0 }} duration={500}>
                            <Loading />
                        </VelocityComponent>
                    </main>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ settings }) => ({ settings });
export default withRouter(connect(mapStateToProps)(AppRoute));
