import React from 'react';
import { connect } from 'react-redux';
import { PageProps } from 'interfaces';
import { withRouter } from 'react-router-dom';
import * as Routes from 'pages/RouteLoader';
import store from 'reduxs/index';
import { setLoading } from 'reduxs/actions/Settings';
import FormItem, { setStateByProperty, checkValidations } from 'components/Form';
import { Field } from 'models/Settings';
import { getTypeName } from 'util/ControlUtils';

interface State {
    data: {
        storeId: string;
        refId: string;
        replaceNote: string;
        storename: string;
        streetNumber: string;
        street: string;
        wardName: string;
        districtName: string;
        cityName: string;
        pharmacistName: string;
        ownerName: string;
        phone: string;
        paymentAccountName: string;
        paymentIdentityCard: string;
        paymentAccountNumber: string;
        paymentBankName: string;
        isPending: boolean;
        isApproved: boolean;
        isDenied: boolean;
        rejectNote: string;
        imgBusinessCert: string;
        imgGppCert: string;
        imgBankCert: string;
        imgIdCert: string;
        imgDelegateCert: string;
    }[];
    showRejectForm: boolean;
    fields: Field[];
    formData: {
        id: string;
        note: string;
    };
}

class ManagerRequestStorePage extends React.Component<PageProps, State> {
    constructor(props: PageProps) {
        super(props);

        this.state = {
            data: [],
            showRejectForm: false,
            fields: [
                {
                    key: "reject.note",
                    isRequired: true,
                    isVisible: true,
                    regex: "",
                    maxLength: 500,
                    name: "Note",
                    isValid: false,
                    isValidType: 'required'
                }
            ],
            formData: {
                id: '',
                note: ''
            },
        }
    }

    componentDidMount = () => {
        this.getData();
    }

    getData = () => {
        store.dispatch(setLoading(true));
        this.props.apiService.getRequestStorePending().then(res => {
            this.setState({
                data: res
            });
            store.dispatch(setLoading(false));
        })
    }

    doApprove = (isApprove, id) => {
        if (isApprove) {
            this.props.apiService.doStoreApprove(true, id, '').then(() => {
                this.getData();
            });
        } else {
            this.setState({
                showRejectForm: true, 
                formData: {note: '', id: id}, 
                fields: [
                    {
                        key: "reject.note",
                        isRequired: true,
                        isVisible: true,
                        regex: "",
                        maxLength: 500,
                        name: "Note",
                        isValid: false,
                        isValidType: 'required'
                    }
                ]});
        }
    }

    doReject = () => {
        const isValid = checkValidations(this);
        if (isValid) {
            const { formData: { note, id } } = this.state;
            this.props.apiService.doStoreApprove(false, id, note).then(() => {
                this.getData();
                this.setState({showRejectForm: false});
            });
        }
    }

    doCloseRejectForm = () => {
        this.setState({showRejectForm: false});
    }

    // Custom Code
    onChangeHandle = (nameHtml, valueHtml) => {
        switch (nameHtml) {
            case 'reject[note]':
                setStateByProperty(this, 'note', nameHtml, valueHtml);
                break;
            default:
                break;
        }
    }

    // Custom Code
    render() {
        const { settings: { metadata } } = this.props;
        const { data, showRejectForm, formData, fields } = this.state;

        const rejectNote = {
            props: {
                type: 'textarea',
                className: '',
                data: formData.note,
                htmlFor: 'reject[note]',
                fieldName: 'reject[note]',
                attribute: {
                    style: {
                        width: '100%'
                    },
                    rows: 10
                },
                fieldLabel: 'Lý do không duyệt'
            },
        };
        
        return (
            <div className="manager-report-page">
                <div className="sticky-top">
                    <div className="row pt-2">
                        <div className="col-6">{getTypeName(metadata.userInfo)}: {metadata.userInfo.name}</div>
                        <div className="col-6 text-right">Nhóm: {metadata.userInfo.groupName}</div>
                    </div>
                    <h3 className="head-title mt-0 mb-0 pt-3">DUYỆT YÊU CẦU TẠO NHÀ THUỐC MỚI</h3>
                    
                </div>

                <div className="holder">
                    {!showRejectForm && <div className="report-table mt-3">
                        <table>
                            <thead>
                                <tr>
                                    <th className="text-center">STT</th>
                                    <th className="text-center sticky-column">Tên NT</th>
                                    <th className="text-center">Số nhà</th>
                                    <th className="text-center">Đường</th>
                                    <th className="text-center">Phường/Xã</th>
                                    <th className="text-center">Quận/Huyện</th>
                                    <th className="text-center">Tỉnh/TP</th>
                                    <th className="text-center">Tên dược sĩ</th>
                                    <th className="text-center">Tên chủ NT</th>
                                    <th className="text-center">Số ĐT chủ NT</th>
                                    <th className="text-center">Người hưởng phí QC</th>
                                    <th className="text-center">CMND người HP QC</th>
                                    <th className="text-center">Số TK ngân hàng</th>
                                    <th className="text-center">Ngân hàng</th>
                                    <th className="text-center">Hình giấy ĐKKD</th>
                                    <th className="text-center">Hình giấy GPP</th>
                                    <th className="text-center">Hình giấy uỷ quyền</th>
                                    <th className="text-center">Hình CMND</th>
                                    <th className="text-center">Hình TK ngân hàng</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.map((row, key) =>
                                    <tr key={key}>
                                        <td>{(key + 1)}</td>
                                        <td className="no-wrap sticky-column">
                                            {row.storename}
                                            {parseInt(row.refId) > 0 && <>
                                                <br/><span className="replace-note">Thay thế (lý do): {row.replaceNote ? row.replaceNote : '-'}</span>
                                            </>}
                                        </td>
                                        <td className="no-wrap">{row.streetNumber}</td>
                                        <td className="no-wrap">{row.street}</td>
                                        <td className="no-wrap">{row.wardName}</td>
                                        <td className="no-wrap">{row.districtName}</td>
                                        <td className="no-wrap">{row.cityName}</td>
                                        <td className="no-wrap">{row.pharmacistName}</td>
                                        <td className="no-wrap">{row.ownerName}</td>
                                        <td className="no-wrap">{row.phone}</td>
                                        <td className="no-wrap">{row.paymentAccountName}</td>
                                        <td className="no-wrap">{row.paymentIdentityCard}</td>
                                        <td className="no-wrap">{row.paymentAccountNumber}</td>
                                        <td className="no-wrap">{row.paymentBankName}</td>
                                        <td className="no-wrap">{row.imgBusinessCert && <a href={row.imgBusinessCert} className="block" target="_blank" rel="noopener noreferrer">Giấy ĐKKD</a>}</td>
                                        <td className="no-wrap">{row.imgGppCert && <a href={row.imgGppCert} className="block" target="_blank" rel="noopener noreferrer">Giấy GPP</a>}</td>
                                        <td className="no-wrap">{row.imgDelegateCert && <a href={row.imgDelegateCert} className="block" target="_blank" rel="noopener noreferrer">Giấy uỷ quyền</a>}</td>
                                        <td className="no-wrap">{row.imgIdCert && <a href={row.imgIdCert} className="block" target="_blank" rel="noopener noreferrer">Giấy CMND</a>}</td>
                                        <td className="no-wrap">{row.imgBankCert && <a href={row.imgBankCert} className="block" target="_blank" rel="noopener noreferrer">TK ngân hàng</a>}</td>
                                        <td>
                                            {metadata.userInfo.type === 'manager' ? <>
                                            {row.isPending && <div className="no-wrap"><button className="btn btn-primary" onClick={() => this.doApprove(true, row.storeId)}>Duyệt</button> <button className="btn btn-danger" onClick={() => this.doApprove(false, row.storeId)}>Không Duyệt</button></div>}
                                            </> : row.isPending && <>Chờ duyệt</>}
                                            {row.isApproved && <>Đã duyệt</>}
                                            {row.isDenied && <>Trả lại TDV<br/><span className="replace-note long-text-in-table">Lý do: {row.rejectNote ? row.rejectNote : '-'}</span></>}
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>}

                    {/* REJECT FORM */}
                    {showRejectForm && <div className="mt-3">
                        <FormItem
                            {...rejectNote.props}
                            onChangeHandle={this.onChangeHandle}
                            fields={fields} />
                    </div>}
                    
                </div>
                <div className="sticky-bottom">
                    {!showRejectForm && <div className="group-btn">
                        <button className="btn btn-dark" onClick={() => this.props.history.push('/' + Routes.HomePageRoute.url)}>Home</button>
                    </div>}

                    {showRejectForm && <div className="group-btn">
                        <button className="btn btn-dark" onClick={() => this.doCloseRejectForm()}>Quay lại</button>
                        <button className="btn btn-danger" onClick={() => this.doReject()}>Trả lại TDV</button>
                    </div>}
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ settings }) => ({ settings });
export default withRouter(connect(mapStateToProps)(ManagerRequestStorePage));