import React from 'react';
import { connect } from 'react-redux';
import { PageProps } from 'interfaces';
import { withRouter } from 'react-router-dom';
import * as Routes from 'pages/RouteLoader';
import store from 'reduxs/index';
import { setLoading } from 'reduxs/actions/Settings';
import { DATE_FORMAT, API_EXPORT_EXCEL } from 'constants/Constants';
import { DisplayDateFormat, getTypeName } from 'util/ControlUtils';

interface State {
    type: 'reportStore' | 'requestAds' | 'requestPayment' | 'planStore';
    staffId: string;
    fromDate: string;
    toDate: string;
    data?: {
        allItems: {
            name: string;
            shortname: string;
        }[];
        allAdses: {
            name: string;
            shortname: string;
        }[];
        rows: {
            staffCount: number;
            fullname: string;
            createdDate: string;
            totalStore: string;
            quantitiesItem: number[];
            quantitiesAds: number[];
            totalPrice: string;
            staffId: number;
            isJustSent: boolean;
            countApproved: number;
            countDenied: number;
        }[];
        totalAllStore: string;
        totalAllQuantityItems: number[];
        totalAllQuantityAdses: number[];
        totalAllPrice: string;
    };
}

class ManagerReportPage extends React.Component<PageProps, State> {
    constructor(props: PageProps) {
        super(props);
        this.state = {
            type: props.match.params.type as 'reportStore' | 'requestAds' | 'requestPayment' | 'planStore',
            staffId: props.match.params.staffId,
            fromDate: props.match.params.fromDate,
            toDate: props.match.params.toDate
        }
    }

    componentDidMount = () => {
        const { staffId, fromDate, toDate, type } = this.state;

        store.dispatch(setLoading(true));
        this.props.apiService.getManagerReport(staffId, DisplayDateFormat(fromDate, DATE_FORMAT), DisplayDateFormat(toDate, DATE_FORMAT), type).then(res => {
            this.setState({
                data: res
            });
            store.dispatch(setLoading(false));
        })
    }

    // Custom Code
    doExport = () => {
        const { type, fromDate, toDate, staffId } = this.state;
        document.location.href = `${API_EXPORT_EXCEL}?type=${type}&staffId=${staffId}&fromDate=${fromDate}&toDate=${toDate}&token=${localStorage.token}&page=0&limit=10000`;
    }

    goToDetail = (row) => {
        const { type, fromDate, toDate, staffId } = this.state;
        this.props.history.push(`/${Routes.ManagerReportDetailPageRoute.url.replace(':type', type).replace(':staffId', row.staffId.toString()).replace(':date', row.createdDate.split('/').reverse().join('-'))}`, {staffId: staffId, fromDate: fromDate, toDate: toDate})
    }

    // Custom Code
    render() {
        const { settings: { metadata } } = this.props;
        const { type, fromDate, toDate, staffId, data } = this.state;
        return (
            <div className="manager-report-page">
                <div className="sticky-top">
                    <div className="row pt-2">
                        <div className="col-6">{getTypeName(metadata.userInfo)}: {metadata.userInfo.name}</div>
                        <div className="col-6 text-right">Nhóm: {metadata.userInfo.groupName}</div>
                    </div>
                    {type === 'reportStore' && <h3 className="head-title mt-0 mb-0 pt-3">BÁO CÁO HOẠT ĐỘNG NHÀ THUỐC</h3>}
                    {type === 'planStore' && <h3 className="head-title mt-0 mb-0 pt-3">KẾ HOẠCH HOẠT ĐỘNG NHÀ THUỐC</h3>}
                    {type === 'requestAds' && <h3 className="head-title mt-0 mb-0 pt-3">YÊU CẦU THI CÔNG</h3>}
                    {type === 'requestPayment' && <h3 className="head-title mt-0 mb-0 pt-3">YÊU CẦU TRẢ PHÍ QUẢNG CÁO</h3>}
                    <p className="text-center">Từ ngày: <b>{DisplayDateFormat(fromDate, DATE_FORMAT)}</b> đến ngày <b>{DisplayDateFormat(toDate, DATE_FORMAT)}</b></p>
                </div>

                <div className="holder">
                    <div className="report-table mt-3">
                        {data && <table>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    {(type === 'reportStore' || type === 'planStore') && <th></th>}
                                    {data.allItems.length > 0 && <th colSpan={data.allItems.length} className="text-center">
                                        <span>Đặt hàng</span>
                                    </th>}
                                    {data.allAdses.length > 0 && <th colSpan={data.allAdses.length} className="text-center">
                                        <span>Loại vật dụng</span>
                                    </th>}
                                    <th></th>
                                </tr>
                                <tr>
                                    <th className="text-center">TDV</th>
                                    <th className="text-center">Ngày</th>
                                    <th className="text-center">
                                        {type === 'reportStore' && <span className="item-name">Số NT viếng thăm</span>}
                                        {type === 'planStore' && <span className="item-name">Số NT</span>}
                                        {type === 'requestAds' && <span className="item-name">Số NT YCTC</span>}
                                        {type === 'requestPayment' && <span className="item-name">Số NT YCTP</span>}
                                    </th>
                                    {(type === 'reportStore' || type === 'planStore') && <th className="text-center"><span className="item-name">Thành tiền (VND)</span></th>}
                                    {data.allItems.map((e, key) =>
                                        <th key={key}><span className="item-name">{e.shortname}</span></th>
                                    )}
                                    {data.allAdses.map((e, key) =>
                                        <th key={key}><span className="item-name">{e.shortname}</span></th>
                                    )}
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.rows.map((row, key) =>
                                    <tr key={key}>
                                        {row.staffCount > 0 && <td className="no-wrap" rowSpan={row.staffCount}>{row.fullname}</td>}
                                        <td>{row.createdDate}</td>
                                        <td className="text-center">{row.totalStore}</td>
                                        {(type === 'reportStore' || type === 'planStore') && <td className="text-right">{row.totalPrice}</td>}
                                        {row.quantitiesItem.map((quantity, quantityKey) =>
                                            <td key={quantityKey} className="text-center">{quantity ? quantity : ''}</td>
                                        )}
                                        {row.quantitiesAds.map((quantity, quantityKey) =>
                                            <td key={quantityKey} className="text-center">{quantity ? quantity : ''}</td>
                                        )}
                                        <td>
                                            {(type === 'reportStore' || type === 'planStore') && <a href="javascript:;" className="no-wrap" onClick={() => this.goToDetail(row)}>Xem chi tiết</a>}
                                            {(type === 'requestAds' || type === 'requestPayment') && <>
                                                {row.isJustSent && <a href="javascript:;" className="no-wrap" onClick={() => this.goToDetail(row)}>Xem & duyệt</a>}
                                                {!row.isJustSent && <a href="javascript:;" className="no-wrap" onClick={() => this.goToDetail(row)}>Xem chi tiết 
                                                    {!!(row.countApproved && row.countDenied) && <span> (Đã duyệt [{row.countApproved}] - Trả lại TDV [{row.countDenied}])</span>}
                                                    {!!(row.countApproved && !row.countDenied) && <span> (Đã duyệt)</span>}
                                                    {!!(!row.countApproved && row.countDenied) && <span> (Trả lại TDV)</span>}
                                                </a>}
                                            </>}
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td colSpan={2}><b>TỔNG CỘNG</b></td>
                                    <td className="text-center"><b>{data.totalAllStore}</b></td>
                                    {(type === 'reportStore' || type === 'planStore') && <td className="text-right"><b>{data.totalAllPrice}</b></td>}
                                    {Object.keys(data.totalAllQuantityItems).map((k, key) =>
                                        <td key={key} className="text-center"><b>{data.totalAllQuantityItems[k] ? data.totalAllQuantityItems[k] : ''}</b></td>
                                    )}
                                    {Object.keys(data.totalAllQuantityAdses).map((k, key) =>
                                        <td key={key} className="text-center"><b>{data.totalAllQuantityAdses[k] ? data.totalAllQuantityAdses[k] : ''}</b></td>
                                    )}
                                    <td></td>
                                </tr>
                            </tfoot>
                        </table>}
                    </div>
                </div>
                <div className="sticky-bottom">
                    <div className="group-btn">
                        <button className="btn btn-dark" onClick={() => this.props.history.push('/' + Routes.HomePageRoute.url)}>Home</button>
                        <button className="btn btn-dark" onClick={() => this.props.history.push('/' + Routes.ManagerReportSearchPageRoute.url.replace(':type', type), { fromDate: fromDate, toDate: toDate, staffId: staffId })}>Quay lại</button>
                        <button className="btn btn-primary" onClick={() => this.doExport()}>Xuất Excel</button>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ settings }) => ({ settings });
export default withRouter(connect(mapStateToProps)(ManagerReportPage));