import React from 'react';
import { TrackDate as TrackDateModel } from 'models/Store';
const TrackDate = ({type, trackDate}: {type: 'reportStore' | 'requestAds' | 'requestPayment' | 'requestStore' | 'planStore'; trackDate: TrackDateModel}) => {
    return <div className="col-12">
        {type === 'requestStore' && <div className="item-ads-table mt-3">
            <table>
                <tbody>
                    <tr>
                        <td>TDV gửi yêu cầu</td>
                        <td>{trackDate.createdDate}</td>
                    </tr>
                    <tr>
                        <td>Manager đã review</td>
                        <td>{trackDate.approveDate}</td>
                    </tr>
                </tbody>
            </table>
        </div>}
        {type === 'planStore' && <div className="item-ads-table mt-3">
            <table>
                <tbody>
                    <tr>
                        <td>Kế hoạch ngày</td>
                        <td>{trackDate.createdDate}</td>
                    </tr>
                </tbody>
            </table>
        </div>}
        {type === 'reportStore' && <div className="item-ads-table mt-3">
            <table>
                <tbody>
                    <tr>
                        <td>TDV gửi báo cáo</td>
                        <td>{trackDate.createdDate}</td>
                    </tr>
                    <tr>
                        <td>Manager đã xem</td>
                        <td>{trackDate.seenDate}</td>
                    </tr>
                </tbody>
            </table>
        </div>}
        {type === 'requestAds' && <div className="item-ads-table mt-3">
            <table>
                <tbody>
                    <tr>
                        <td>TDV gửi yêu cầu</td>
                        <td>{trackDate.createdDate}</td>
                    </tr>
                    <tr>
                        <td>Manager đã duyệt</td>
                        <td>{trackDate.approve1Date}</td>
                    </tr>
                    <tr>
                        <td>Admin đã duyệt</td>
                        <td>{trackDate.approve2Date}</td>
                    </tr>
                    <tr>
                        <td>Tình trạng hiện tại</td>
                        <td>{trackDate.progress}</td>
                    </tr>
                    <tr>
                        <td>Admin đã nghiệm thu</td>
                        <td>
                            {trackDate.doneDate}
                            {trackDate.doneImage && <a href={trackDate.doneImage} target="_blank" rel="noopener noreferrer"> (Hình nghiệm thu)</a>}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>}
        {type === 'requestPayment' && <div className="item-ads-table mt-3">
            <table>
                <tbody>
                    <tr>
                        <td>TDV gửi yêu cầu</td>
                        <td>{trackDate.createdDate}</td>
                    </tr>
                    <tr>
                        <td>Manager đã duyệt</td>
                        <td>{trackDate.approve1Date}</td>
                    </tr>
                    <tr>
                        <td>Admin đã duyệt</td>
                        <td>{trackDate.approve2Date}</td>
                    </tr>
                    <tr>
                        <td>Admin đã nghiệm thu</td>
                        <td>
                            {trackDate.doneDate}
                            {trackDate.doneImage && <a href={trackDate.doneImage} target="_blank" rel="noopener noreferrer"> (Hình nghiệm thu)</a>}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>}
    </div>
}

export default TrackDate;