import React from 'react';
import GoogleMapReact from 'google-map-react';
import { GOOGLE_MAP_API_KEY } from 'constants/Constants';

interface Props {
    coords: {
        lat: number;
        long: number;
    };
}

const GoogleMap: React.SFC<Props> = ({coords}) => {
    return (
        <GoogleMapReact
            bootstrapURLKeys={{ key: GOOGLE_MAP_API_KEY }}
            center={{
                lat: coords.lat,
                lng: coords.long
            }}
            zoom={16}
        >
            <GoogleMarker lat={coords.lat} lng={coords.long}></GoogleMarker>
        </GoogleMapReact>
    );
};

const GoogleMarker = ({lat, lng}) => {
    return <div className="pin" key={`${lat}-${lng}`}></div>
}

export default GoogleMap;
