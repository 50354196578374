import React from 'react';
import { connect } from 'react-redux';
import { PageProps } from 'interfaces';
import { Field, City, District } from 'models/Settings';
import { withRouter } from 'react-router-dom';
import FormItem, { setStateByProperty, checkValidations, getIdOrUndefined } from 'components/Form';
import Swal from 'sweetalert2';
import * as Routes from 'pages/RouteLoader';
import { getFields, getFormFieldsOther, getFormFieldsSearchStore } from './fields';
import * as Constants from 'constants/Constants';
import Store from 'models/Store';
import UploadImgGroup from 'components/UploadImgGroup';
import _ from 'lodash';

interface State {
    disabledSent: boolean;
    disabledDraft: boolean;
    fields: Field[];

    // Custom Code
    formData: {
        store: Store;
        city: City;
        district: District;
        wardName: string;
        street: string;
        streetNumber: string;
        name: string;
        ownerName: string;
        pharmacistName: string;
        phone: string;
        paymentAccountName: string;
        paymentIdentityCard: string;
        paymentAccountNumber: string;
        paymentBankName: string;
        replaceNote: string;
        isReplace: boolean;

        imgBusinessCert: (File | null)[];
        imgGppCert: (File | null)[];
        imgBankCert: (File | null)[];
        imgIdCert: (File | null)[];
        imgDelegateCert: (File | null)[];
        
        imgBusinessCertPreview: (string | ArrayBuffer | null)[];
        imgGppCertPreview: (string | ArrayBuffer | null)[];
        imgBankCertPreview: (string | ArrayBuffer | null)[];
        imgIdCertPreview: (string | ArrayBuffer | null)[];
        imgDelegateCertPreview: (string | ArrayBuffer | null)[];
    };

}

class RequestStorePage extends React.Component<PageProps, State> {
    constructor(props: PageProps) {
        super(props);

        let fields = getFields();
        fields = fields.map(e => ({
            ...new Field(), ...e,
            isValid: false,
            isValidType: 'required'
        }));

        this.state = {
            disabledSent: true,
            disabledDraft: true,
            fields: fields,

            // Custom Code
            formData: {
                store: new Store(),
                city: new City(),
                district: new District(),
                wardName: '',
                street: '',
                streetNumber: '',
                name: '',
                ownerName: '',
                pharmacistName: '',
                phone: '',
                paymentAccountName: '',
                paymentIdentityCard: '',
                paymentAccountNumber: '',
                paymentBankName: '',
                replaceNote: '',
                isReplace: false,

                imgBusinessCert: [],
                imgGppCert: [],
                imgBankCert: [],
                imgIdCert: [],
                imgDelegateCert: [],

                imgBusinessCertPreview: [],
                imgGppCertPreview: [],
                imgBankCertPreview: [],
                imgIdCertPreview: [],
                imgDelegateCertPreview: [],
            },
        }
    }

    componentDidMount = () => {
        if (localStorage.requestStoreData) {
            try {
                const requestStoreData = JSON.parse(localStorage.requestStoreData);
                let fields = getFields();
                fields = fields.map(e => ({
                    ...new Field(), ...e,
                    isValid: true,
                    isValidType: 'none'
                }));
                this.setState({formData: requestStoreData, disabledSent: false, fields: fields});
            } catch (error) {}
        }
    }

    resetForm = () => {
        let fields = getFields();
        fields = fields.map(e => ({
            ...new Field(), ...e,
            isValid: false,
            isValidType: 'required'
        }));

        this.setState(prevState => ({
            disabledSent: true,
            disabledDraft: true,
            fields: fields,

            // Custom Code
            formData: {
                ...prevState.formData,
                store: new Store(),
                city: new City(),
                district: new District(),
                wardName: '',
                street: '',
                streetNumber: '',
                name: '',
                ownerName: '',
                pharmacistName: '',
                phone: '',
                paymentAccountName: '',
                paymentIdentityCard: '',
                paymentAccountNumber: '',
                paymentBankName: '',
                replaceNote: ''
            },
        }));
    }

    resetSearchForm = () => {
        this.setState(prevState => ({formData: {...prevState.formData, store: new Store()}}));
    }

    // Custom Code
    onChangeHandle = (nameHtml, valueHtml) => {
        const { settings: { stores } } = this.props;
        const { formData } = this.state;

        switch (nameHtml) {
            case 'searchStore[name]':
                setStateByProperty(this, 'store', nameHtml, { id: valueHtml, name: '' });
                setStateByProperty(this, 'city', 'searchStore[city]', { id: '', name: '' });
                setStateByProperty(this, 'district', 'searchStore[district]', { id: '', name: '' });
                setStateByProperty(this, 'name', 'store[name]', '');
                setStateByProperty(this, 'wardName', 'store[wardName]', '');
                setStateByProperty(this, 'street', 'store[street]', '');
                setStateByProperty(this, 'streetNumber', 'store[streetNumber]', '');
                break;
            case 'searchStore[city]':
                setStateByProperty(this, 'city', nameHtml, { id: valueHtml, name: '' });
                setStateByProperty(this, 'district', 'searchStore[district]', { id: '', name: '' });
                setStateByProperty(this, 'wardName', 'searchStore[wardName]', '');
                setStateByProperty(this, 'street', 'searchStore[street]', '');
                setStateByProperty(this, 'streetNumber', 'searchStore[streetNumber]', '');
                this.resetSearchForm();
                break;
            case 'searchStore[district]':
                setStateByProperty(this, 'district', nameHtml, { id: valueHtml, name: '' });
                setStateByProperty(this, 'wardName', 'searchStore[wardName]', '');
                setStateByProperty(this, 'street', 'searchStore[street]', '');
                setStateByProperty(this, 'streetNumber', 'searchStore[streetNumber]', '');
                this.resetSearchForm();
                break;
            case 'searchStore[wardName]':
                setStateByProperty(this, 'wardName', nameHtml, valueHtml);
                setStateByProperty(this, 'street', 'searchStore[street]', '');
                setStateByProperty(this, 'streetNumber', 'searchStore[streetNumber]', '');
                this.resetSearchForm();
                break;
            case 'searchStore[street]':
                setStateByProperty(this, 'street', nameHtml, valueHtml);
                setStateByProperty(this, 'streetNumber', 'searchStore[streetNumber]', '');
                this.resetSearchForm();
                break;
            case 'searchStore[streetNumber]':
                const existStoreStreetNumber = stores.find(e => e.streetNumber === valueHtml);
                if (existStoreStreetNumber) {
                    setStateByProperty(this, 'store', nameHtml, { id: existStoreStreetNumber.id, name: '' });
                } else {
                    setStateByProperty(this, 'store', nameHtml, { id: '', name: '' });
                }
                setStateByProperty(this, 'city', 'searchStore[city]', { id: '', name: '' });
                setStateByProperty(this, 'district', 'searchStore[district]', { id: '', name: '' });
                setStateByProperty(this, 'name', 'store[name]', '');
                setStateByProperty(this, 'wardName', 'store[wardName]', '');
                setStateByProperty(this, 'street', 'store[street]', '');
                setStateByProperty(this, 'streetNumber', 'store[streetNumber]', '');
                break;
            case 'store[name]':
                setStateByProperty(this, 'name', nameHtml, valueHtml);
                break;
            case 'store[ownerName]':
                setStateByProperty(this, 'ownerName', nameHtml, valueHtml);
                break;
            case 'store[pharmacistName]':
                setStateByProperty(this, 'pharmacistName', nameHtml, valueHtml);
                break;
            case 'store[phone]':
                setStateByProperty(this, 'phone', nameHtml, valueHtml);
                break;
            case 'store[streetNumber]':
                setStateByProperty(this, 'streetNumber', nameHtml, valueHtml);
                break;
            case 'store[street]':
                setStateByProperty(this, 'street', nameHtml, valueHtml);
                break;
            case 'store[wardName]':
                setStateByProperty(this, 'wardName', nameHtml, valueHtml);
                break;
            case 'store[city]':
                setStateByProperty(this, 'city', nameHtml, { id: valueHtml, name: '' });
                setStateByProperty(this, 'district', 'store[district]', { id: '', name: '' });
                break;
            case 'store[district]':
                setStateByProperty(this, 'district', nameHtml, { id: valueHtml, name: '' });
                break;
            case 'store[paymentAccountName]':
                setStateByProperty(this, 'paymentAccountName', nameHtml, valueHtml);
                break;
            case 'store[paymentIdentityCard]':
                setStateByProperty(this, 'paymentIdentityCard', nameHtml, valueHtml);
                break;
            case 'store[paymentAccountNumber]':
                setStateByProperty(this, 'paymentAccountNumber', nameHtml, valueHtml);
                break;
            case 'store[paymentBankName]':
                setStateByProperty(this, 'paymentBankName', nameHtml, valueHtml);
                break;
            case 'store[replaceNote]':
                setStateByProperty(this, 'replaceNote', nameHtml, valueHtml);
                break;
            case 'store[isReplace]':
                setStateByProperty(this, 'isReplace', nameHtml, !formData.isReplace);
                this.resetForm();
                break;
            default:
                break;
        }
        this.setState({ disabledSent: false, disabledDraft: false });
    }

    fileChangedHandler = () => {
        this.setState({
            disabledDraft: false,
            disabledSent: false
        });
    }

    checkValidCapturePicture = (type: 'draft' | 'sent') => {
        const { formData: { imgBusinessCertPreview, imgGppCertPreview, isReplace, replaceNote } } = this.state;
        if (type === 'sent') {
            if (isReplace) {
                if (!replaceNote) {
                    return -2;
                } else if (!_.isEmpty(imgBusinessCertPreview.filter(e => e)) && !_.isEmpty(imgGppCertPreview.filter(e => e))) {
                    return 1;
                } else {
                    return -1;
                }
            } else {
                if (!_.isEmpty(imgBusinessCertPreview.filter(e => e)) && !_.isEmpty(imgGppCertPreview.filter(e => e))) {
                    return 1;
                } else {
                    return -1;
                }
            }
        } else {
            return 1;
        }
    }

    // Custom Code
    onSubmit = (type: 'draft' | 'sent') => {
        const isValid = checkValidations(this);
        const { formData } = this.state;

        if (isValid && this.checkValidCapturePicture(type) > 0) {
            if (type === 'sent') {
                Swal.fire({
                    html: 'Vui lòng chờ ...<br/><div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>',
                    showConfirmButton: false,
                    allowOutsideClick: false
                })
                this.props.apiService.postRequestStore(formData).then(() => {
                    Swal.fire({
                        ...Constants.SWAL_COMMON_STYLE,
                        text: 'Gửi yêu cầu thành công.',
                        type: 'success',
                        confirmButtonText: 'Đóng'
                    }).then(() => {
                        localStorage.requestStoreData = '';
                        this.props.history.push('/' + Routes.HomePageRoute.url);
                        this.props.history.push('/' + Routes.RequestStorePageRoute.url);
                    });
                });
            } else {
                localStorage.requestStoreData = JSON.stringify(formData);
                this.setState({disabledDraft: true});
            }
        } else if (this.checkValidCapturePicture(type) == -1) {
            Swal.fire({
                ...Constants.SWAL_COMMON_STYLE,
                text: 'Bạn cần chụp hình giấy đăng ký kinh doanh & GPP trước khi gửi yêu cầu.',
                type: 'warning',
                confirmButtonText: 'Đóng'
            })
        } else if (this.checkValidCapturePicture(type) == -2) {
            Swal.fire({
                ...Constants.SWAL_COMMON_STYLE,
                text: 'Bạn cần nhập lý do thay thế nhà thuốc trước khi gửi yêu cầu.',
                type: 'warning',
                confirmButtonText: 'Đóng'
            })
        }
    }

    // Custom Code
    render() {
        const { fields, formData, disabledSent, disabledDraft } = this.state;
        const { imgBusinessCertPreview, imgGppCertPreview, imgBankCertPreview, imgIdCertPreview, imgDelegateCertPreview } = formData;
        const { settings: { metadata, stores } } = this.props;
        const formFieldsOther = getFormFieldsOther(formData, metadata);
        const formFieldsSearchStore = getFormFieldsSearchStore(formData, metadata, stores);

        const isReplace = {
            props: {
                type: 'checkbox',
                className: '',
                data: formData.isReplace ? 1 : 0,
                attribute: {
                    checked: formData.isReplace
                },
                htmlFor: 'store[isReplace]',
                fieldName: 'store[isReplace]',
                fieldLabel: 'Thay thế NT hiện có'
            },
        };

        return (
            <div className="request-store-page">
                <div className="sticky-top">
                    <div className="row pt-2">
                        <div className="col-6">TDV: {metadata.userInfo.name}</div>
                        <div className="col-6 text-right">Nhóm: {metadata.userInfo.groupName}</div>
                    </div>
                    <h3 className="head-title mt-0 mb-0 pt-3">
                        YÊU CẦU TẠO NHÀ THUỐC MỚI
                    </h3>
                </div>
                <div className="holder">
                    <div className="item-ads row">
                        <div className="mt-3 col-12">
                            <div className="item-ads-table mt-3">
                                <table>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <FormItem
                                                    {...isReplace.props}
                                                    onChangeHandle={this.onChangeHandle}
                                                    fields={fields}
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        {formData.isReplace && <div className="mt-3 col-12">
                            <div className="row">
                                {formFieldsSearchStore.map((field, key) =>
                                    <div key={`${getIdOrUndefined(formData.city)}-${getIdOrUndefined(formData.district)}-${formData.wardName}-${formData.street}-${formData.streetNumber}-${key}`} className="mt-1 col-6 col-lg-2">
                                        <FormItem
                                            {...field.props}
                                            onChangeHandle={this.onChangeHandle}
                                            fields={fields}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>}
                        <div className="mt-3 col-12 col-sm-4">
                            {formFieldsOther.map((field, key) =>
                                <React.Fragment key={`${getIdOrUndefined(formData.city)}-${getIdOrUndefined(formData.district)}-${formData.wardName}-${formData.street}-${key}`}>
                                    <FormItem
                                        {...field.props}
                                        onChangeHandle={this.onChangeHandle}
                                        fields={fields}
                                    />
                                </React.Fragment>
                            )}
                        </div>
                        <div className="mt-3 col-12 col-sm-4">
                            <div className="item-ads-table mt-3">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>CHỤP HÌNH GIẤY ĐĂNG KÝ KINH DOANH *</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <UploadImgGroup 
                                                    disabled={false} 
                                                    files={formData.imgBusinessCert} 
                                                    previews={imgBusinessCertPreview}
                                                    onFileChanged={this.fileChangedHandler}
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="item-ads-table mt-3">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>CHỤP HÌNH GIẤY GPP *</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <UploadImgGroup 
                                                    disabled={false} 
                                                    files={formData.imgGppCert} 
                                                    previews={imgGppCertPreview}
                                                    onFileChanged={this.fileChangedHandler}
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="mt-3 col-12 col-sm-4">
                            <div className="item-ads-table mt-3">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>CHỤP HÌNH GIẤY UỶ QUYỀN CHO NGƯỜI HƯỞNG PHÍ QUẢNG CÁO</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <UploadImgGroup 
                                                    disabled={false} 
                                                    files={formData.imgDelegateCert} 
                                                    previews={imgDelegateCertPreview}
                                                    onFileChanged={this.fileChangedHandler}
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="item-ads-table mt-3">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>CHỤP HÌNH CMND NGƯỜI HƯỞNG PHÍ QUẢNG CÁO</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <UploadImgGroup 
                                                    disabled={false} 
                                                    files={formData.imgIdCert} 
                                                    previews={imgIdCertPreview}
                                                    onFileChanged={this.fileChangedHandler}
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="item-ads-table mt-3">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>CHỤP HÌNH GIẤY XÁC NHẬN TÀI KHOẢN NGÂN HÀNG</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <UploadImgGroup 
                                                    disabled={false} 
                                                    files={formData.imgBankCert} 
                                                    previews={imgBankCertPreview}
                                                    onFileChanged={this.fileChangedHandler}
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="sticky-bottom">
                    <div className="group-btn">
                        <button className="btn btn-dark" onClick={() => this.props.history.push('/' + Routes.HomePageRoute.url)}>Home</button>
                        <button disabled={disabledDraft} className="btn btn-dark" onClick={() => this.onSubmit('draft')}>Lưu tạm</button>
                        <button disabled={disabledSent} className="btn btn-primary" onClick={() => this.onSubmit('sent')}>Tạo yêu cầu</button>
                    </div>
                </div>

            </div>
        );
    }
}

const mapStateToProps = ({ settings }) => ({ settings });
export default withRouter(connect(mapStateToProps)(RequestStorePage));