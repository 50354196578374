import { Field } from "models/Settings";
import * as API from 'constants/Constants';
import { Route } from 'models/Store';
import { getSearchStoreFields } from 'pages/RequestStorePage/fields';

export const getFields = () => {
    const fields: Field[] = [
        ...getSearchStoreFields(),
        {
            key: "route.routeName",
            isRequired: true,
            isVisible: true,
            regex: "",
            maxLength: 50,
            name: "Route Name"
        },
    ];

    return fields;
}

export const buildOptionsName = (objects: any) => {
    if (objects && objects.length) {
        const options = objects.map((e: Route) => ({value: e.name, label: e.name}));
        return options.filter((item, index) => options.findIndex(e => e.value === item.value)  === index);
    }
    return [];
}

export const buildFilterObjectName = (keyword: string) => {
    return `&keyword=${keyword}`;
}

export const getFormFieldsRoute = (formData) => {

    const fields = [
        {
            props: {
                type: 'tag',
                htmlFor: 'route[routeName]',
                fieldName: 'route[routeName]',
                className: 'select-custom mb-2',
                data: formData.routeName ? formData.routeName : undefined,
                fieldLabel: 'Tên tuyến',
                advanceOptions: {
                    apiEndpoint: API.API_SEARCH_ROUTES + `?routeName=${formData.routeName}`,
                    buildOptions: buildOptionsName,
                    buildFilterObject: buildFilterObjectName,
                    pageSize: 300
                }
            },
        },
    ];

    return fields;
}