import React from 'react';
import {injectIntl} from 'react-intl';

interface Props {
    intl;
}

class CurrentLocale extends React.Component<Props, {}> {
    static instance: CurrentLocale;

    componentWillMount() {
        if (!CurrentLocale.instance)
            CurrentLocale.instance = this;
    }

    render() {
        return false;
    }
}

export default injectIntl(CurrentLocale);

export function intl() {
    return CurrentLocale.instance && CurrentLocale.instance.props.intl;
}

export function formatMessage(...args) {
    return intl() && intl().formatMessage(...args);
}