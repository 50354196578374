import React, { useEffect, useState } from 'react';
import FormItem, { getIdOrUndefined } from 'components/Form';
import * as Routes from 'pages/RouteLoader';
import Camera, { FACING_MODES } from 'react-html5-camera-photo';
import ViewImgGroup from 'components/ViewImgGroup';
import 'react-html5-camera-photo/build/css/index.css';
import { TargetActualIncome } from 'models/Store';
import { API_EXPORT_REQUEST_PAYMENT_ADS_PRICE } from 'constants/Constants';

const Step1 = ({
    formFieldsSearchStore,
    formFieldsAdses,
    formPeriod,
    formData,
    fields,
    adsImageInsidePreview,
    adsImageOutsidePreview,
    targetActualIncome,
    disabledDraft,
    disabledSent,
    onChangeHandle,
    fileChangedHandler,
    onSubmit,
    metadata,
    params,
    history
}) => {
    const [insideCamera, setInsideCamera] = useState(false);
    const [outsideCamera, setOutsideCamera] = useState(false);

    const switchToFullscreen = (isFullscreen) => {
        const portal: HTMLElement | null = document.getElementById('app-site');
        if (portal) {
            portal.setAttribute('class', isFullscreen ? 'fullscreen' : '');
        }
    }

    const capturePicture = (e, type: 'adsImageOutside' | 'adsImageInside') => {
        switchToFullscreen(false);
        let previewsReturn: string[] = [];
        switch (type) {
            case 'adsImageOutside':
                if (adsImageOutsidePreview) {
                    previewsReturn = adsImageOutsidePreview;
                }
                previewsReturn.push(e);
                setOutsideCamera(false);
                break;
            case 'adsImageInside':
                if (adsImageInsidePreview) {
                    previewsReturn = adsImageInsidePreview;
                }
                previewsReturn.push(e);
                setInsideCamera(false);
                break;
        }

        fileChangedHandler(previewsReturn, type);
    }

    const fileChange = (result: (string | ArrayBuffer | null)[], type: 'adsImageOutside' | 'adsImageInside') => {
        fileChangedHandler(result, type);
    }

    useEffect(() => {
        if (getIdOrUndefined(formData.store) <= 0) {
            setInsideCamera(false);
            setOutsideCamera(false);
        }
    }, [formData]);

    const targetActualIncomeObj: TargetActualIncome = targetActualIncome;
    const period = targetActualIncomeObj.period ? ` ${targetActualIncomeObj.period}` : '';

    return <React.Fragment>
        {outsideCamera && <Camera isImageMirror={false} isMaxResolution={false} isFullscreen={true} idealFacingMode={FACING_MODES.ENVIRONMENT} onTakePhoto = { (e) => capturePicture(e, 'adsImageOutside') } />}
        {insideCamera && <Camera isImageMirror={false} isMaxResolution={false} isFullscreen={true} idealFacingMode={FACING_MODES.ENVIRONMENT} onTakePhoto = { (e) => capturePicture(e, 'adsImageInside') } />}
        <div className="sticky-top">
            <div className="row pt-2">
                <div className="col-6">TDV: {metadata.userInfo.name}</div>
                <div className="col-6 text-right">Nhóm: {metadata.userInfo.groupName}</div>
            </div>
            <h3 className="head-title mt-0 mb-0 pt-3">YÊU CẦU TRẢ PHÍ QUẢNG CÁO</h3>
            <div className="action row">
                {formFieldsSearchStore.map((field, key) =>
                    <div key={`${getIdOrUndefined(formData.city)}-${getIdOrUndefined(formData.district)}-${formData.wardName}-${formData.street}-${formData.streetNumber}-${key}`} className="mt-1 col-6 col-lg-2">
                        <FormItem
                            {...field.props}
                            attribute={{disabled: !!(params.storeId)}}
                            onChangeHandle={onChangeHandle}
                            fields={fields}
                        />
                    </div>
                )}
            </div>
        </div>
        <div className="holder">
            <div className="item-ads row">
                <div key={`${getIdOrUndefined(formData.period)}`} className="col-12 col-sm-2">
                    <FormItem
                        {...formPeriod.props}
                        onChangeHandle={onChangeHandle}
                        fields={fields}
                    />
                </div>
                <div className="col-12 col-sm-10">
                    Mục tiêu doanh số Quý{period}: <b>{targetActualIncomeObj.targetKey}</b><br/>
                    Kết quả trong Quý{period}: <b>{targetActualIncomeObj.actual}</b><br/>
                    Tỉ lệ đạt: <b>{targetActualIncomeObj.percent}</b>
                </div>
                <div className="col-12 col-sm-8">
                    <div className="item-ads-table mt-3">
                        <table>
                            <thead>
                                <tr>
                                    <th>VẬT DỤNG</th>
                                    <th className="text-center">SỐ LƯỢNG</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {formFieldsAdses.map((field, key) =>
                                    <tr key={key}>
                                        <td>{field.name}</td>
                                        <td>
                                            <FormItem
                                                {...field.props}
                                                onChangeHandle={onChangeHandle}
                                                fields={fields}
                                            /></td>
                                        <td>{field.unitName}</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
                <form id="file" onSubmit={(e) => e.preventDefault()} className="col-12 col-sm-4">
                    <div className="item-ads-table mt-3">
                        <table>
                            <thead>
                                <tr>
                                    <th>CHỤP HÌNH BÊN NGOÀI *</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        {adsImageOutsidePreview.length < 3 && <button type="button" disabled={getIdOrUndefined(formData.store) <= 0} className="btn btn-white btn-block mt-1 mb-1" onClick={() => {setOutsideCamera(true); setInsideCamera(false);switchToFullscreen(true); }}>Chụp hình</button>}
                                        <ViewImgGroup images={adsImageOutsidePreview} showDeleteButton={true} onFileChanged={(result) => fileChange(result, 'adsImageOutside')} />
                                        <p>* Hình chụp toàn cảnh <b>bên ngoài</b> nhà thuốc, thấy rõ vị trí đang đặt vật dụng quảng cáo.<br/>
                                        * Chức năng chụp hình chỉ hoạt động trên <b>Safari</b> đối với <b>iOS</b> và <b>Chrome</b> đối với <b>Android</b>.</p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="item-ads-table mt-3">
                        <table>
                            <thead>
                                <tr>
                                    <th>CHỤP HÌNH BÊN TRONG *</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        {adsImageInsidePreview.length < 3 && <button type="button" disabled={getIdOrUndefined(formData.store) <= 0} className="btn btn-white btn-block mt-1 mb-1" onClick={() => {setInsideCamera(true); setOutsideCamera(false);switchToFullscreen(true); }}>Chụp hình</button>}
                                        <ViewImgGroup images={adsImageInsidePreview} showDeleteButton={true} onFileChanged={(result) => fileChange(result, 'adsImageInside')} />
                                        <p>* Hình chụp toàn cảnh <b>bên trong</b> nhà thuốc, thấy rõ vị trí đang đặt vật dụng quảng cáo.<br/>
                                        * Chức năng chụp hình chỉ hoạt động trên <b>Safari</b> đối với <b>iOS</b> và <b>Chrome</b> đối với <b>Android</b>.</p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </form>
            </div>
        </div>
        <div className="sticky-bottom">
            <div className="group-btn nowrap">
                <button className="btn btn-dark" onClick={() => history.push('/' + Routes.HomePageRoute.url)}>Home</button>
                <button disabled={disabledSent} className="btn btn-primary" onClick={() => onSubmit('draft', 2)}>Tạo yêu cầu</button>
                {!disabledSent && <>
                    <a className="btn btn-dark" href={API_EXPORT_REQUEST_PAYMENT_ADS_PRICE.replace('{0}', getIdOrUndefined(formData.store)).replace('{1}', getIdOrUndefined(formData.period)).replace('{2}', localStorage.token).replace('{3}', 'price')} target='_blank' rel="noopener noreferrer">BXN thưc tế hợp tác quảng cáo</a>
                    <a className="btn btn-dark" href={API_EXPORT_REQUEST_PAYMENT_ADS_PRICE.replace('{0}', getIdOrUndefined(formData.store)).replace('{1}', getIdOrUndefined(formData.period)).replace('{2}', localStorage.token).replace('{3}', 'price_bonus')} target='_blank' rel="noopener noreferrer">BXN thực tế đạt mục tiêu doanh số</a>
                </>}
            </div>
        </div>
    </React.Fragment>
}

export default Step1;