import { Field } from "models/Settings";

export const getFields = () => {
    const fields: Field[] =  [
        {
            key: "login.username",
            isRequired: true,
            isVisible: true,
            regex: "",
            maxLength: 50,
            name: "ID"
        },
        {
            key: "login.password",
            isRequired: true,
            isVisible: true,
            regex: "",
            maxLength: 50,
            name: "Password"
        },
    ];

    return fields;
}


export const getFormFields = (formData) => {
    const formFields = [
        {
            props: {
                type: 'text',
                htmlFor: 'login[username]',
                fieldName: 'login[username]',
                data: formData.username,
                fieldLabel: 'ID'
            },
        },
        {
            props: {
                type: 'password',
                htmlFor: 'login[password]',
                fieldName: 'login[password]',
                data: formData.password,
                fieldLabel: 'Password'
            },
        },
    ];

    return formFields;
}