import React from 'react';
import { connect } from 'react-redux';
import { PageProps } from 'interfaces';
import { Metadata } from 'models/Settings';
import { withRouter } from 'react-router-dom';
import * as Route from 'pages/RouteLoader';
import logo from 'assets/images/hisamitsu_w.png';
import { getTypeName } from 'util/ControlUtils';

class RouteHomePage extends React.Component<PageProps, {}> {
    constructor(props: PageProps) {
        super(props);

        this.state = {
            metadata: new Metadata()
        }
    }

    logout = () => {
        localStorage.token = '';
        localStorage.cityId = '';
        localStorage.districtId = '';

        this.props.history.push('/');
    }

    // Custom Code
    render() {
        const { settings: { metadata, role } } = this.props;

        return (
            <div className="home-page">
                <div className="sticky-top">
                    <div className="row pt-2">
                        <div className="col-6">{role === 'staff' ? 'TDV' : getTypeName(metadata.userInfo)}: {metadata.userInfo.name}</div>
                        <div className="col-6 text-right">Nhóm: {metadata.userInfo.groupName}</div>
                    </div>
                    <h1 className="head-title mt-3 mb-3"><img src={logo} width={200} /></h1>
                </div>
                <div className="holder">
                    <div className="group-btn">
                        <button className="btn btn-block btn-primary" onClick={() => this.props.history.push('/' + Route.RouteCreatePageRoute.url)}>TẠO TUYẾN</button>
                    </div>
                    <div className="group-btn">
                        <button className="btn btn-block btn-primary" onClick={() => this.props.history.push('/' + Route.RouteStorePageRoute.url)}>TẠO KẾ HOẠCH THEO TUYẾN</button>
                    </div>
                    <div className="group-btn">
                        <button className="btn btn-block btn-primary" onClick={() => this.props.history.push('/' + Route.PlanStorePageAddRoute.url)}>TẠO KẾ HOẠCH THEO NHÀ THUỐC</button>
                    </div>
                    <div className="group-btn">
                        <button className="btn btn-block btn-dark" onClick={() => this.props.history.push('/' + Route.HomePageRoute.url)}>QUAY LẠI</button>
                    </div>
                </div>

            </div>
        );
    }
}

const mapStateToProps = ({ settings }) => ({ settings });
export default withRouter(connect(mapStateToProps)(RouteHomePage));