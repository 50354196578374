import * as Constants from 'constants/Constants';
import { Metadata, Coord } from 'models/Settings';
import Store from 'models/Store';

export function setLoading(loading: boolean) {
    return { 
        type: Constants.SET_LOADING, 
        payload: loading
    };
}

export function setMetadata(metadata: Metadata) {
    return { 
        type: Constants.SET_METADATA, 
        payload: metadata
    };
}

export function setStore(stores: Store[]) {
    return { 
        type: Constants.SET_STORE, 
        payload: stores
    };
}

export function setLocation(coord: Coord) {
    return { 
        type: Constants.SET_COORD, 
        payload: coord
    };
}

export function setRole(role: 'staff' | 'user') {
    return { 
        type: Constants.SET_ROLE, 
        payload: role
    };
}