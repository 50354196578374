import React from 'react';
import Store, { TargetActualIncome } from 'models/Store';
import { Metadata, City, District, PeriodOption } from 'models/Settings';
import { formatMoney } from 'util/ControlUtils';

const Step2 = ({
    formFieldsAdses,
    formData,
    store,
    metadata,
    targetActualIncome,
    onSubmit
}) => {
    const storeObj: Store = store;
    const metadataObj: Metadata = metadata;

    const city = metadataObj.city.find((e: City) => e.id === formData.city.id);
    const district = city ? city.district.find((e: District) => e.id === formData.district.id) : null;
    const period =  metadataObj.allPeriods.find((e: PeriodOption) => e.id === formData.period.id);
    const totalPrice = formFieldsAdses.reduce((acc, cur) => acc + cur.priceData, 0);
    const totalpriceNotReachTarget = formFieldsAdses.reduce((acc, cur) => acc + cur.priceNotReachTargetData, 0);
    const totalpriceBonus = formFieldsAdses.reduce((acc, cur) => acc + cur.priceBonusData, 0);
    
    const targetActualIncomeObj: TargetActualIncome = targetActualIncome;

    return <React.Fragment>
        <div className="sticky-top">
            <div className="row pt-2">
                <div className="col-6">TDV: {metadata.userInfo.name}</div>
                <div className="col-6 text-right">Nhóm: {metadata.userInfo.groupName}</div>
            </div>
            <h3 className="head-title mt-0 mb-0 pt-3">NHÀ THUỐC XÁC NHẬN PHÍ QUẢNG CÁO</h3>
        </div>
        <div className="holder">
            <div className="item-ads row">
                <div className="col-12">
                    <div className="item-ads-table mt-3">
                        <table>
                            <tbody>
                                <tr>
                                    <td>Nhà thuốc</td>
                                    <td>{storeObj.name}</td>
                                </tr>
                                <tr>
                                    <td>Địa chỉ</td>
                                    <td>{`${storeObj.streetNumber} ${storeObj.street}, ${storeObj.wardName}, ${district ? district.name : ''}, ${city ? city.name : ''}`}</td>
                                </tr>
                                <tr>
                                    <td>Người hưởng phí quảng cáo</td>
                                    <td>{storeObj.paymentAccountName}</td>
                                </tr>
                                <tr>
                                    <td>CMND số</td>
                                    <td>{storeObj.paymentIdentityCard}</td>
                                </tr>
                                <tr>
                                    <td>Tài khoản ngân hàng</td>
                                    <td>{storeObj.paymentAccountNumber}</td>
                                </tr>
                                <tr>
                                    <td>Ngân hàng</td>
                                    <td>{storeObj.paymentBankName}</td>
                                </tr>
                                <tr>
                                    <td>Mục tiêu doanh số {period ? period.name : ''}</td>
                                    <td>{targetActualIncomeObj.targetKey}</td>
                                </tr>
                                <tr>
                                    <td>Kết quả trong {period ? period.name : ''}</td>
                                    <td>{targetActualIncomeObj.actual}</td>
                                </tr>
                                <tr>
                                    <td>Tỉ lệ đạt</td>
                                    <td>{targetActualIncomeObj.percent}</td>
                                </tr>
                            </tbody>
                        </table>
                        <div className="mt-2">
                            <b>Tôi xác nhận đang thực hiện quảng cáo các vật dụng liệt kê bên dưới và sẽ nhận phí quảng cáo {period ? period.name : ''} chi tiết như sau:</b>
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <div className="item-ads-table mt-3">
                        <table>
                            <thead>
                                <tr>
                                    <th>VẬT DỤNG</th>
                                    <th>SỐ LƯỢNG</th>
                                    <th>THÁNG</th>
                                    <th className="text-right">PHÍ QUẢNG CÁO</th>
                                    <th className="text-right">PHÍ CHIẾT KHẤU</th>
                                    <th className="text-right">MÚC PHÍ / QUÝ</th>
                                </tr>
                            </thead>
                            <tbody>
                                {formFieldsAdses.map((field, key) =>
                                    <tr key={key}>
                                        <td>{field.name}</td>
                                        <td>{field.quantity} {field.unitName}</td>
                                        <td>{field.month}</td>
                                        <td className="text-right">{field.priceNotReachTarget} VNĐ</td>
                                        <td className="text-right">{field.priceBonus} VNĐ</td>
                                        <td className="text-right">{field.price} VNĐ</td>
                                    </tr>
                                )}
                                <tr>
                                    <td><b>TỔNG CỘNG</b></td>
                                    <td colSpan={2}></td>
                                    <td className="text-right"><b>{formatMoney(totalpriceNotReachTarget, 0)} VNĐ</b></td>
                                    <td className="text-right"><b>{formatMoney(totalpriceBonus, 0)} VNĐ</b></td>
                                    <td className="text-right"><b>{formatMoney(totalPrice, 0)} VNĐ</b></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="col-12 mt-3">
                    <form id="file" onSubmit={(e) => e.preventDefault()}>
                        Phí quảng cáo <b>{period ? period.name : ''}</b> sẽ được chuyển khoản vào số tài khoản nêu trên trước ngày <b>{period ? period.deadline : ''}</b>.
                    </form>
                </div>
            </div>

        </div>
        <div className="sticky-bottom">
            <div className="group-btn">
                <button className="btn btn-dark" onClick={() => onSubmit('draft', 1)}>Quay lại</button>
                <button className="btn btn-primary" onClick={() => onSubmit('draft', 3)}>Ký xác nhận</button>
            </div>
        </div>
    </React.Fragment>
}

export default Step2;