import { PAYMENT_MONTH } from 'constants/Constants';

export default class Store {
    id = 0;
    cityId = 0;
    districtId = 0;
    wardName = '';
    street = '';
    streetNumber = '';
    name = '';
    paymentAccountName = '';
    paymentIdentityCard = '';
    paymentAccountNumber = '';
    paymentBankName = '';
}

export class Item {
    id = 0;
    name = '';
    unitName = '';
    quantity = 0;
    price = 0;
}

export class AdsOption {
    id = 0;
    name = '';
    checked = false;
}

export class Ads {
    id = 0;
    name = '';
    unitName = '';
    quantity = 0;
    price = 0;
    priceNotReachTarget = 0;
    priceBonus = 0;
    optionId = 0;
    options: AdsOption[] = [];
    isReplace = false;
    month = PAYMENT_MONTH;
}

export class TrackDate {
    createdDate = '';
    approveDate = '';
    approve1Date = '';
    approve2Date = '';
    doneDate = '';
    seenDate = '';
    progress = '';
    doneImage = '';
}

export class TargetActualIncome {
    targetKey = '-';
    target = '-';
    actual = '-';
    percent = '-';
    targetValue = 0;
    outstandingValue = 0;
    actualValue = 0;
    carriedOverValue = 0;
    percentValue = 0;
    period = 0;
}

export class Route {
    id = 0;
    name = '';
}