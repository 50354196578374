import HomePage from './HomePage';
import CalendarReportPage from './CalendarReportPage';
import CalendarPlanPage from './CalendarPlanPage';
import PlanStorePage from './PlanStorePage';
import StaffCalendarPage from './CalendarPage';
import ManagerCalendarPage from './ManagerCalendarPage';
import CalendarForManagerPage from './CalendarPage/manager';
import CalendarForAdminPage from './CalendarPage/admin';
import ReportStorePage from './ReportStorePage';
import RequestAdsPage from './RequestAdsPage';
import RequestPaymentPage from './RequestPaymentPage';
import RequestStorePage from './RequestStorePage';
import HistoryPage from './HistoryPage';
import HistoryDetailPage from './HistoryDetailPage';
import HistoryDetailForAdminPage from './HistoryDetailPage/admin';
import ManagerReportSearchPage from './ManagerReportSearchPage';
import ManagerReportPage from './ManagerReportPage';
import ManagerReportDetailPage from './ManagerReportDetailPage';
import ManagerStoreSearchPage from './ManagerStoreSearchPage';
import ManagerStoreDetailPage from './ManagerStoreDetailPage';
import ManagerRequestStorePage from './ManagerRequestStorePage';
import DocumentPage from './DocumentPage';
import RouteHomePage from './RouteHomePage';
import RouteCreatePage from './RouteCreatePage';
import RouteStorePage from './RouteStorePage';


export const HomePageRoute =  {
    url: 'trang-chu',
    Component: HomePage
};

export const RouteHomePageRoute =  {
    url: 'tuyen-nha-thuoc',
    Component: RouteHomePage
};
export const RouteCreatePageRoute =  {
    url: 'tuyen-nha-thuoc/tao-tuyen',
    Component: RouteCreatePage
};
export const RouteStorePageRoute =  {
    url: 'tuyen-nha-thuoc/ke-hoach-theo-tuyen',
    Component: RouteStorePage
};

export const ManagerStoreSearchPageRoute =  {
    url: 'thong-tin-nha-thuoc',
    Component: ManagerStoreSearchPage
};
export const StaffStoreDetailPageRoute =  {
    url: 'thong-tin-nha-thuoc/:cityId/:districtId/:fromDate/:toDate',
    Component: ManagerStoreDetailPage
};
export const ManagerStoreDetailPageRoute =  {
    url: 'thong-tin-nha-thuoc/:staffId/:fromDate/:toDate',
    Component: ManagerStoreDetailPage
};
export const ManagerReportSearchPageRoute =  {
    url: 'bao-cao/:type',
    Component: ManagerReportSearchPage
};
export const ManagerReportPageRoute =  {
    url: 'bao-cao/:type/:staffId/:fromDate/:toDate',
    Component: ManagerReportPage
};
export const ManagerReportDetailPageRoute =  {
    url: 'bao-cao/:type/:staffId/:date',
    Component: ManagerReportDetailPage
};
export const ManagerRequestStorePageRoute =  {
    url: 'duyet-yeu-cau-tao-nha-thuoc',
    Component: ManagerRequestStorePage
};
export const StaffCalendarPageRoute =  {
    url: 'lich-cong-tac',
    Component: StaffCalendarPage
};
export const CalendarReportPageRoute =  {
    url: 'lich-cong-tac/bao-cao/:date',
    Component: CalendarReportPage
};
export const CalendarPlanPageRoute =  {
    url: 'lich-cong-tac/ke-hoach/:date',
    Component: CalendarPlanPage
};
export const PlanStorePageAddRoute =  {
    url: 'ke-hoach-cong-tac/them-moi',
    Component: PlanStorePage
};
export const PlanStorePageEditRoute =  {
    url: 'ke-hoach-cong-tac/:storeId/:date/thay-doi',
    Component: PlanStorePage
};
export const ManagerCalendarPageRoute =  {
    url: 'lich-cong-tac-manager',
    Component: ManagerCalendarPage
};
export const CalendarPageForManagerPageRoute =  {
    url: 'lich-cong-tac-manager/:staffId/:fullname',
    Component: CalendarForManagerPage
};

export const CalendarPageForAdminPageRoute =  {
    url: 'lich-cong-tac/0/0/0/:token',
    Component: CalendarForAdminPage
};

export const ReportStorePageAddRoute =  {
    url: 'bao-cao-nha-thuoc/them-moi',
    Component: ReportStorePage
};

export const ReportStorePageEditRoute =  {
    url: 'bao-cao-nha-thuoc/:storeId/:date/thay-doi',
    Component: ReportStorePage
};

export const RequestAdsPageAddRoute =  {
    url: 'yeu-cau-thi-cong/them-moi',
    Component: RequestAdsPage
};
export const RequestAdsPageEditRoute =  {
    url: 'yeu-cau-thi-cong/:storeId/thay-doi',
    Component: RequestAdsPage
};

export const RequestPaymentPageAddRoute =  {
    url: 'yeu-cau-tra-phi/them-moi',
    Component: RequestPaymentPage
};

export const RequestPaymentPageEditRoute =  {
    url: 'yeu-cau-tra-phi/:storeId/thay-doi',
    Component: RequestPaymentPage
};

export const RequestStorePageRoute =  {
    url: 'yeu-cau-tao-nha-thuoc',
    Component: RequestStorePage
};

export const HistoryPageRoute =  {
    url: 'lich-su-lam-viec',
    Component: HistoryPage
};

export const HistoryDetailPageRoute =  {
    url: 'lich-su-lam-viec/:storeId/:id/:type',
    Component: HistoryDetailPage
};

export const HistoryDetailForAdminPageRoute =  {
    url: 'lich-su-lam-viec/:storeId/:id/:type/:token',
    Component: HistoryDetailForAdminPage
};

export const DocumentPageRoute =  {
    url: 'tai-lieu-tham-khao',
    Component: DocumentPage
};

const RouteLoader = [
    HomePageRoute,
    RouteHomePageRoute,
    RouteCreatePageRoute,
    RouteStorePageRoute,
    StaffCalendarPageRoute,
    CalendarReportPageRoute,
    CalendarPlanPageRoute,
    PlanStorePageAddRoute,
    PlanStorePageEditRoute,
    CalendarPageForManagerPageRoute,
    CalendarPageForAdminPageRoute,
    ManagerCalendarPageRoute,
    ReportStorePageAddRoute,
    ReportStorePageEditRoute,
    RequestAdsPageAddRoute,
    RequestAdsPageEditRoute,
    RequestPaymentPageAddRoute,
    RequestPaymentPageEditRoute,
    RequestStorePageRoute,
    ManagerReportSearchPageRoute,
    ManagerReportPageRoute,
    ManagerReportDetailPageRoute,
    ManagerStoreSearchPageRoute,
    ManagerStoreDetailPageRoute,
    StaffStoreDetailPageRoute,
    ManagerRequestStorePageRoute,
    HistoryPageRoute,
    HistoryDetailPageRoute,
    HistoryDetailForAdminPageRoute,
    DocumentPageRoute
];

export default RouteLoader;
