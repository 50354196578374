import * as Constants from 'constants/Constants';
import Settings from 'models/Settings';

const settings = (state = new Settings(), action) => {
    switch (action.type) {

        case Constants.SET_STORE:
            state.stores = action.payload;
            return {...state};

        case Constants.SET_METADATA:
            state.metadata = action.payload;
            return {...state};

        case Constants.SET_LOADING:
            state.loading = action.payload;
            return {...state};

        case Constants.SET_COORD:
            state.coord = action.payload;
            return {...state};

        case Constants.SET_ROLE:
            state.role = action.payload;
            return {...state};

        default:
            return state;
    }
};

export default settings;