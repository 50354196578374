import axios from 'axios';
import * as API from 'constants/Constants';
import CommonService from './common.service';

export default class ApiService {
    commonService: CommonService;

    constructor() {
        this.commonService = new CommonService();
    }

    login(username, password, role: 'staff' | 'user') {
        const headers = this.commonService.getHeader();
        let data = new FormData();
        data.append('username', username);
        data.append('password', password);
        data.append('role', role);
        
        return axios
            .post(API.API_LOGIN, data, { headers, withCredentials: true })
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }

    info() {
        const headers = this.commonService.getHeader();
        return axios
            .get(API.API_INFO, { headers })
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }

    getCalendar(date, token = '', staffId = '') {
        const headers = this.commonService.getHeader(true, token);
        return axios
            .get(API.API_GET_CALENDAR + `?date=${date}&staffId=${staffId}`, { headers })
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }

    postCalendar(calendarData) {
        const headers = this.commonService.getHeader();
        let data = new FormData();
        data.append('data', JSON.stringify(calendarData));
        
        return axios
            .post(API.API_POST_CALENDAR, data, { headers, withCredentials: true })
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }

    getManagerCalendar(date, token = '') {
        const headers = this.commonService.getHeader(true, token);
        return axios
            .get(API.API_GET_MANAGER_CALENDAR + `?date=${date}`, { headers })
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }

    postManagerCalendar(calendarData) {
        const headers = this.commonService.getHeader();
        let data = new FormData();
        data.append('data', JSON.stringify(calendarData));
        
        return axios
            .post(API.API_POST_MANAGER_CALENDAR, data, { headers, withCredentials: true })
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }

    postManagerCalendarTargetPrice(currentDate, targetPrice, staffId) {
        const headers = this.commonService.getHeader();
        let data = new FormData();
        data.append('currentDate', currentDate);
        data.append('targetPrice', targetPrice);
        data.append('staffId', staffId);
        
        return axios
            .post(API.API_POST_MANAGER_CALENDAR_TARGET_PRICE, data, { headers, withCredentials: true })
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }

    items(token = '') {
        const headers = this.commonService.getHeader(true, token);
        return axios
            .get(API.API_ITEMS, { headers })
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }

    adses(type: 'reportStore' | 'requestAds' | 'requestPayment' | 'requestStore' | 'planStore', token = '') {
        const headers = this.commonService.getHeader(true, token);
        return axios
            .get(API.API_ADSES+ `?type=${type}`, { headers })
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }

    getReportStore(storeId, date, refId = 0, token = '') {
        const headers = this.commonService.getHeader(true, token);
        return axios
            .get(API.API_GET_REPORT_STORE + `?storeId=${storeId}&refId=${refId}&date=${date}`, { headers })
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }

    postReportStore(reportStoreData, type: 'draft' | 'sent', refId = -1) {
        const headers = this.commonService.getHeader();
        let data = new FormData();
        const formData = {
            store: reportStoreData.store,
            city: reportStoreData.city,
            district: reportStoreData.district,
            itemsStocked: reportStoreData.itemsStocked,
            itemsOrder: reportStoreData.itemsOrder,
            adses: reportStoreData.adses,
            isVisited: reportStoreData.isVisited,
            note: reportStoreData.note,
            date: reportStoreData.date
        }
        data.append('data', JSON.stringify(formData));
        reportStoreData.adsImageEvidence.forEach(e => data.append('storeImg[]', e));
        reportStoreData.adsImageDocument.forEach(e => data.append('documentImg[]', e));

        data.append('type', type);
        data.append('refId', String(refId));
        
        return axios
            .post(API.API_POST_REPORT_STORE, data, { headers, withCredentials: true })
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }

    getRequestAds(storeId, refId = 0, token = '') {
        const headers = this.commonService.getHeader(true, token);
        return axios
            .get(API.API_GET_REQUEST_ADS + `?storeId=${storeId}&refId=${refId}`, { headers })
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }

    postRequestAds(requestAdsData, type) {
        const headers = this.commonService.getHeader();
        let data = new FormData();
        
        const formData = {
            store: requestAdsData.store,
            city: requestAdsData.city,
            district: requestAdsData.district,
            adses: requestAdsData.adses,
            note: requestAdsData.note
        }
        data.append('data', JSON.stringify(formData));

        requestAdsData.adsImageInside.forEach(e => data.append('storeInImg[]', e));
        requestAdsData.adsImageOutside.forEach(e => data.append('storeOutImg[]', e));
        requestAdsData.adsImageDocument.forEach(e => data.append('documentImg[]', e));

        data.append('type', type);
        
        return axios
            .post(API.API_POST_REQUEST_ADS, data, { headers, withCredentials: true })
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }

    getRequestPaymentByPeriod(storeId, requestPaymentConfigId = 0, token = '') {
        const headers = this.commonService.getHeader(true, token);
        return axios
            .get(API.API_GET_REQUEST_PAYMENT + `?storeId=${storeId}&requestPaymentConfigId=${requestPaymentConfigId}`, { headers })
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }

    getRequestPayment(storeId, refId = 0, token = '') {
        const headers = this.commonService.getHeader(true, token);
        return axios
            .get(API.API_GET_REQUEST_PAYMENT + `?storeId=${storeId}&refId=${refId}`, { headers })
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }

    postRequestPayment(requestPaymentData, adsImageInsidePreview, adsImageOutsidePreview, signatureData, type) {
        const headers = this.commonService.getHeader();
        let data = new FormData();
        
        const formData = {
            store: requestPaymentData.store,
            city: requestPaymentData.city,
            district: requestPaymentData.district,
            adses: requestPaymentData.adses,
            period: requestPaymentData.period,
            note: requestPaymentData.note
        }
        data.append('data', JSON.stringify(formData));

        adsImageInsidePreview.forEach(e => data.append('storeInImg[]', e));
        adsImageOutsidePreview.forEach(e => data.append('storeOutImg[]', e));

        data.append('signatureData', signatureData);
        data.append('type', type);
        
        return axios
            .post(API.API_POST_REQUEST_PAYMENT, data, { headers, withCredentials: true })
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }

    history(page, type, fromDate, toDate) {
        const headers = this.commonService.getHeader();
        return axios
            .get(API.API_HISTORY + `?page=${page}&type=${type}&fromDate=${fromDate}&toDate=${toDate}`, { headers })
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }

    getRequestStore(storeId, token = '') {
        const headers = this.commonService.getHeader(true, token);
        return axios
            .get(API.API_GET_STORE + `?storeId=${storeId}`, { headers })
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }

    postRequestStore(requestStoreData) {
        const headers = this.commonService.getHeader();
        let data = new FormData();
        
        const formData = {
            isReplace: requestStoreData.isReplace ? '1' : '0',
            store: requestStoreData.store,
            city: requestStoreData.city,
            district: requestStoreData.district,
            wardName: requestStoreData.wardName,
            street: requestStoreData.street,
            streetNumber: requestStoreData.streetNumber,
            name: requestStoreData.name,
            ownerName: requestStoreData.ownerName,
            pharmacistName: requestStoreData.pharmacistName,
            phone: requestStoreData.phone,
            paymentAccountName: requestStoreData.paymentAccountName,
            paymentIdentityCard: requestStoreData.paymentIdentityCard,
            paymentAccountNumber: requestStoreData.paymentAccountNumber,
            paymentBankName: requestStoreData.paymentBankName,
            replaceNote: requestStoreData.replaceNote
        }
        data.append('data', JSON.stringify(formData));
        
        requestStoreData.imgBusinessCertPreview.forEach(e => data.append('imgBusinessCertPreview[]', e));
        requestStoreData.imgGppCertPreview.forEach(e => data.append('imgGppCertPreview[]', e));
        requestStoreData.imgBankCertPreview.forEach(e => data.append('imgBankCertPreview[]', e));
        requestStoreData.imgIdCertPreview.forEach(e => data.append('imgIdCertPreview[]', e));
        requestStoreData.imgDelegateCertPreview.forEach(e => data.append('imgDelegateCertPreview[]', e));
        
        return axios
            .post(API.API_POST_STORE, data, { headers, withCredentials: true })
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }

    getManagerReport(staffId, fromDate, toDate, type: 'reportStore' | 'requestAds' | 'requestPayment' | 'planStore') {
        const headers = this.commonService.getHeader(true);
        return axios
            .get(API.API_GET_MANAGER_REPORT + `?staffId=${staffId}&fromDate=${fromDate}&toDate=${toDate}&type=${type}`, { headers })
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }

    getManagerReportDetail(staffId, date, type: 'reportStore' | 'requestAds' | 'requestPayment' | 'planStore') {
        const headers = this.commonService.getHeader(true);
        return axios
            .get(API.API_GET_MANAGER_REPORT_DETAIL + `?staffId=${staffId}&date=${date}&type=${type}`, { headers })
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }

    getManagerStoreDetail(staffId, fromDate, toDate, page) {
        const headers = this.commonService.getHeader(true);
        return axios
            .get(API.API_GET_MANAGER_STORE_DETAIL + `?page=${page}&staffId=${staffId}&fromDate=${fromDate}&toDate=${toDate}`, { headers })
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }

    getStaffStoreDetail(cityId, districtId, fromDate, toDate, page) {
        const headers = this.commonService.getHeader(true);
        return axios
            .get(API.API_GET_MANAGER_STORE_DETAIL + `?page=${page}&cityId=${cityId}&districtId=${districtId}&fromDate=${fromDate}&toDate=${toDate}`, { headers })
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }

    getRequestStorePending() {
        const headers = this.commonService.getHeader(true);
        return axios
            .get(API.API_GET_MANAGER_REQUEST_STORE_PENDING, { headers })
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }

    getRouteStores(routeName) {
        const headers = this.commonService.getHeader(true);
        return axios
            .get(API.API_GET_ROUTE_STORES + `?routeName=${routeName}`, { headers })
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }

    postRouteStores(routeName, storeIds) {
        const headers = this.commonService.getHeader();
        let data = new FormData();
        data.append('routeName', routeName);
        storeIds.forEach(e => {
            data.append('storeIds[]', e);
        });
        
        return axios
            .post(API.API_POST_ROUTE_STORES, data, { headers, withCredentials: true })
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }

    deleteRouteStores(routeName) {
        const headers = this.commonService.getHeader();
        let data = new FormData();
        data.append('routeName', routeName);
        
        return axios
            .post(API.API_DELETE_ROUTE_STORES, data, { headers, withCredentials: true })
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }

    getRoutePlanStores(routeName, date) {
        const headers = this.commonService.getHeader(true);
        return axios
            .get(API.API_GET_ROUTE_PLAN_STORES + `?routeName=${routeName}&date=${date}`, { headers })
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }

    postRoutePlanStores(routePlanStoreData) {
        const headers = this.commonService.getHeader();
        let data = new FormData();
        const itemsOrder: any[] = [];
        Object.keys(routePlanStoreData.itemsOrder).forEach(k => {
            itemsOrder.push(routePlanStoreData.itemsOrder[k]);
        });
        const adses: any[] = [];
        Object.keys(routePlanStoreData.adses).forEach(k => {
            adses.push(routePlanStoreData.adses[k]);
        });
        const formData = {
            itemsOrder: itemsOrder,
            adses: adses,
        }
        data.append('data', JSON.stringify(formData));
        data.append('date', routePlanStoreData.date);
        data.append('routeName', routePlanStoreData.routeName);
        
        return axios
            .post(API.API_POST_ROUTE_PLAN_STORES, data, { headers, withCredentials: true })
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }

    getCalendarDetail(date, type: 'report' | 'plan') {
        const headers = this.commonService.getHeader(true);
        return axios
            .get(API.API_GET_CALENDAR_DETAIL + `?date=${date}&type=${type}`, { headers })
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }

    getPlanStore(storeId, date, refId = 0, token = '') {
        const headers = this.commonService.getHeader(true, token);
        return axios
            .get(API.API_GET_PLAN_STORE + `?storeId=${storeId}&refId=${refId}&date=${date}`, { headers })
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }

    postPlanStore(planStoreData, type: 'draft' | 'sent') {
        const headers = this.commonService.getHeader();
        let data = new FormData();
        const formData = {
            store: planStoreData.store,
            city: planStoreData.city,
            district: planStoreData.district,
            itemsOrder: planStoreData.itemsOrder,
            adses: planStoreData.adses,
            isVisited: planStoreData.isVisited
        }
        data.append('data', JSON.stringify(formData));
        data.append('type', type);
        data.append('date', planStoreData.date);
        
        return axios
            .post(API.API_POST_PLAN_STORE, data, { headers, withCredentials: true })
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }

    deletePlanStore(storeId, date) {
        const headers = this.commonService.getHeader();
        return axios
            .get(API.API_DELETE_PLAN_STORE + `?storeId=${storeId}&date=${date}`, { headers })
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }

    doApprove(isApprove, id, note, type, isUndo = false) {
        const headers = this.commonService.getHeader();
        let data = new FormData();
        data.append('isApprove', isApprove ? '1' : '0');
        data.append('id', id);
        data.append('note', note);
        data.append('type', type);
        data.append('isUndo', isUndo ? 'true' : '');
        
        return axios
            .post(API.API_DO_APPROVE, data, { headers, withCredentials: true })
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }

    doStoreApprove(isApprove, id, note) {
        const headers = this.commonService.getHeader();
        let data = new FormData();
        data.append('isApprove', isApprove ? '1' : '0');
        data.append('id', id);
        data.append('note', note);
        
        return axios
            .post(API.API_DO_STORE_APPROVE, data, { headers, withCredentials: true })
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }

    getDocument(page, documentGroupId) {
        const headers = this.commonService.getHeader();
        return axios
            .get(API.API_GET_DOCUMENT + `?page=${page}&documentGroupId=${documentGroupId}`, { headers })
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }

    getDocumentGroup() {
        const headers = this.commonService.getHeader(true);
        return axios
            .get(API.API_GET_DOCUMENT_GROUP, { headers })
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }

    getDistrictsOfStoresOfStaffUser() {
        const headers = this.commonService.getHeader();
        return axios
            .get(API.API_GET_DISTRICTS_STORE_OF_STAFF_USER, { headers })
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }
}
