import React, { useEffect, useState } from 'react';
import { DisplayDateTimeFormat } from 'util/ControlUtils';

const ViewImgGroup: React.SFC<{ 
    images: (string | ArrayBuffer | null)[]; 
    showMsgIfEmpty?: boolean; 
    showDeleteButton?: boolean;
    onFileChanged?: (previews: (string | ArrayBuffer | null)[]) => void; }> = ({ images, showMsgIfEmpty = false, showDeleteButton = false, onFileChanged }) => {
    const [ createDate, setCreateDate ] = useState([new Date(), new Date(), new Date()]);
    useEffect(() => {
        // Update the document title using the browser API
        const newCreatedDate = [...createDate];
        newCreatedDate[images.length - 1] = new Date();
        setCreateDate(newCreatedDate);
    }, [images.length]);
    const deleteImage = (index) => {
        let previewsReturn: (string | ArrayBuffer | null)[] = [];
        if (images) {
            previewsReturn = images;
        }
        previewsReturn[index] = '';

        onFileChanged!(previewsReturn.filter(e => e));
    }

    return (
        <>
            <div className="img-group">
                {[...Array(3).keys()].map((index, key) =>
                    images[index] && <div key={key}>
                        {showDeleteButton && images[index] && <button onClick={() => deleteImage(index)} className="btn btn-danger btn-block mt-1 mb-1">Xoá</button>}
                        <img className="mt-1 mb-1 img-center" src={images[index]!.toString()} />
                        {showDeleteButton && images[index] && <div className="date-overlay">{DisplayDateTimeFormat(createDate[index], 'DD/MM/YYYY HH:mm:ss')}</div>}
                    </div>)}
            </div>
            {showMsgIfEmpty && !images.length && <span>Không có hình ảnh</span>}
        </>
    );
};

export default ViewImgGroup;
