import React from 'react';
import { connect } from 'react-redux';
import { PageProps } from 'interfaces';
import { Field } from 'models/Settings';
import { withRouter } from 'react-router-dom';
import FormItem, { setStateByProperty, checkValidations, forceAbs } from 'components/Form';
import Swal from 'sweetalert2';
import { getFields, getFormFields } from './fields';
import moment from 'moment';
import { chunk, interpret } from 'util/ControlUtils';
import Calendar, { SaleTarget } from 'models/Calendar';
import store from 'reduxs/index';
import { setLoading } from 'reduxs/actions/Settings';

interface State {
    disabled: boolean;
    fields: Field[];

    // Custom Code
    token: string;
    formData: {
        calendar: Calendar[];
    };
    gridCalendar: {
        currentDate: Date;
        cells: {
            weekday: number;
            day: number;
        }[];
    };
    saleTarget: SaleTarget;
    type: 'view' | 'edit';
}

class CalendarForAdminPage extends React.Component<PageProps, State> {
    constructor(props: PageProps) {
        super(props);

        let fields = getFields();
        fields = fields.map(e => ({
            ...new Field(), ...e,
            isValid: false,
            isValidType: 'required'
        }));

        this.state = {
            disabled: true,
            fields: fields,

            // Custom Code
            token: props.match.params.token,
            formData: {
                calendar: []
            },
            gridCalendar: this.getGridCalendar(),
            saleTarget: new SaleTarget(),
            type: 'view'
        }
    }

    // Custom Code
    componentDidMount() {
        this.getData();
    }

    getData() {
        const { gridCalendar: { currentDate }, token } = this.state;
        store.dispatch(setLoading(true));
        this.props.apiService.getCalendar(moment(currentDate).startOf('month').format('YYYY-MM-DD'), token).then(res => {
            const calendar: Calendar[] = res.calendar;
            const saleTarget: SaleTarget = res.saleTarget;
            this.setState({
                formData: {
                    calendar: calendar
                },
                saleTarget: saleTarget
            });
            store.dispatch(setLoading(false));
        })
    }

    getGridCalendar = (direction: 'none' | 'next' | 'prev' = 'none') => {
        let date = new Date();
        if (direction !== 'none') {
            const { gridCalendar: { currentDate } } = this.state;
            date = direction === 'next' ? moment(currentDate).add(1, 'month').toDate() : (direction === 'prev' ? moment(currentDate).add(-1, 'month').toDate() : new Date());
        }
        const daysInMonth = moment(date).daysInMonth();
        let cells: { weekday: number; day: number }[] = [];
        for (let i = 1; i <= daysInMonth; i++) {
            const day = moment(date).startOf('month').add(i - 1, 'days');
            if (i === 1) {
                for (let missCell = 1; missCell < day.isoWeekday(); missCell++) {
                    cells.push({ weekday: missCell, day: 0 })
                }
            }
            cells.push({ weekday: day.isoWeekday(), day: i });
        }

        return {
            currentDate: date,
            cells: cells
        }
    }

    prevMonth = () => {
        this.setState({
            gridCalendar: this.getGridCalendar('prev'),
            formData: {
                calendar: []
            }
        }, () => {
            this.getData()
        })
    }

    nextMonth = () => {
        this.setState({
            gridCalendar: this.getGridCalendar('next'),
            formData: {
                calendar: []
            }
        }, () => {
            this.getData()
        })
    }

    // Custom Code
    onChangeHandle = (nameHtml, valueHtml) => {
        const { formData, gridCalendar } = this.state;
        let calendar: Calendar[] = [];

        switch (nameHtml) {
            default:
                const extractNameHTML = interpret(nameHtml);
                if (extractNameHTML) {
                    if (extractNameHTML['name'] === 'calendar' &&
                        extractNameHTML['indices'] &&
                        extractNameHTML['indices'].length === 2) { // array of sub field
                        const extractName = extractNameHTML['indices'][1];
                        const extractIndex = extractNameHTML['indices'][0];

                        if (formData.calendar) {
                            calendar = formData.calendar;
                        }
                        if (!calendar[extractIndex]) {
                            calendar[extractIndex] = new Calendar();
                            calendar[extractIndex].date = moment(gridCalendar.currentDate).startOf('month').add(extractIndex-1, 'days').format('YYYY-MM-DD');
                        }
                        if (extractName === 'note') {
                            calendar[extractIndex].note = valueHtml;
                            setStateByProperty(this, 'calendar', nameHtml, calendar, 'note');
                        }
                    }
                }
                break;
        }
        this.setState({
            disabled: false
        });
    }

    // Custom Code
    onSubmit = () => {
        const isValid = checkValidations(this);
        const { formData: { calendar } } = this.state;

        if (isValid) {
            Swal.fire({
                html: 'Vui lòng chờ ...<br/><div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>',
                showConfirmButton: false,
                allowOutsideClick: false
            })
            this.props.apiService.postCalendar(calendar).then(() => {
                this.setState({ type: 'view' });
                Swal.close();
            });
        }
    }

    // Custom Code
    onCancel = () => {
        this.setState({ type: 'view' }, () => {
            this.getData();
        });
    }

    // Custom Code
    render() {
        const { fields, formData, gridCalendar, saleTarget, type } = this.state;
        const { settings: { metadata } } = this.props;
        const formFields = getFormFields(formData, gridCalendar.cells);
        const weeks = chunk(gridCalendar.cells, 7);

        return (
            <div className="calendar-page">
                <div className="sticky-top">
                    <div className="row pt-2">
                        <div className="col-6">TDV: {metadata.userInfo.name}</div>
                        <div className="col-6 text-right">Nhóm: {metadata.userInfo.groupName}</div>
                    </div>
                    <h3 className="head-title mt-0 mb-0 pt-3">
                        {type === 'view' ? 'Lịch công tác' : 'Tạo lịch công tác'}
                    </h3>
                    <div className="action">
                        <button disabled={type === 'edit'} onClick={() => this.prevMonth()} className="btn btn-primary"><i className="zmdi zmdi-chevron-left"></i> Tháng trước</button>
                        <button disabled={type === 'edit'} onClick={() => this.nextMonth()} className="btn btn-primary">Tháng sau <i className="zmdi zmdi-chevron-right"></i></button>
                        <table className="price-target">
                            <tbody>
                                <tr>
                                    <td className="label">Mục tiêu</td>
                                    <td className="value">{saleTarget.targetPrice}</td>
                                    <td className="label">Kế hoạch</td>
                                    <td className="value">{saleTarget.planPrice}</td>
                                </tr>
                                <tr>
                                    <td className="label">Kết quả</td>
                                    <td className="value">{saleTarget.resultPrice}</td>
                                    <td className="label">Còn lại</td>
                                    <td className={`value ${saleTarget.remainPrice.indexOf('-') > -1 ? 'success' : 'fail'}`}>{saleTarget.remainPrice}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="holder">
                    <div className="calendar-table">
                        <table>
                            <thead>
                                <tr>
                                    <th>{moment(gridCalendar.currentDate).format('MM/YYYY')}</th>
                                    <th className="text-center">Thứ 2</th>
                                    <th className="text-center">Thứ 3</th>
                                    <th className="text-center">Thứ 4</th>
                                    <th className="text-center">Thứ 5</th>
                                    <th className="text-center">Thứ 6</th>
                                    <th className="text-center">Thứ 7</th>
                                    <th className="text-center">Chủ Nhật</th>
                                </tr>
                            </thead>
                            <tbody>
                                {weeks.map((week, key1) =>
                                    <React.Fragment key={key1}>
                                        <tr>
                                            <td></td>
                                            {week.map((day, key2) =>
                                                <td key={key2} className={`text-center ${day.day === 0 ? 'disabled' : (day.weekday === 6 || day.weekday === 7) ? 'weekend' : ''}`}>
                                                    <b>{day.day !== 0 ? day.day : ''}</b>
                                                </td>
                                            )}
                                        </tr>
                                        <tr>
                                            <td>Ở đâu</td>
                                            {week.map((day, key2) =>
                                                <td key={key2} className={`text-center ${day.day === 0 ? 'disabled' : (day.weekday === 6 || day.weekday === 7) ? 'weekend' : ''}`}>
                                                    {day.day !== 0 && <FormItem
                                                        {...formFields[key1 * 7 + key2].row1.props}
                                                        attribute={{ disabled: type === 'view' }}
                                                        onChangeHandle={this.onChangeHandle}
                                                        fields={fields}
                                                    />}
                                                </td>
                                            )}
                                        </tr>
                                        <tr>
                                            <td>Viếng thăm</td>
                                            {week.map((day, key2) =>
                                                <td key={key2} className={`text-center ${day.day === 0 ? 'disabled' : (day.weekday === 6 || day.weekday === 7) ? 'weekend' : ''}`}>
                                                    {day.day !== 0 && <FormItem
                                                        {...formFields[key1 * 7 + key2].row2.props}
                                                        attribute={{ disabled: type === 'view' }}
                                                        onChangeHandle={this.onChangeHandle}
                                                        fields={fields}
                                                    />}
                                                </td>
                                            )}
                                        </tr>
                                        <tr>
                                            <td>NT bán hàng</td>
                                            {week.map((day, key2) =>
                                                <td key={key2} className={`text-center ${day.day === 0 ? 'disabled' : (day.weekday === 6 || day.weekday === 7) ? 'weekend' : ''}`}>
                                                    {day.day !== 0 && <FormItem
                                                        {...formFields[key1 * 7 + key2].row3.props}
                                                        attribute={{ disabled: type === 'view' }}
                                                        onChangeHandle={this.onChangeHandle}
                                                        fields={fields}
                                                    />}
                                                </td>
                                            )}
                                        </tr>
                                        <tr>
                                            <td>NT VDQC</td>
                                            {week.map((day, key2) =>
                                                <td key={key2} className={`text-center ${day.day === 0 ? 'disabled' : (day.weekday === 6 || day.weekday === 7) ? 'weekend' : ''}`}>
                                                    {day.day !== 0 && <FormItem
                                                        {...formFields[key1 * 7 + key2].row4.props}
                                                        attribute={{ disabled: type === 'view' }}
                                                        onChangeHandle={this.onChangeHandle}
                                                        fields={fields}
                                                    />}
                                                </td>
                                            )}
                                        </tr>
                                        <tr>
                                            <td>Kế hoạch</td>
                                            {week.map((day, key2) =>
                                                <td key={key2} className={`plan-type text-center ${day.day === 0 ? 'disabled' : (day.weekday === 6 || day.weekday === 7) ? 'weekend' : ''}`}>
                                                    {day.day !== 0 && <FormItem
                                                        {...formFields[key1 * 7 + key2].row5.props}
                                                        attribute={{ disabled: true }}
                                                        onChangeHandle={this.onChangeHandle}
                                                        fields={fields}
                                                    />}
                                                </td>
                                            )}
                                        </tr>
                                        <tr>
                                            <td>Kết quả</td>
                                            {week.map((day, key2) =>
                                                <td key={key2} className={`report-type text-center ${day.day === 0 ? 'disabled' : (day.weekday === 6 || day.weekday === 7) ? 'weekend' : ''}`}>
                                                    {day.day !== 0 && <FormItem
                                                        {...formFields[key1 * 7 + key2].row6.props}
                                                        attribute={{ disabled: true }}
                                                        onChangeHandle={this.onChangeHandle}
                                                        fields={fields}
                                                    />}
                                                </td>
                                            )}
                                        </tr>
                                        <tr>
                                            <td>Khác</td>
                                            {week.map((day, key2) =>
                                                <td key={key2} className={`text-center ${day.day === 0 ? 'disabled' : (day.weekday === 6 || day.weekday === 7) ? 'weekend' : ''}`}>
                                                    {day.day !== 0 && <FormItem
                                                        {...formFields[key1 * 7 + key2].row7.props}
                                                        attribute={{ disabled: type === 'view' }}
                                                        onChangeHandle={this.onChangeHandle}
                                                        fields={fields}
                                                    />}
                                                </td>
                                            )}
                                        </tr>
                                    </React.Fragment>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>
        );
    }
}

const mapStateToProps = ({ settings }) => ({ settings });
export default withRouter(connect(mapStateToProps)(CalendarForAdminPage));