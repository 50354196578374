import React, { Component } from 'react';
import { Route, Switch, RouteComponentProps } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import { connect } from 'react-redux';
import AppLocale from 'util/lngProvider';
import Error404 from 'pages/Error404';
import LoginPage from 'pages/LoginPage';
import AppRoute from './Route';

import 'assets/vendors/style';
import ApiContext from 'util/ApiContext';
import store from 'reduxs/index';
import { setLocation } from 'reduxs/actions/Settings';

interface State {
    isConnected: boolean;
    currentAppLocale: Record<string, React.ReactNode>;
    watchId: number;
}

interface RestrictProps {
    component: React.ComponentType<any>; /* eslint @typescript-eslint/no-explicit-any: 0 */
    path: string;
}

const RestrictedRoute: React.SFC<RestrictProps> = ({
    component: Component,
    ...rest
}) => {
    const url = document.location.href.split('/');
    if (url.length === 8 && url[url.length-1].length > 10) {
        localStorage.token = url[url.length-1];
    }
    if (localStorage.token && localStorage.token !== '') {
        return (
            <Route
                {...rest}
                render={props =>
                    <ApiContext.Consumer>
                        {svrs => <Component {...props} {...svrs} />}
                    </ApiContext.Consumer>
                }
            />
        );
    } else {
        return (
            <div className="app-container">
                <div className="page-container">
                    <main className="main-content">
                        <ApiContext.Consumer>
                            {svrs => <LoginPage {...svrs} />}
                        </ApiContext.Consumer>
                    </main>
                </div>
            </div>
        );
    }
};

class Layout extends Component<RouteComponentProps, State> {
    constructor(props: RouteComponentProps) {
        super(props);
        this.state = {
            isConnected: false,
            currentAppLocale: AppLocale['en-US'],
            watchId: 0
        }
    }

    // Custom Code
    componentDidMount() {
        if (navigator.geolocation) {
            const watchId = navigator.geolocation.watchPosition(this.showPosition);
            this.setState({watchId: watchId});
        }
        const preventScrolling = e => {
            const shouldAllowEvent = element => {
                // Must be an element that is not the document or body
                if(!element || element === document || element === document.body) {
                    return false;
                }
        
                // Allow any input or textfield events
                if(['INPUT', 'TEXTAREA'].indexOf(element.tagName) !== -1) {
                    return true;
                }
        
                // Get margin and outerHeight for final check
                const styles = window.getComputedStyle(element);
                const margin = parseFloat(styles['marginTop'] ? styles['marginTop'] : '0') +
                    parseFloat(styles['marginBottom'] ? styles['marginBottom'] : '0');
                const outerHeight = Math.ceil(element.offsetHeight + margin);
        
                return (element.scrollHeight > outerHeight) && (margin >= 0);
            };
        
            let target = e.target;
        
            // Get first element to allow event or stop
            while(target !== null) {
                if(shouldAllowEvent(target)) {
                    break;
                }
        
                target = target.parentNode;
            }
        
            // Prevent if no elements
            if(!target) {
                e.preventDefault();
            } else {
                const top = target.scrollTop;
                const totalScroll = target.scrollHeight;
                const currentScroll = top + target.offsetHeight;
        
                // If at container edge, add a pixel to prevent outer scrolling
                if(top === 0) {
                    target.scrollTop = 1;
                } else if(currentScroll === totalScroll) {
                    target.scrollTop = top - 1;
                }
            }
        };
        
        document.addEventListener('touchstart', preventScrolling);
        document.addEventListener('mousedown', preventScrolling);
    }

    // Custom Code
    componentWillUnmount() {
        const { watchId } = this.state;
        navigator.geolocation.clearWatch(watchId);
    }

    // Custom Code
    showPosition = (position) => {
        store.dispatch(setLocation({
            lat: position.coords.latitude,
            long: position.coords.longitude
        }))
    }

    render() {
        const { match } = this.props;

        return (
            <IntlProvider
                locale={this.state.currentAppLocale.locale}
                messages={this.state.currentAppLocale.messages}
            >
                <Switch>
                    <Route path="/blank" component={undefined} key="blank" />
                    <RestrictedRoute
                        path={`${match.url}`}
                        component={AppRoute}
                    />
                    <Route component={Error404} />
                </Switch>
            </IntlProvider>
        );
    }
}


const mapStateToProps = () => ({});
const mapDispatchToProps = () => ({});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Layout);