import React from 'react';
import { connect } from 'react-redux';
import { PageProps } from 'interfaces';
import { withRouter } from 'react-router-dom';
import * as Routes from 'pages/RouteLoader';
import store from 'reduxs/index';
import { setLoading } from 'reduxs/actions/Settings';
import { DATE_FORMAT, API_EXPORT_EXCEL } from 'constants/Constants';
import { DisplayDateFormat, getTypeName } from 'util/ControlUtils';
import FormItem, { setStateByProperty, checkValidations } from 'components/Form';
import { Field } from 'models/Settings';
import { TargetActualIncome } from 'models/Store';

interface State {
    type: 'reportStore' | 'requestAds' | 'requestPayment' | 'planStore';
    staffId: string;
    date: string;
    filter: {
        staffId: string;
        fromDate: string;
        toDate: string;
    };
    showRejectForm: boolean;
    fields: Field[];
    formData: {
        id: string;
        note: string;
    };
    data?: {
        staffName: string;
        allItems: {
            name: string;
            shortname: string;
        }[];
        allAdses: {
            name: string;
            shortname: string;
        }[];
        rows: {
            id: string;
            storeId: string;
            storename: string;
            streetNumber: string;
            street: string;
            wardName: string;
            districtName: string;
            cityName: string;
            targetActualIncome: TargetActualIncome;
            quantitiesItem: number[];
            quantitiesItemInStock: number[];
            quantitiesAds: number[];
            totalPrice: string;
            isPending: boolean;
            isApproved: boolean;
            isDenied: boolean;
            isUndo: boolean;
            note: string;
            rejectNote: string;
            images: {
                evidence: string;
                document: string;
                inside: string;
                outside: string;
                signature: string;
            };
        }[];
        totalAllQuantityItems: number[];
        totalAllQuantityItemsInStock: number[];
        totalAllQuantityAdses: number[];
        totalAllPrice: string;
    };
}

class ManagerReportDetailPage extends React.Component<PageProps, State> {
    constructor(props: PageProps) {
        super(props);
        const { location: { state } } = props;

        this.state = {
            type: props.match.params.type as 'reportStore' | 'requestAds' | 'requestPayment' | 'planStore',
            staffId: props.match.params.staffId,
            date: props.match.params.date,
            showRejectForm: false,
            formData: {
                id: '',
                note: ''
            },
            fields: [
                {
                    key: "reject.note",
                    isRequired: true,
                    isVisible: true,
                    regex: "",
                    maxLength: 500,
                    name: "Note",
                    isValid: false,
                    isValidType: 'required'
                }
            ],
            filter: {
                fromDate: state && state.fromDate ? state.fromDate : '',
                toDate: state && state.toDate ? state.toDate : '',
                staffId: state && state.staffId ? state.staffId : ''
            }
        }
    }

    componentDidMount = () => {
        this.getData();
    }

    getData = () => {
        const { staffId, date, type } = this.state;

        store.dispatch(setLoading(true));
        this.props.apiService.getManagerReportDetail(staffId, DisplayDateFormat(date, DATE_FORMAT), type).then(res => {
            this.setState({
                data: res
            });
            store.dispatch(setLoading(false));
        })
    }

    // Custom Code
    doExport = () => {
        const { type, date, staffId } = this.state;
        document.location.href = `${API_EXPORT_EXCEL}?type=${type}&staffId=${staffId}&date=${date}&token=${localStorage.token}&page=0&limit=10000`;
    }

    doApprove = (isApprove, id) => {
        if (isApprove) {
            const { type } = this.state;
            this.props.apiService.doApprove(true, id, '', type).then(() => {
                this.getData();
            });
        } else {
            this.setState({
                showRejectForm: true, 
                formData: {note: '', id: id}, 
                fields: [
                    {
                        key: "reject.note",
                        isRequired: true,
                        isVisible: true,
                        regex: "",
                        maxLength: 500,
                        name: "Note",
                        isValid: false,
                        isValidType: 'required'
                    }
                ]});
        }
    }

    doReject = () => {
        const isValid = checkValidations(this);
        if (isValid) {
            const { formData: { note, id }, type } = this.state;
            this.props.apiService.doApprove(false, id, note, type).then(() => {
                this.getData();
                this.setState({showRejectForm: false});
            });
        }
    }

    doUndo = (id) => {
        const { type } = this.state;
        this.props.apiService.doApprove(true, id, '', type, true).then(() => {
            this.getData();
        });
    }

    doCloseRejectForm = () => {
        this.setState({showRejectForm: false});
    }

    // Custom Code
    onChangeHandle = (nameHtml, valueHtml) => {
        switch (nameHtml) {
            case 'reject[note]':
                setStateByProperty(this, 'note', nameHtml, valueHtml);
                break;
            default:
                break;
        }
    }

    goEdit = (storeId, refId) => {
        const { date, filter: { fromDate, toDate }, staffId, type } = this.state;
        const dateParam = date.split(' ')[0].split('/').reverse().join('-');
        this.props.history.push(`/${Routes.ReportStorePageEditRoute.url.replace(':storeId', storeId).replace(':date', dateParam)}`, {returnURLType: 'manager_report_detail', returnURLParams: {date: dateParam, refId: refId, staffId: staffId, fromDate: fromDate, toDate: toDate, reportType: type}});
    }

    // Custom Code
    render() {
        const { settings: { metadata } } = this.props;
        const { type, date, data, filter: { fromDate, toDate, staffId }, showRejectForm, formData, fields } = this.state;
        
        const rejectNote = {
            props: {
                type: 'textarea',
                className: '',
                data: formData.note,
                htmlFor: 'reject[note]',
                fieldName: 'reject[note]',
                attribute: {
                    style: {
                        width: '100%'
                    },
                    rows: 10
                },
                fieldLabel: 'Ghi chú yêu cầu sửa lại'
            },
        };

        return (
            <div className="manager-report-page">
                <div className="sticky-top">
                    <div className="row pt-2">
                        <div className="col-6">{getTypeName(metadata.userInfo)}: {metadata.userInfo.name}</div>
                        <div className="col-6 text-right">Nhóm: {metadata.userInfo.groupName}</div>
                    </div>
                    {type === 'reportStore' && <h3 className="head-title mt-0 mb-0 pt-3">BÁO CÁO HOẠT ĐỘNG NHÀ THUỐC</h3>}
                    {type === 'planStore' && <h3 className="head-title mt-0 mb-0 pt-3">KẾ HOẠCH HOẠT ĐỘNG NHÀ THUỐC</h3>}
                    {type === 'requestAds' && <h3 className="head-title mt-0 mb-0 pt-3">YÊU CẦU THI CÔNG</h3>}
                    {type === 'requestPayment' && <h3 className="head-title mt-0 mb-0 pt-3">YÊU CẦU TRẢ PHÍ QUẢNG CÁO</h3>}
                    {data && <p className="text-center">TDV: <b>{data.staffName}</b> - Ngày: <b>{DisplayDateFormat(date, DATE_FORMAT)}</b></p>}
                    
                </div>

                <div className="holder">
                    {!showRejectForm && <div className="report-table mt-3">
                        {data && <table>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    {(type === 'requestAds' || type ==='requestPayment') && <th></th>}
                                    {(type === 'requestAds' || type ==='requestPayment') && <th></th>}
                                    {(type === 'requestAds' || type ==='requestPayment') && <th></th>}
                                    {(type === 'reportStore' || type === 'requestAds' || type === 'requestPayment') && <th></th>}
                                    {type === 'reportStore' && data.allItems.length && <th colSpan={data.allItems.length} className="text-center"><span>Hàng tồn</span></th>}
                                    {data.allItems.length > 0 && <th colSpan={data.allItems.length} className="text-center"><span>Đặt hàng</span></th>}
                                    {data.allAdses.length > 0 && <th colSpan={data.allAdses.length} className="text-center"><span>Loại vật dụng</span></th>}
                                    {type === 'reportStore' && <th></th>}
                                    {(type === 'reportStore' || type === 'requestAds' || type ==='requestPayment') && <th></th>}
                                    {(type === 'requestAds' || type ==='requestPayment') && <th></th>}
                                </tr>
                                <tr>
                                    <th className="text-center">STT</th>
                                    <th className="text-center sticky-column">Tên NT</th>
                                    <th className="text-center">Số nhà</th>
                                    <th className="text-center">Đường</th>
                                    <th className="text-center">Phường/Xã</th>
                                    <th className="text-center">Quận/Huyện</th>
                                    <th className="text-center">Tỉnh/TP</th>
                                    {(type === 'requestAds' || type ==='requestPayment') && <th className="text-center">Target</th>}
                                    {(type === 'requestAds' || type ==='requestPayment') && <th className="text-center">Kết quả</th>}
                                    {(type === 'requestAds' || type ==='requestPayment') && <th className="text-center">Tỉ lệ đạt</th>}
                                    {type === 'reportStore' && <th className="text-center"><span className="item-name">Thành tiền (VNĐ)</span></th>}
                                    {type === 'requestAds' && <th className="text-center"><span className="item-name">Nội dung thi công</span></th>}
                                    {type === 'requestPayment' && <th className="text-center"><span className="item-name">Phí quảng cáo (VNĐ)</span></th>}
                                    {type === 'reportStore' && data.allItems.map((e, key) =>
                                        <th key={key}><span className="item-name">{e.shortname}</span></th>
                                    )}
                                    {data.allItems.map((e, key) =>
                                        <th key={key}><span className="item-name">{e.shortname}</span></th>
                                    )}
                                    {data.allAdses.map((e, key) =>
                                        <th key={key}><span className="item-name">{e.shortname}</span></th>
                                    )}
                                    {type === 'reportStore' && <th>Ghi chú</th>}
                                    {(type === 'reportStore' || type === 'requestAds' || type ==='requestPayment') && <th></th>}
                                    {(type === 'requestAds' || type ==='requestPayment') && <th></th>}
                                </tr>
                            </thead>
                            <tbody>
                                {data.rows.map((row, key) =>
                                    <tr key={key}>
                                        <td>{(key + 1)}</td>
                                        <td className="no-wrap sticky-column">{row.storename} {type === 'reportStore' && <button className="btn btn-danger" onClick={() => this.goEdit(row.storeId, row.id)}>Sửa</button>}</td>
                                        <td className="no-wrap">{row.streetNumber}</td>
                                        <td className="no-wrap">{row.street}</td>
                                        <td className="no-wrap">{row.wardName}</td>
                                        <td className="no-wrap">{row.districtName}</td>
                                        <td className="no-wrap">{row.cityName}</td>
                                        {(type === 'requestAds' || type ==='requestPayment') && <td className="no-wrap">{row.targetActualIncome.target}</td>}
                                        {(type === 'requestAds' || type ==='requestPayment') && <td className="no-wrap">{row.targetActualIncome.actual}</td>}
                                        {(type === 'requestAds' || type ==='requestPayment') && <td className="no-wrap">{row.targetActualIncome.percent}</td>}
                                        {(type === 'reportStore' || type === 'requestAds' || type === 'requestPayment') && <td className="text-right">
                                            {type === 'reportStore' || type === 'requestPayment' ? row.totalPrice : <div className="long-text-in-table text-left" dangerouslySetInnerHTML={{__html: row.note}}></div>}
                                        </td>}
                                        {type === 'reportStore' && row.quantitiesItemInStock.map((quantity, quantityKey) =>
                                            <td key={quantityKey} className="text-center">{quantity ? quantity : ''}</td>
                                        )}
                                        {row.quantitiesItem.map((quantity, quantityKey) =>
                                            <td key={quantityKey} className="text-center">{quantity ? quantity : ''}</td>
                                        )}
                                        {row.quantitiesAds.map((quantity, quantityKey) =>
                                            <td key={quantityKey} className="text-center">{quantity ? quantity : ''}</td>
                                        )}
                                        {type === 'reportStore' && <td><span className="long-text-in-table">{row.note}</span></td>}
                                        {(type === 'reportStore' || type === 'requestAds' || type ==='requestPayment') && <td>
                                            {row.images.document && <a href={row.images.document} className="block" target="_blank" rel="noopener noreferrer">Hình BBTT</a>}
                                            {row.images.evidence && <a href={row.images.evidence} className="block" target="_blank" rel="noopener noreferrer">Hình NT</a>}
                                            {row.images.inside && <a href={row.images.inside} className="block" target="_blank" rel="noopener noreferrer">Hình trong NT</a>}
                                            {row.images.outside && <a href={row.images.outside} className="block" target="_blank" rel="noopener noreferrer">Hình ngoài NT</a>}
                                            {row.images.signature && <a href={row.images.signature} className="block" target="_blank" rel="noopener noreferrer">Hình chữ ký</a>}
                                            {row.images.signature && <a href="javascript:;" className="block" onClick={() => this.props.history.push(`/${Routes.HistoryDetailForAdminPageRoute.url.replace(':storeId', row.storeId).replace(':id', row.id).replace(':type', 'requestPayment').replace(':token', localStorage.token)}`, {staffId: this.state.staffId, date: this.state.date, fromDate: fromDate, toDate: toDate, staffGroupId: staffId})}>Xem xác nhận NT</a>}
                                        </td>}
                                        {(type === 'requestAds' || type ==='requestPayment') && <td>
                                            {row.isApproved && <>Đã duyệt</>}
                                            {row.isDenied && <>Trả lại TDV<br/><span className="replace-note long-text-in-table">Lý do: {row.rejectNote ? row.rejectNote : '-'}</span></>}
                                            {metadata.userInfo.type === 'manager' ? <>
                                            {row.isPending && <div className="no-wrap"><button className="btn btn-primary" onClick={() => this.doApprove(true, row.id)}>Duyệt</button> <button className="btn btn-danger" onClick={() => this.doApprove(false, row.id)}>Không Duyệt</button></div>}
                                            {row.isUndo && <><br/><button className="btn btn-warning" onClick={() => this.doUndo(row.id)}>Undo</button></>}
                                            </> : row.isPending && <>Chờ duyệt</>}
                                        </td>}
                                    </tr>
                                )}
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td colSpan={(type === 'requestAds' || type ==='requestPayment') ? 10 : 7}><b>TỔNG CỘNG</b></td>
                                    {(type === 'reportStore' || type === 'requestAds' || type === 'requestPayment') && <td className="text-right">
                                        {type === 'reportStore' || type === 'requestPayment' ? <b>{data.totalAllPrice}</b> : ''}
                                    </td>}
                                    {type === 'reportStore' && Object.keys(data.totalAllQuantityItemsInStock).map((k, key) =>
                                        <td key={key} className="text-center"><b>{data.totalAllQuantityItemsInStock[k] ? data.totalAllQuantityItemsInStock[k] : ''}</b></td>
                                    )}
                                    {Object.keys(data.totalAllQuantityItems).map((k, key) =>
                                        <td key={key} className="text-center"><b>{data.totalAllQuantityItems[k] ? data.totalAllQuantityItems[k] : ''}</b></td>
                                    )}
                                    {Object.keys(data.totalAllQuantityAdses).map((k, key) =>
                                        <td key={key} className="text-center"><b>{data.totalAllQuantityAdses[k] ? data.totalAllQuantityAdses[k] : ''}</b></td>
                                    )}
                                    {type === 'reportStore' && <td></td>}
                                    {(type === 'reportStore' || type === 'requestAds' || type ==='requestPayment') && <td></td>}
                                    {(type === 'requestAds' || type ==='requestPayment') && <td></td>}
                                </tr>
                            </tfoot>
                        </table>}
                    </div>}

                    {/* REJECT FORM */}
                    {showRejectForm && <div className="mt-3">
                        <FormItem
                            {...rejectNote.props}
                            onChangeHandle={this.onChangeHandle}
                            fields={fields} />
                    </div>}
                </div>
                <div className="sticky-bottom">
                    {!showRejectForm && <div className="group-btn">
                        <button className="btn btn-dark" onClick={() => this.props.history.push('/' + Routes.HomePageRoute.url)}>Home</button>
                        {fromDate && toDate ? 
                            <button className="btn btn-dark" onClick={() => this.props.history.push('/' + Routes.ManagerReportPageRoute.url.replace(':type', type).replace(':fromDate', fromDate).replace(':toDate', toDate).replace(':staffId', staffId))}>Quay lại</button>
                            : 
                            <button className="btn btn-dark" onClick={() => this.props.history.push('/' + Routes.ManagerReportSearchPageRoute.url.replace(':type', type))}>Quay lại</button>
                        }
                        <button className="btn btn-primary" onClick={() => this.doExport()}>Xuất Excel</button>
                    </div>}

                    {showRejectForm && <div className="group-btn">
                        <button className="btn btn-dark" onClick={() => this.doCloseRejectForm()}>Quay lại</button>
                        <button className="btn btn-danger" onClick={() => this.doReject()}>Trả lại TDV</button>
                    </div>}
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ settings }) => ({ settings });
export default withRouter(connect(mapStateToProps)(ManagerReportDetailPage));