import { Field, Metadata, City, District } from "models/Settings";
import { getIdOrUndefined } from "components/Form";
import * as API from 'constants/Constants';
import store from "reduxs/index";
import { setStore } from "reduxs/actions/Settings";
import Store from 'models/Store';

export const getSearchStoreFields = (isRequired = false) => {
    const fields: Field[] = [
        {
            key: "searchStore.name",
            isRequired: isRequired,
            isVisible: true,
            regex: "",
            maxLength: 50,
            name: "Name"
        },
        {
            key: "searchStore.streetNumber",
            isRequired: isRequired,
            isVisible: true,
            regex: "",
            maxLength: 50,
            name: "Street Number"
        },
        {
            key: "searchStore.street",
            isRequired: isRequired,
            isVisible: true,
            regex: "",
            maxLength: 50,
            name: "Street"
        },
        {
            key: "searchStore.wardName",
            isRequired: isRequired,
            isVisible: true,
            regex: "",
            maxLength: 50,
            name: "Ward Name"
        },
        {
            key: "searchStore.city",
            isRequired: isRequired,
            isVisible: true,
            regex: "",
            maxLength: 50,
            name: "City"
        },
        {
            key: "searchStore.district",
            isRequired: isRequired,
            isVisible: true,
            regex: "",
            maxLength: 50,
            name: "District"
        },
    ];

    return fields;
}

export const getFields = () => {
    const fields: Field[] = [
        ...getSearchStoreFields(true),
        {
            key: "store.isReplace",
            isRequired: false,
            isVisible: true,
            regex: "",
            maxLength: 50,
            name: "Is Replace"
        },
        {
            key: "store.name",
            isRequired: true,
            isVisible: true,
            regex: "",
            maxLength: 50,
            name: "Name"
        },
        {
            key: "store.ownerName",
            isRequired: true,
            isVisible: true,
            regex: "",
            maxLength: 50,
            name: "Owner Name"
        },
        {
            key: "store.phone",
            isRequired: true,
            isVisible: true,
            regex: "",
            maxLength: 50,
            name: "Phone"
        },
        {
            key: "store.pharmacistName",
            isRequired: false,
            isVisible: true,
            regex: "",
            maxLength: 50,
            name: "Pharmacist Name"
        },
        {
            key: "store.streetNumber",
            isRequired: true,
            isVisible: true,
            regex: "",
            maxLength: 50,
            name: "Street Number"
        },
        {
            key: "store.street",
            isRequired: true,
            isVisible: true,
            regex: "",
            maxLength: 50,
            name: "Street"
        },
        {
            key: "store.wardName",
            isRequired: true,
            isVisible: true,
            regex: "",
            maxLength: 50,
            name: "Ward Name"
        },
        {
            key: "store.city",
            isRequired: true,
            isVisible: true,
            regex: "",
            maxLength: 50,
            name: "City"
        },
        {
            key: "store.district",
            isRequired: true,
            isVisible: true,
            regex: "",
            maxLength: 50,
            name: "District"
        },
        {
            key: "store.replaceNote",
            isRequired: false,
            isVisible: true,
            regex: "",
            maxLength: 50,
            name: "Replace Note"
        },
        {
            key: "store.paymentAccountName",
            isRequired: false,
            isVisible: true,
            regex: "",
            maxLength: 50,
            name: "Payment Account Name"
        },
        {
            key: "store.paymentIdentityCard",
            isRequired: false,
            isVisible: true,
            regex: "",
            maxLength: 50,
            name: "Payment Identity Card"
        },
        {
            key: "store.paymentAccountNumber",
            isRequired: false,
            isVisible: true,
            regex: "",
            maxLength: 50,
            name: "Payment Account Number"
        },
        {
            key: "store.paymentBankName",
            isRequired: false,
            isVisible: true,
            regex: "",
            maxLength: 50,
            name: "Payment Bank Name"
        },
    ];

    return fields;
}

export const buildOptionsName = (objects: any) => {
    if (objects && objects.length) {
        store.dispatch(setStore(objects));
        return objects.map((e: Store) => ({value: e.id, label: e.name}));
    }
    return [];
}

export const buildOptionsStreetNumber = (objects: any) => {
    if (objects && objects.length) {
        const options = objects.map((e: Store) => ({value: e.streetNumber, label: e.streetNumber}));
        return options.filter((item, index) => options.findIndex(e => e.value === item.value)  === index);
    }
    return [];
}

export const buildOptionsStreet = (objects: any) => {
    if (objects && objects.length) {
        const options = objects.map((e: Store) => ({value: e.street, label: e.street}));
        return options.filter((item, index) => options.findIndex(e => e.value === item.value)  === index);
    }
    return [];
}

export const buildOptionsWardName = (objects: any) => {
    if (objects && objects.length) {
        const options = objects.map((e: Store) => ({value: e.wardName, label: e.wardName}));
        return options.filter((item, index) => options.findIndex(e => e.value === item.value)  === index);
    }
    return [];
}

export const buildFilterObjectName = (keyword: string) => {
    return `&keyword=${keyword}&type=name`;
}

export const buildFilterObjectStreetNumber = (keyword: string) => {
    return `&keyword=${keyword}&type=streetNumber`;
}

export const buildFilterObjectStreet = (keyword: string) => {
    return `&keyword=${keyword}&type=street`;
}

export const buildFilterObjectWardName = (keyword: string) => {
    return `&keyword=${keyword}&type=wardName`;
}

export const getFormFieldsSearchStore = (formData, metadata: Metadata, stores: Store[] = []) => {
    let selectedCity = metadata.city.find(e => e.id === getIdOrUndefined(formData.city));
    const existStore = stores.find(e => e.id === getIdOrUndefined(formData.store));
    let existCity: City | undefined;
    let existDistrict: District | undefined;
    if (existStore && metadata.city.length) {
        existCity = metadata.city.find(e => e.id === existStore.cityId);
        if (existCity) {
            selectedCity = metadata.city.find(e => e.id === getIdOrUndefined(existCity));
            existDistrict = existCity.district.find(e => e.id === existStore.districtId);
        }
    }
    const formFields = [
        {
            props: {
                type: 'select',
                htmlFor: 'searchStore[name]',
                fieldName: 'searchStore[name]',
                data: getIdOrUndefined(formData.store),
                fieldLabel: 'Tên NT',
                advanceOptions: {
                    apiEndpoint: API.API_SEARCH_STORES + `?storeId=${getIdOrUndefined(formData.store)}&street=${formData.street}&wardName=${formData.wardName}&districtId=${getIdOrUndefined(formData.district)}&cityId=${getIdOrUndefined(formData.city)}`,
                    buildOptions: buildOptionsName,
                    buildFilterObject: buildFilterObjectName,
                    pageSize: 300
                },
                attribute: {
                    skipValidation: !!existStore
                }
            },
        },
        {
            props: {
                type: 'select',
                htmlFor: 'searchStore[streetNumber]',
                fieldName: 'searchStore[streetNumber]',
                data: existStore ? existStore.streetNumber : (formData.streetNumber ? formData.streetNumber : undefined),
                fieldLabel: 'Số nhà',
                advanceOptions: {
                    apiEndpoint: API.API_SEARCH_STORES + `?storeId=${getIdOrUndefined(formData.store)}&street=${formData.street}&wardName=${formData.wardName}&districtId=${getIdOrUndefined(formData.district)}&cityId=${getIdOrUndefined(formData.city)}`,
                    buildOptions: buildOptionsStreetNumber,
                    buildFilterObject: buildFilterObjectStreetNumber,
                    pageSize: 300
                },
                attribute: {
                    disabled: !!existStore,
                    skipValidation: !!existStore
                }
            },
        },
        {
            props: {
                type: 'select',
                htmlFor: 'searchStore[street]',
                fieldName: 'searchStore[street]',
                data: existStore ? existStore.street : (formData.street ? formData.street : undefined),
                fieldLabel: 'Đường',
                advanceOptions: {
                    apiEndpoint: API.API_SEARCH_STORES + `?storeId=${getIdOrUndefined(formData.store)}&wardName=${formData.wardName}&districtId=${getIdOrUndefined(formData.district)}&cityId=${getIdOrUndefined(formData.city)}`,
                    buildOptions: buildOptionsStreet,
                    buildFilterObject: buildFilterObjectStreet,
                    pageSize: 300
                },
                attribute: {
                    disabled: !!existStore,
                    skipValidation: !!existStore
                }
            },
        },
        {
            props: {
                type: 'select',
                htmlFor: 'searchStore[wardName]',
                fieldName: 'searchStore[wardName]',
                data: existStore ? existStore.wardName : (formData.wardName ? formData.wardName : undefined),
                fieldLabel: 'Phường / Xã',
                advanceOptions: {
                    apiEndpoint: API.API_SEARCH_STORES + `?storeId=${getIdOrUndefined(formData.store)}&districtId=${getIdOrUndefined(formData.district)}&cityId=${getIdOrUndefined(formData.city)}`,
                    buildOptions: buildOptionsWardName,
                    buildFilterObject: buildFilterObjectWardName,
                    pageSize: 300
                },
                attribute: {
                    disabled: !!existStore,
                    skipValidation: !!existStore
                }
            },
        },
        {
            props: {
                type: 'select',
                htmlFor: 'searchStore[district]',
                fieldName: 'searchStore[district]',
                data: existDistrict ? existDistrict.id : (metadata.city.length ? getIdOrUndefined(formData.district) : undefined),
                fieldLabel: 'Quận / Huyện',
                options: selectedCity ? selectedCity.district.map((e: District) => ({value: e.id, label: e.name})) : [],
                attribute: {
                    disabled: !!existDistrict,
                    skipValidation: !!existDistrict
                }
            },
        },
        {
            props: {
                type: 'select',
                htmlFor: 'searchStore[city]',
                fieldName: 'searchStore[city]',
                data: existCity ? existCity.id : (metadata.city.length ? getIdOrUndefined(formData.city) : undefined),
                fieldLabel: 'TP / Tỉnh',
                options: metadata.city.map((e: City) => ({value: e.id, label: e.name})),
                attribute: {
                    disabled: !!existCity,
                    skipValidation: !!existCity
                }
            },
        }
    ];

    return formFields;
}

export const getFormFieldsOther = (formData, metadata: Metadata) => {
    const selectedCity = metadata.city.find(e => e.id === getIdOrUndefined(formData.city));

    const storeFields = [
        {
            props: {
                type: 'text',
                data: formData.name,
                htmlFor: 'store[name]',
                fieldName: 'store[name]',
                className: 'mb-2',
                fieldLabel: 'Tên nhà thuốc' + (formData.isReplace ? ' mới' : ''),
                attribute: {
                    placeholder: ''
                }
            },
        },
    ];

    const noteFields = [
        {
            props: {
                type: 'textarea',
                data: formData.replaceNote,
                htmlFor: 'store[replaceNote]',
                fieldName: 'store[replaceNote]',
                className: 'mb-2',
                fieldLabel: 'Lý do thay thế *'
            },
        }
    ];

    const geoFields = [
        {
            props: {
                type: 'text',
                data: formData.streetNumber,
                htmlFor: 'store[streetNumber]',
                fieldName: 'store[streetNumber]',
                className: 'mb-2',
                fieldLabel: 'Số nhà' + (formData.isReplace ? ' mới' : ''),
                attribute: {
                    placeholder: ''
                }
            },
        },
        {
            props: {
                type: 'tag',
                htmlFor: 'store[street]',
                fieldName: 'store[street]',
                className: 'select-custom mb-2',
                data: formData.street ? formData.street : undefined,
                fieldLabel: 'Đường' + (formData.isReplace ? ' mới' : ''),
                advanceOptions: {
                    apiEndpoint: API.API_SEARCH_STORES + `?storeId=${getIdOrUndefined(formData.store)}&wardName=${formData.wardName}&districtId=${getIdOrUndefined(formData.district)}&cityId=${getIdOrUndefined(formData.city)}`,
                    buildOptions: buildOptionsStreet,
                    buildFilterObject: buildFilterObjectStreet,
                    pageSize: 300
                },
                selectAllLabel: '- Chọn -'
            },
        },
        {
            props: {
                type: 'tag',
                htmlFor: 'store[wardName]',
                fieldName: 'store[wardName]',
                className: 'select-custom mb-2',
                data: formData.wardName ? formData.wardName : undefined,
                fieldLabel: 'Phường / Xã' + (formData.isReplace ? ' mới' : ''),
                advanceOptions: {
                    apiEndpoint: API.API_SEARCH_STORES + `?storeId=${getIdOrUndefined(formData.store)}&districtId=${getIdOrUndefined(formData.district)}&cityId=${getIdOrUndefined(formData.city)}`,
                    buildOptions: buildOptionsWardName,
                    buildFilterObject: buildFilterObjectWardName,
                    pageSize: 300
                },
                selectAllLabel: '- Chọn -'
            },
        },
        {
            props: {
                type: 'select',
                htmlFor: 'store[district]',
                fieldName: 'store[district]',
                className: 'select-custom mb-2',
                data: metadata.city.length ? getIdOrUndefined(formData.district) : undefined,
                fieldLabel: 'Quận / Huyện' + (formData.isReplace ? ' mới' : ''),
                options: selectedCity ? selectedCity.district.map((e: District) => ({ value: e.id, label: e.name })) : [],
                selectAllLabel: '- Chọn -'
            },
        },
        {
            props: {
                type: 'select',
                htmlFor: 'store[city]',
                fieldName: 'store[city]',
                className: 'select-custom mb-2',
                data: metadata.city.length ? getIdOrUndefined(formData.city) : undefined,
                fieldLabel: 'TP / Tỉnh' + (formData.isReplace ? ' mới' : ''),
                options: metadata.city.map((e: City) => ({ value: e.id, label: e.name })),
                selectAllLabel: '- Chọn -'
            },
        },
    ];

    const formFields = [
        {
            props: {
                type: 'text',
                data: formData.pharmacistName,
                htmlFor: 'store[pharmacistName]',
                fieldName: 'store[pharmacistName]',
                className: 'mb-2',
                fieldLabel: 'Tên dược sĩ'
            },
        },
        {
            props: {
                type: 'text',
                data: formData.ownerName,
                htmlFor: 'store[ownerName]',
                fieldName: 'store[ownerName]',
                className: 'mb-2',
                fieldLabel: 'Tên chủ nhà thuốc'
            },
        },
        {
            props: {
                type: 'text',
                data: formData.phone,
                htmlFor: 'store[phone]',
                fieldName: 'store[phone]',
                className: 'mb-2',
                fieldLabel: 'Số ĐTDĐ chủ nhà thuốc'
            },
        },
        {
            props: {
                type: 'text',
                data: formData.paymentAccountName,
                htmlFor: 'store[paymentAccountName]',
                fieldName: 'store[paymentAccountName]',
                className: 'mb-2',
                fieldLabel: 'Người hưởng phí quảng cáo'
            },
        },
        {
            props: {
                type: 'text',
                data: formData.paymentIdentityCard,
                htmlFor: 'store[paymentIdentityCard]',
                fieldName: 'store[paymentIdentityCard]',
                className: 'mb-2',
                fieldLabel: 'CMND người hưởng phí quảng cáo'
            },
        },
        {
            props: {
                type: 'text',
                data: formData.paymentAccountNumber,
                htmlFor: 'store[paymentAccountNumber]',
                fieldName: 'store[paymentAccountNumber]',
                className: 'mb-2',
                fieldLabel: 'Số tài khoản ngân hàng'
            },
        },
        {
            props: {
                type: 'text',
                data: formData.paymentBankName,
                htmlFor: 'store[paymentBankName]',
                fieldName: 'store[paymentBankName]',
                className: 'mb-2',
                fieldLabel: 'Ngân hàng'
            },
        },
    ];

    return formData.isReplace ? [...storeFields, ...geoFields, ...noteFields, ...formFields] : [...storeFields, ...geoFields, ...formFields];
}