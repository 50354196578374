export class StaffCalendar {
    fullname = '';
    note = '';
}

export class SaleTarget {
    targetPrice = '';
    planPrice = '';
    resultPrice = '';
    remainPrice = '';
}

export default class Calendar {
    date = '';
    place = '';
    note = '';
    visited = 0;
    saleItemCount = 0;
    saleAdsCount = 0;
    revenueReport = 0;
    revenuePlan = 0;
    staffCalendars: StaffCalendar[] = [];
}