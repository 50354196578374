import React from 'react';
import { connect } from 'react-redux';
import { PageProps } from 'interfaces';
import { withRouter } from 'react-router-dom';
import * as Routes from 'pages/RouteLoader';
import store from 'reduxs/index';
import { setLoading } from 'reduxs/actions/Settings';
import { DATE_FORMAT, API_EXPORT_EXCEL } from 'constants/Constants';
import { DisplayDateFormat, formatMoney, getTypeName } from 'util/ControlUtils';
import { TargetActualIncome } from 'models/Store';

interface Data {
    districtName: string;
    allItems: {
        name: string;
        shortname: string;
    }[];
    allAdses: {
        name: string;
        shortname: string;
    }[];
    allAdsesRequestPayment: {
        name: string;
        shortname: string;
    }[];
    rows: {
        storeId: string;
        storename: string;
        streetNumber: string;
        street: string;
        wardName: string;
        districtName: string;
        cityName: string;
        phone: string;
        ownerName: string;
        visitedCount: number;
        inStockDate: string;
        targetActualIncome: TargetActualIncome;
        quantities: number[];
        quantitiesAds: number[];
        quantitiesRequestPayment: number[];
        quantitiesInStock: number[];
        totalPrice: string;
        evidenceImages: string;
    }[];
    totalAllQuantity: number[];
    totalAllQuantityAds: number[];
    totalAllQuantityRequestPayment: number[];
    totalAllQuantityInStock: number[];
    totalAllPrice: number;
    totalAllVisited: number;
}

interface State {
    cityId: string;
    districtId: string;
    staffId: string;
    fromDate: string;
    toDate: string;
    page: number;
    data?: Data;
}

class ManagerStoreDetailPage extends React.Component<PageProps, State> {
    constructor(props: PageProps) {
        super(props);

        this.state = {
            cityId: props.match.params.cityId,
            districtId: props.match.params.districtId,
            staffId: props.match.params.staffId,
            fromDate: props.match.params.fromDate,
            toDate: props.match.params.toDate,
            page: 0
        }
    }

    componentDidMount = () => {
        this.getData();
    }

    mergeData = (res: Data) => {
        let { data, page } = this.state;
        if (page === 0) {
            return res;
        } else if (data) {
            data.rows = [...data.rows, ...res.rows];
            data.totalAllPrice += res.totalAllPrice;
            data.totalAllVisited += res.totalAllVisited;
            data.totalAllQuantity = data.totalAllQuantity.map((e, i) => e + res.totalAllQuantity[i]);
            data.totalAllQuantityAds = data.totalAllQuantityAds.map((e, i) => e + res.totalAllQuantityAds[i]);
            return data;
        } else {
            return data;
        }
    }

    getData = () => {
        const { cityId, districtId, staffId, fromDate, toDate, page } = this.state;
        const { settings: { role } } = this.props;

        page === 0 && store.dispatch(setLoading(true));
        const getStoreDetail = role === 'staff' ? 
            this.props.apiService.getStaffStoreDetail(cityId, districtId, DisplayDateFormat(fromDate, DATE_FORMAT), DisplayDateFormat(toDate, DATE_FORMAT), page) :
            this.props.apiService.getManagerStoreDetail(staffId, DisplayDateFormat(fromDate, DATE_FORMAT), DisplayDateFormat(toDate, DATE_FORMAT), page);
        
        getStoreDetail.then(res => {
            if (res && res.rows && res.rows.length) {
                this.setState(prevState => ({
                    page: prevState.page + 1,
                    data: this.mergeData(res)
                }), () => {
                    this.getData();
                })
            } else if (page === 0) {
                this.setState({
                    data: res
                });
            }
            store.dispatch(setLoading(false));
        })
    }

    // Custom Code
    render() {
        const { settings: { metadata, role } } = this.props;
        const { cityId, districtId, staffId, fromDate, toDate, data } = this.state;
        let removeKeys: number[] = [];
        if (data) {
            data.allAdsesRequestPayment.forEach((e, key) => {
                const existData = data.allAdses.find(f => f.name === e.name && f.shortname === e.shortname);
                if (existData) {
                    removeKeys.push(key);
                }
            });
        }

        const period = data && data.rows.length ? `quý ${data.rows[0].targetActualIncome.period}` : ''; 

        return (
            <div className="manager-report-page">
                <div className="sticky-top">
                    <div className="row pt-2">
                        <div className="col-6">{role === 'staff' ? 'TDV' : getTypeName(metadata.userInfo)}: {metadata.userInfo.name}</div>
                        <div className="col-6 text-right">Nhóm: {metadata.userInfo.groupName}</div>
                    </div>
                    <h3 className="head-title mt-0 mb-0 pt-3">THÔNG TIN NHÀ THUỐC</h3>
                    {data && <p className="text-center">{role === 'staff' ? 'Khu vực' : 'TDV'}: <b>{data.districtName}</b><br/>Từ ngày: <b>{DisplayDateFormat(fromDate, DATE_FORMAT)}</b> đến ngày: <b>{DisplayDateFormat(toDate, DATE_FORMAT)}</b></p>}
                    
                </div>

                <div className="holder">
                    <div className="report-table mt-3">
                        {data && <table>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th colSpan={data.allItems.length} className="text-center"><span>Doanh số</span></th>
                                    <th></th>
                                    <th></th>
                                    <th colSpan={data.allItems.length} className="text-center"><span>Hàng tồn</span></th>
                                    <th colSpan={data.allAdses.length + data.allAdsesRequestPayment.length - removeKeys.length} className="text-center"><span>Vật dụng quảng cáo</span></th>
                                    <th></th>
                                </tr>
                                <tr>
                                    <th className="text-center">STT</th>
                                    <th className="text-center sticky-column">Tên NT</th>
                                    <th className="text-center">Số nhà</th>
                                    <th className="text-center">Đường</th>
                                    <th className="text-center">Phường/Xã</th>
                                    <th className="text-center">Quận/Huyện</th>
                                    <th className="text-center">Tỉnh/TP</th>
                                    <th className="text-center">Tên chủ NT</th>
                                    <th className="text-center">Số ĐT</th>
                                    <th className="text-center">Target {period}</th>
                                    <th className="text-center">Kết quả {period}</th>
                                    <th className="text-center">Số lần viếng thăm</th>
                                    {data.allItems.map((e, key) =>
                                        <th key={key}><span className="item-name">{e.shortname}</span></th>
                                    )}
                                    <th className="text-center"><span className="item-name">Thành tiền (VNĐ)</span></th>
                                    <th className="text-center"><span className="item-name">Ngày tồn kho</span></th>
                                    {data.allItems.map((e, key) =>
                                        <th key={key}><span className="item-name">{e.shortname}</span></th>
                                    )}
                                    {data.allAdses.map((e, key) =>
                                        <th key={key}><span className="item-name">{e.shortname}</span></th>
                                    )}
                                    {data.allAdsesRequestPayment.map((e, key) =>
                                        !removeKeys.find(e => e === key) && <th key={key}><span className="item-name">{e.shortname}</span></th>
                                    )}
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.rows.map((row, key) =>
                                    <tr key={key}>
                                        <td>{(key + 1)}</td>
                                        <td className="no-wrap sticky-column">{row.storename}</td>
                                        <td className="no-wrap">{row.streetNumber}</td>
                                        <td className="no-wrap">{row.street}</td>
                                        <td className="no-wrap">{row.wardName}</td>
                                        <td className="no-wrap">{row.districtName}</td>
                                        <td className="no-wrap">{row.cityName}</td>
                                        <td className="no-wrap">{row.ownerName}</td>
                                        <td className="no-wrap">{row.phone}</td>
                                        <td className="no-wrap">{row.targetActualIncome.target}</td>
                                        <td className="no-wrap">{row.targetActualIncome.actual}</td>
                                        <td className="text-center">{row.visitedCount}</td>
                                        {row.quantities.map((quantity, quantityKey) =>
                                            <td key={quantityKey} className="text-center">{quantity ? quantity : ''}</td>
                                        )}
                                        <td className="text-right">{row.totalPrice}</td>
                                        <td className="text-center"><span className="no-wrap">{row.inStockDate}</span></td>
                                        {row.quantitiesInStock.map((quantity, quantityKey) =>
                                            <td key={quantityKey} className="text-center">{quantity ? quantity : ''}</td>
                                        )}
                                        {row.quantitiesAds.map((quantity, quantityKey) =>
                                            <td key={quantityKey} className="text-center">{quantity ? quantity : ''}</td>
                                        )}
                                        {row.quantitiesRequestPayment.map((quantity, quantityKey) =>
                                            !removeKeys.find(e => e === quantityKey) && <td key={quantityKey} className="text-center">{quantity ? quantity : ''}</td>
                                        )}
                                        <td>{row.evidenceImages && <a href={row.evidenceImages} className="block" target="_blank" rel="noopener noreferrer">Xem hình</a>}</td>
                                    </tr>
                                )}
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td colSpan={11}><b>TỔNG CỘNG</b></td>
                                    <td className="text-center"><b>{formatMoney(data.totalAllVisited, 0)}</b></td>
                                    {Object.keys(data.totalAllQuantity).map((k, key) =>
                                        <td key={key} className="text-center"><b>{data.totalAllQuantity[k] ? data.totalAllQuantity[k] : ''}</b></td>
                                    )}
                                    <td className="text-right"><b>{formatMoney(data.totalAllPrice, 0)}đ</b></td>
                                    <td></td>
                                    {Object.keys(data.totalAllQuantityInStock).map((k, key) =>
                                        <td key={key} className="text-center"><b>{data.totalAllQuantityInStock[k] ? data.totalAllQuantityInStock[k] : ''}</b></td>
                                    )}
                                    {Object.keys(data.totalAllQuantityAds).map((k, key) =>
                                        <td key={key} className="text-center"><b>{data.totalAllQuantityAds[k] ? data.totalAllQuantityAds[k] : ''}</b></td>
                                    )}
                                    {Object.keys(data.totalAllQuantityRequestPayment).map((k, key) =>
                                        !removeKeys.find(e => e === key) && <td key={key} className="text-center"><b>{data.totalAllQuantityRequestPayment[k] ? data.totalAllQuantityRequestPayment[k] : ''}</b></td>
                                    )}
                                    <td></td>
                                </tr>
                            </tfoot>
                        </table>}
                    </div>
                    
                </div>
                <div className="sticky-bottom">
                    <div className="group-btn">
                        <button className="btn btn-dark" onClick={() => this.props.history.push('/' + Routes.HomePageRoute.url)}>Home</button>
                        <button className="btn btn-dark" onClick={() => this.props.history.push('/' + Routes.ManagerStoreSearchPageRoute.url, {cityId: cityId, districtId: districtId, staffId: staffId, fromDate: fromDate, toDate: toDate})}>Quay lại</button>
                        {role === 'staff' ? 
                            <a href={`${API_EXPORT_EXCEL}?cityId=${cityId}&districtId=${districtId}&fromDate=${fromDate}&toDate=${toDate}&type=reportStoreByFilter&token=${localStorage.token}&page=0&limit=10000`} target="_blank" rel="noopener noreferrer" className="btn btn-primary">Xuất Excel</a> :
                            <a href={`${API_EXPORT_EXCEL}?staffId=${staffId}&fromDate=${fromDate}&toDate=${toDate}&type=reportStoreByFilter&token=${localStorage.token}&page=0&limit=10000`} target="_blank" rel="noopener noreferrer" className="btn btn-primary">Xuất Excel</a>
                        }
                        
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ settings }) => ({ settings });
export default withRouter(connect(mapStateToProps)(ManagerStoreDetailPage));