import { Field } from "models/Settings";

export const getFields = () => {
    const fields: Field[] = [
        {
            key: "filter.fromDate",
            isRequired: false,
            isVisible: true,
            regex: "",
            maxLength: 50,
            name: "From Date"
        },
        {
            key: "filter.toDate",
            isRequired: false,
            isVisible: true,
            regex: "",
            maxLength: 50,
            name: "To Date"
        },
    ];

    return fields;
}

export const getFormFields = (formData) => {
    const formFields = [
        {
            props: {
                type: 'calendar',
                htmlFor: 'filter[fromDate]',
                fieldName: 'filter[fromDate]',
                className: '',
                datePickerData: formData.fromDate
                    ? new Date(formData.fromDate)
                    : undefined,
                fieldLabel: 'Từ ngày'
            },
            className: ''
        },
        {
            props: {
                type: 'calendar',
                htmlFor: 'filter[toDate]',
                fieldName: 'filter[toDate]',
                className: '',
                datePickerData: formData.toDate
                    ? new Date(formData.toDate)
                    : undefined,
                fieldLabel: 'Đến ngày'
            },
            className: ''
        },
    ];

    return formFields;
}