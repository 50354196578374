import React from 'react';
import { connect } from 'react-redux';
import { PageProps } from 'interfaces';
import { withRouter } from 'react-router-dom';
import { getFields, getFormFields } from './fields';
import { Field, Staff, District, City } from 'models/Settings';
import * as Routes from 'pages/RouteLoader';
import FormItem, { setStateByProperty, checkValidations, getIdOrZero } from 'components/Form';
import { DisplayDateFormat, getTypeName } from 'util/ControlUtils';
import { DATE_FORMAT_FOR_DATABASE } from 'constants/Constants';
import store from 'reduxs/index';
import { setLoading } from 'reduxs/actions/Settings';
interface State {
    fields: Field[];
    cities: City[];
    formData: {
        city: City;
        district: District;
        staff: Staff;
        fromDate: string;
        toDate: string;
    };
}

class ManagerStoreSearchPage extends React.Component<PageProps, State> {
    constructor(props: PageProps) {
        super(props);

        const { location: { state } } = props;

        let fields = getFields();
        fields = fields.map(e => ({
            ...new Field(), ...e,
            isValid: state ? true : false,
            isValidType: state ? 'none' : 'required'
        }));

        this.state = {
            fields: fields,
            formData: {
                city: {...new City(), id: state && state.cityId ? state.cityId : ''},
                district: {...new District(), id: state && state.districtId ? state.districtId : ''},
                staff: {...new Staff(), id: state && state.staffId ? state.staffId : ''},
                fromDate: state && state.fromDate ? state.fromDate : '',
                toDate: state && state.toDate ? state.toDate : ''
            },
            cities: []
        }
    }

    componentDidMount = () => {
        store.dispatch(setLoading(true));
        this.props.apiService.getDistrictsOfStoresOfStaffUser().then(res => {
            this.setState({cities: res});
            store.dispatch(setLoading(false));
        })
    }
    
    // Custom Code
    onChangeHandle = (nameHtml, valueHtml) => {
        switch (nameHtml) {
            case 'filter[fromDate]':
                let fromDate = '';
                if (!isNaN(Date.parse(valueHtml))) {
                    fromDate = DisplayDateFormat(valueHtml, DATE_FORMAT_FOR_DATABASE);
                    if (isNaN(Date.parse(fromDate))) {
                        fromDate = '';
                    }
                }
                setStateByProperty(this, 'fromDate', nameHtml, fromDate);
                break;
            case 'filter[toDate]':
                let toDate = '';
                if (!isNaN(Date.parse(valueHtml))) {
                    toDate = DisplayDateFormat(valueHtml, DATE_FORMAT_FOR_DATABASE);
                    if (isNaN(Date.parse(toDate))) {
                        toDate = '';
                    }
                }
                setStateByProperty(this, 'toDate', nameHtml, toDate);
                break;
            case 'filter[city]':
                setStateByProperty(this, 'city', nameHtml, { id: valueHtml, name: '' });
                setStateByProperty(this, 'district', 'filter[district]', { id: '', name: '' });
                break;
            case 'filter[district]':
                setStateByProperty(this, 'district', nameHtml, { id: valueHtml, name: '' });
                break;
            case 'filter[staff]':
                setStateByProperty(this, 'staff', nameHtml, { id: valueHtml, name: '' });
                break;
            default:
                break;
        }
    }

    // Custom Code
    onSubmit = () => {
        const isValid = checkValidations(this);
        const { formData: { fromDate, toDate, staff, city, district } } = this.state;
        const { settings: { role } } = this.props;

        if (isValid) {
            if (role === 'staff') {
                this.props.history.push('/' + Routes.StaffStoreDetailPageRoute.url.replace(':fromDate', fromDate).replace(':toDate', toDate).replace(':cityId', getIdOrZero(city)).replace(':districtId', getIdOrZero(district)));
            } else {
                this.props.history.push('/' + Routes.ManagerStoreDetailPageRoute.url.replace(':fromDate', fromDate).replace(':toDate', toDate).replace(':staffId', getIdOrZero(staff)));
            }
        }
    }
 
    // Custom Code
    render() {
        const { settings, settings: { metadata, role } } = this.props;
        const { fields, formData, cities } = this.state;

        const formFields = getFormFields(formData, cities, settings);

        return (
            <div className="manager-report-search-page">
                <div className="row pt-2">
                    <div className="col-6">{role === 'staff' ? 'TDV' : getTypeName(metadata.userInfo)}: {metadata.userInfo.name}</div>
                    <div className="col-6 text-right">Nhóm: {metadata.userInfo.groupName}</div>
                </div>

                <div className="holder">
                    <h3 className="head-title mt-0 mb-0 pt-3">THÔNG TIN NHÀ THUỐC</h3>
                    <div className="item-ads row">
                        <div className="mt-3 col-12">
                            {formFields.map((field, key) =>
                                <React.Fragment key={key}>
                                    <FormItem
                                        {...field.props}
                                        onChangeHandle={this.onChangeHandle}
                                        fields={fields}
                                    />
                                </React.Fragment>
                            )}
                        </div>
                    </div>

                    <div className="group-btn">
                        <button className="btn btn-dark" onClick={() => this.props.history.push('/' + Routes.HomePageRoute.url)}>Home</button>
                        <button className="btn btn-primary" onClick={() => this.onSubmit()}>Xem báo cáo</button>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ settings }) => ({ settings });
export default withRouter(connect(mapStateToProps)(ManagerStoreSearchPage));