import moment from 'moment';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { UserInfo } from 'models/Settings';

export function capitalize(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export function getTypeName(userInfo: UserInfo) {
    if (userInfo.type === 'manager') {
        return 'Manager';
    } else if (userInfo.type === 'leader') {
        return 'Leader';
    } else if (userInfo.type === 'general_manager') {
        return 'Deputy';
    }
    return 'Manager';
}

export function formatMoney(number, decPlaces) {
    return (number).toFixed(decPlaces).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');  // 12,345.67
}

export function urltoFile(urls: string[]){
    let pm: Promise<File>[] = [];
    urls.forEach(url => {
        const filename = url.split('/').pop();
        const imgExt = (filename || '').split('.').pop();
        let mimeType = 'image/png';
        if (imgExt && imgExt.toLowerCase() !== 'png') {
            mimeType = 'image/jpeg';
        }
        pm.push(fetch(url)
            .then(function(res){return res.arrayBuffer();})
            .then(function(buf){return new File([buf], (filename || ''),{type:mimeType});})
        );
    });
    return Promise.all(pm);
}

export function randomPassword() {
    const chars = 'ABCDEFGHIJKLMNOP';
    const lowerChars = 'abcdefghijklmnopqrstuvwxyz';
    const speacialChars = '!@#$%^&*()-+<>';
    const number = '1234567890';
    let password = '';
    for (let i = 0; i < 2; i++) {
        const j = Math.floor(Math.random() * chars.length);
        const k = Math.floor(Math.random() * speacialChars.length);
        const l = Math.floor(Math.random() * lowerChars.length);
        const m = Math.floor(Math.random() * number.length);
        password +=
      chars.charAt(j) +
      speacialChars.charAt(k) +
      lowerChars.charAt(l) +
      number.charAt(m);
    }
    return password;
}

export function transformOptions(data: { id: string; name: string }[]) {
    return data.map(item => {
        return {
            value: item.id,
            label: item.name,
            ...item
        };
    });
}

export function isValidPassword(password: string) {
    const format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
    return (
        format.test(password) && /[a-z]/.test(password) && /[A-Z]/.test(password)
    );
}

export function IsValidEmail(emailAddress) {
    return /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        emailAddress
    );
}

// Eg: XXX-XXX-XXXX
// Eg: XXX.XXX.XXXX
// Eg: XXX XXX XXXX
export function IsValidPhoneNumber(phoneNumber) {
    phoneNumber = phoneNumber.split(' ').join('');
    return /^\d{10}$/.test(phoneNumber);
}

export function IsValidPhoneNumberWithCountryCode(phoneNumber) {    
    return /^\+?[0-9]*$/.test(phoneNumber);
}

export function chunk(arr, chunkSize) {
    var R: any[] = [];
    for (var i = 0, len = arr.length; i < len; i += chunkSize)
        R.push(arr.slice(i, i + chunkSize));
    return R;
}

export function interpret(str) {
    let idx = 0;
    while (str[idx + 1] != '[') {
        idx++;
    }
    let name = str.substr(0, idx + 1);

    let indices: any[] = [];
    while (str[idx + 1] == '[') {
        idx++;
        let startIdx = idx;
        while (str[idx + 1] != ']') {
            idx++;
        }
        indices.push(str.substr(startIdx + 1, idx - startIdx));
        idx++;
    }

    return { name, indices };
}

export function removeDuplicatedObj(arr) {
    return arr.reduce((unique, o) => {
        if (!unique.some(obj => obj.label === o.label && obj.value === o.value)) {
            unique.push(o);
        }
        return unique;
    }, []);
}

// Eg. +XX-XXXX-XXXX
// Eg. +XX.XXXX.XXXX
// Eg. +XX XXXX XXXX
export function IsValidSignPhoneNumber(phoneNumber) {
    phoneNumber = phoneNumber.split(' ').join('');
    return /^\+?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/.test(phoneNumber);
}

// Eg. MM/YY
export function IsValidCreditCardExpiryDate(creditCardNumber) {
    return /^\(?([0-9]{2})\)?[/]?([0-9]{2})$/.test(creditCardNumber);
}

// Eg. 38980
// Eg. 83900-8789
export function IsValidPostCode(postCode) {
    return /^\d{5}$|^\d{5}-\d{4}$/.test(postCode);
}

// Eg. XXX
export function IsValidCvcOrCvv(cvcOrCvv) {
    return /^[0-9]{3}$/.test(cvcOrCvv);
}

// Eg. Visa 	4111111111111111
// Eg. MasterCard 	5500000000000004
// Eg. American Express 	340000000000009
export function IsValidCreditCardNumber(cardNumber, cardType) {
    cardNumber = cardNumber.split(' ').join('');
    const visaRegEx = /^(?:4[0-9]{12}(?:[0-9]{3})?)$/;
    const mastercardRegEx = /^(?:5[1-5][0-9]{14})$/;
    const amexpRegEx = /^(?:3[47][0-9]{13})$/;
    let isValid = false;

    if (
        (cardType === 'Visa' || cardType === 'Visa Debit') &&
    visaRegEx.test(cardNumber)
    ) {
        isValid = true;
    } else if (
        (cardType === 'MasterCard' || cardType === 'Master Debit') &&
    mastercardRegEx.test(cardNumber)
    ) {
        isValid = true;
    } else if (cardType === 'AMEX' && amexpRegEx.test(cardNumber)) {
        isValid = true;
    }

    return isValid;
}

// Eg. 2345643.00 will return 2,345,643.00
export function CommaFormatted(amount) {
    return amount.toLocaleString(navigator.language, {
        minimumFractionDigits: 2
    });
}

export function GetFullName(firstName, lastName) {
    return `${firstName} ${lastName}`;
}

export function DisplayCardNumber(cardNumber) {
    return cardNumber !== ''
        ? `****${cardNumber.substring(cardNumber.length - 4, cardNumber.length)}`
        : '';
}

export function ConvertDateFormatForDatePicker(dateFormat) {
    switch (dateFormat) {
        case 'dd/mm/yyyy':
            return 'dd/MM/yyyy';
        case 'mm/dd/yyyy':
            return 'MM/dd/yyyy';
        default:
            return dateFormat;
    }
}

export function ConvertDateForSelectedDatePicker(date) {
    return moment(date).toDate();
}

export function DisplayDateFormat(date, dateFormat) {
    return moment(date).format(dateFormat.toUpperCase());
}

export function DisplayDateTimeFormat(date, dateFormat) {
    return moment(date).format(dateFormat);
}

export function CompareTwoDates(newDate, oldDate, dateFormat) {
    return (
        DisplayDateFormat(newDate, dateFormat) ===
    DisplayDateFormat(oldDate, dateFormat)
    );
}

export function IsValidDateOfBirth(dateOfBirth) {
    return moment(dateOfBirth).isSameOrBefore(new Date(), 'day');
}

export function IsValidStartDate(startDate) {
    return moment(startDate).isSameOrAfter(new Date(), 'day');
}

export function ConvertDateFormat(date) {
    return moment(date)
        .utc()
        .toDate()
        .toISOString();
}

export function IsValidFormatDate(dateString, dateFormat) {
    if (dateString.length !== dateFormat.length) {
        return false;
    }
    return DisplayDateFormat(dateString, dateFormat).length === dateFormat.length;
}

export function IsValidDate(date, dateFormat) {
    var dateString = DisplayDateFormat(date, dateFormat);
    return dateString.length === dateFormat.length;
}

export function DisplayNationalIdNumber(nationalId) {
    return nationalId !== ''
        ? `${nationalId.substring(0, 4)}******${nationalId.substring(
            nationalId.length - 3,
            nationalId.length
        )}`
        : '';
}

export function CapitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function IsMatchWithSystemConfig(value, pattern) {
    const regex = RegExp(pattern);
    return regex.test(value);
}

export function IsEndDateAfterStartDate(startDate, endDate) {
    return moment(endDate)
        .utc()
        .isAfter(moment(startDate).utc());
}

export function GenerateNumericRandom() {
    const min = 1;
    const max = 100;
    return min + Math.random() * (max - min);
}

export function getPager(
    totalItems: number,
    currentPage: number = 1,
    itemPerPage: number = 10
) {
    const totalPages = Math.ceil(totalItems / itemPerPage);
    let startPage: number, endPage: number;
    if (totalPages <= 5) {
        startPage = 1;
        endPage = totalPages;
    } else {
        if (currentPage <= 3) {
            startPage = 1;
            endPage = 5;
        } else if (currentPage + 2 >= totalPages) {
            startPage = totalPages - 3;
            endPage = totalPages;
        } else {
            startPage = currentPage - 2;
            endPage = currentPage + 2;
        }
    }
    const pages = [...Array(endPage + 1 - startPage).keys()].map(
        i => startPage + i
    );

    return {
        currentPage,
        startPage,
        endPage,
        pages,
        totalPages
    };
}

// Reference from css-tricks.com/css-variables-calc-rgb-enforcing-high-contrast-colors
export function enforcingHighContrastColors(red, green, blue) {
    return (red * 299 + green * 587 + blue * 144 - 180000) * -1000;
}

export function convertSingleCode(colorCode) {
    let hexCode = colorCode.toString(16);
    return hexCode.length == 1 ? '0' + hexCode : hexCode;
}

// Reference from tutorialscapital.com/react-native-convert-rgb-color-code-hexadecimal-code-tutorial-scratch/
export function rgbToHex(red, green, blue) {
    return (
        '#' +
    convertSingleCode(red) +
    convertSingleCode(green) +
    convertSingleCode(blue)
    );
}

// Reference from sitepoint.com/javascript-generate-lighter-darker-color
export function ColorLuminance(hex, lum) {
    // validate hex string
    hex = String(hex).replace(/[^0-9a-f]/gi, '');
    if (hex.length < 6) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    lum = lum || 0;

    // convert to decimal and change luminosity
    var rgb = '#',
        c,
        i;
    for (i = 0; i < 3; i++) {
        c = parseInt(hex.substr(i * 2, 2), 16);
        c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16);
        rgb += ('00' + c).substr(c.length);
    }

    return rgb;
}

// Contain at least 8 characters
export function isMinimumCharacterCount(password) {
    return password.length > 7;
}

// Contain at least 1 uppercase character (A-Z)
export function IsMinimumUpperCaseLetter(password) {
    return /[A-Z]/.test(password);
}

// Contain at least 1 lowercase character (a-z)
export function IsMinimumLowerCaseLetter(password) {
    return /[a-z]/.test(password);
}

// Contain at least 1 digit character
export function IsMinimumDigitCharacterCount(password) {
    return /[0-9]/.test(password);
}

// Contain at least one special character
export function IsMinimumSpecialCharacter(password) {
    return /[.!@#\$%\^&\*]/.test(password);
}

export function setRequiredChangePassword(userProfile) {
    const { amr, isRequiredChangePassword } = userProfile;

    if (amr[0] !== 'external' && isRequiredChangePassword === '1') {
        localStorage.isRequiredChangePassword = isRequiredChangePassword;
    } else if (
        localStorage.isRequiredChangePassword &&
    localStorage.isRequiredChangePassword === '1'
    ) {
        localStorage.isRequiredChangePassword = '0';
    }
}

export function getDialCode(phoneNumber: string, countryCode: string) {
    try {
        const phoneUtil = PhoneNumberUtil.getInstance();
        return phoneUtil
            .parseAndKeepRawInput(phoneNumber, countryCode)
            .getCountryCode()!
            .toString();
    } catch {
        return '';
    }
}

export function isValidNumber(phone: string, countryCode: string) {
    try {
        const phoneUtil = PhoneNumberUtil.getInstance();
        return phoneUtil.isValidNumber(
            phoneUtil.parseAndKeepRawInput(phone, countryCode)
        );
    } catch {
        return false;
    }
}

export function checkInvalidEmailField(email: string) {
    if (email) {
        return IsValidEmail(email);
    }
    return true;
}

export function isGroupNameValid(groupName: string) {
    if (groupName) {
        return /^[A-Za-z\s-]+$/.test(groupName);
    }
    return false;
}

export function isInputMatchRegex(regex: string, inputText: string) {
    if (regex && inputText) {
        return new RegExp(regex).test(inputText);
    }
    return true;
}

// Get object to display on Dropdown list
export function getProperty(model, key, list) {    
    return model && model[key]
        ? list.find(e => e.value === model[key])
        : list[0];
}

// Valid characters are "a-z", "A-Z" and space, and max length is 100 characters
export function IsValidAccountName(accountName) {
    return /^[a-zA-Z ]{1,100}$/.test(accountName);
}

// Valid characters are 0-9 and space
export function IsValidCardNumber(cardNumber) {
    return /^[\d ]*$/.test(cardNumber);
}

// Valid characters are 0-9 and space
export function IsValidIssueNumber(issueNumber) {
    return /^[\d]{1,2}$/.test(issueNumber);
}

// Valid characters are 0-9, and max length is 12 characters
export function IsValidNationalIdNumber(nationalIdNumber) {
    return /^[\d]{1,12}$/.test(nationalIdNumber);
}

export function padZero(str, len = 2) {
    var zeros = new Array(len).join('0');
    return (zeros + str).slice(-len);
}

export function invertColor(hex, bw = true) {
    if (hex.indexOf('#') === 0) {
        hex = hex.slice(1);
    }
    // convert 3-digit hex to 6-digits.
    if (hex.length === 3) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    if (hex.length !== 6) {
        throw new Error('Invalid HEX color.');
    }
    let r = parseInt(hex.slice(0, 2), 16),
        g = parseInt(hex.slice(2, 4), 16),
        b = parseInt(hex.slice(4, 6), 16);
    if (bw) {
        // http://stackoverflow.com/a/3943023/112731
        return (r * 0.299 + g * 0.587 + b * 0.114) > 186
            ? 'text-dark'
            : 'text-white';
    }

    
    // invert color components
    const rStr = (255 - r).toString(16);
    const gStr = (255 - g).toString(16);
    const bStr = (255 - b).toString(16);
    // pad each with zeros and return
    return "#" + padZero(rStr) + padZero(gStr) + padZero(bStr);
}