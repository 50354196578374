import { Item, Ads } from 'models/Store';
import { formatMoney } from 'util/ControlUtils';
import { PAYMENT_MONTH } from 'constants/Constants';

export const getFormFieldsItemsStocked = (formData, items: Item[]) => {
    let formFields: any[] = [];
    items.forEach(item => {

        const itemData: Item = formData.itemsStocked[item.id] ? formData.itemsStocked[item.id] : new Item();
        formFields.push({
            name: item.name,
            unitName: item.unitName,
            quantity: itemData.quantity,
            price: formatMoney(itemData.price * itemData.quantity, 0)
        });
    });

    return formFields;
}

export const getFormFieldsItemsOrder = (formData, items: Item[]) => {
    let formFields: any[] = [];
    items.forEach(item => {

        const itemData: Item = formData.itemsOrder[item.id] ? formData.itemsOrder[item.id] : new Item();
        formFields.push({
            name: item.name,
            unitName: item.unitName,
            quantity: itemData.quantity,
            price: formatMoney(itemData.price * itemData.quantity, 0)
        });
    });

    return formFields;
}

export const getFormFieldsAdses = (formData, adses: Ads[]) => {
    let formFields: any[] = [];
    adses.forEach(item => {

        const adsData: Ads = formData.adses[item.id] ? formData.adses[item.id] : new Ads();
        let options: any[] = [];
        item.options.forEach(option => {
            option.id === adsData.optionId && options.push({
                name: option.name
            })
        })
        const price = Math.round(adsData.price * adsData.quantity * adsData.month / PAYMENT_MONTH);
        const priceNotReachTarget = Math.round(adsData.priceNotReachTarget * adsData.quantity * adsData.month / PAYMENT_MONTH);
        const priceBonus = Math.round(adsData.priceBonus * adsData.quantity * adsData.month / PAYMENT_MONTH);
        formFields.push({
            name: item.name,
            unitName: item.unitName,
            price: formatMoney(price, 0),
            priceNotReachTarget: formatMoney(priceNotReachTarget, 0),
            priceBonus: formatMoney(priceBonus, 0),
            priceData: price,
            priceNotReachTargetData: priceNotReachTarget,
            priceBonusData: priceBonus,
            month: adsData.month,
            quantity: adsData.quantity,
            isReplace: adsData.isReplace,
            options: options
        });
    });

    return formFields;
}