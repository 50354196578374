import React from 'react';
import { connect } from 'react-redux';
import { PageProps } from 'interfaces';
import { withRouter } from 'react-router-dom';
import * as Routes from 'pages/RouteLoader';
import store from 'reduxs/index';
import Swal from 'sweetalert2';
import { setLoading } from 'reduxs/actions/Settings';
import FormItem, { setStateByProperty, checkValidations, forceAbs } from 'components/Form';
import { Field } from 'models/Settings';
import { getFields, getFormFieldsRoute } from './fields';
import * as Constants from 'constants/Constants';
import moment from 'moment';
import { DisplayDateFormat, interpret, formatMoney } from 'util/ControlUtils';
import { Item, Ads } from 'models/Store';

interface State {
    disabledSent: boolean;
    isEdit: boolean;
    data?: {
        allItems: {
            name: string;
            shortname: string;
            unitname: string;
            price: number;
        }[];
        allAdses: {
            name: string;
            shortname: string;
        }[];
        rows: {
            id: string;
            storeId: string;
            storename: string;
            streetNumber: string;
            street: string;
            wardName: string;
            districtName: string;
            cityName: string;
            inStockDate: string;
            quantitiesItem: Item[];
            quantitiesInStock: Item[];
            quantitiesAds: Ads[];
        }[];
    };
    fields: Field[];
    formData: {
        routeName: string;
        date: string;
        itemsOrder: Item[];
        adses: Ads[];
    };
}

class RouteStorePage extends React.Component<PageProps, State> {
    constructor(props: PageProps) {
        super(props);

        let fields = getFields();
        fields = fields.map(e => ({
            ...new Field(), ...e,
            isValid: e.key === 'route.date' ? true : false,
            isValidType: e.key === 'route.date' ? 'none' : 'required'
        }));

        const { location: { state } } = props;

        this.state = {
            disabledSent: true,
            isEdit: false,
            fields: fields,
            formData: {
                routeName: '',
                date: state && state.date ? state.date : moment(new Date()).format('YYYY-MM-DD'),
                itemsOrder: [],
                adses: []
            },
        }
    }

    getData = () => {
        const { formData: { routeName, date } } = this.state;
        store.dispatch(setLoading(true));
        this.props.apiService.getRoutePlanStores(routeName, DisplayDateFormat(date, Constants.DATE_FORMAT)).then(res => {
            const rightRows = JSON.parse(JSON.stringify(res.rows));
            const fakeRows = res.rows.map(row => {
                row.quantitiesItem.map(item => {
                    item.quantity = ' ';
                    return item;
                });
                row.quantitiesAds.map(ads => {
                    ads.quantity = ' ';
                    return ads;
                });
                return row;
            });
            this.setState(prevState => ({
                data: {...res, rows: fakeRows},
                formData: {
                    ...prevState.formData,
                    itemsOrder: [],
                    adses: []
                },
                disabledSent: true
            }));
            setTimeout(() => this.setState({data: {...res, rows: rightRows}}), 0);
            store.dispatch(setLoading(false));
        })
    }

    // Custom Code
    onChangeHandle = (nameHtml, valueHtml) => {
        const { formData, data } = this.state;
        let itemsOrder: Item[] = [];
        let adses: Ads[] = [];

        switch (nameHtml) {
            case 'route[routeName]':
                setStateByProperty(this, 'routeName', nameHtml, valueHtml);
                setTimeout(() => this.getData(), 0);
                break;
            case 'route[date]':
                let date = '';
                if (!isNaN(Date.parse(valueHtml))) {
                    date = DisplayDateFormat(valueHtml, Constants.DATE_FORMAT_FOR_DATABASE);
                    if (isNaN(Date.parse(date))) {
                        date = '';
                    }
                }
                setStateByProperty(this, 'date', nameHtml, date);
                setTimeout(() => this.getData(), 0);
                break;
            default:
                const extractNameHTML = interpret(nameHtml);
                if (extractNameHTML) {
                    if (extractNameHTML['name'] === 'itemsOrder' &&
                        extractNameHTML['indices'] &&
                        extractNameHTML['indices'].length === 2) { // array of sub field
                        const extractName = extractNameHTML['indices'][1];
                        const extractIndex = extractNameHTML['indices'][0];

                        if (formData.itemsOrder) {
                            itemsOrder = formData.itemsOrder;
                        }
                        if (!itemsOrder[extractIndex]) {
                            itemsOrder[extractIndex] = new Item();
                            itemsOrder[extractIndex].id = extractIndex;
                        }
                        if (extractName === 'quantity') {
                            itemsOrder[extractIndex].quantity = forceAbs(valueHtml);
                            setStateByProperty(this, 'itemsOrder', nameHtml, itemsOrder, 'quantity');
                            if (data) {
                                const extractData = extractIndex.split('-');
                                const id = extractData[0];
                                const storeId = extractData[1];
                                const itemId = extractData[2];
                                const rows = data.rows.map(row => {
                                    if (parseInt(row.id) === parseInt(id) && parseInt(row.storeId) === parseInt(storeId)) {
                                        row.quantitiesItem.map(item => {
                                            if (parseInt(String(item.id)) === parseInt(itemId)) {
                                                item.quantity = forceAbs(valueHtml);
                                            }
                                            return item;
                                        });
                                    }
                                    return row;
                                });
                                this.setState({data: {...data, rows: rows}});
                            }
                            this.setState({ disabledSent: false });
                        }
                    }
                    if (extractNameHTML['name'] === 'adses' &&
                        extractNameHTML['indices'] &&
                        extractNameHTML['indices'].length === 2) { // array of sub field
                        const extractName = extractNameHTML['indices'][1];
                        const extractIndex = extractNameHTML['indices'][0];

                        if (formData.adses) {
                            adses = formData.adses;
                        }
                        if (!adses[extractIndex]) {
                            adses[extractIndex] = new Ads();
                            adses[extractIndex].id = extractIndex;
                        }
                        if (extractName === 'quantity') {
                            adses[extractIndex].quantity = forceAbs(valueHtml);
                            setStateByProperty(this, 'adses', nameHtml, adses, 'quantity');
                            if (data) {
                                const extractData = extractIndex.split('-');
                                const id = extractData[0];
                                const storeId = extractData[1];
                                const adsId = extractData[2];
                                const rows = data.rows.map(row => {
                                    if (parseInt(row.id) === parseInt(id) && parseInt(row.storeId) === parseInt(storeId)) {
                                        row.quantitiesAds.map(ads => {
                                            if (parseInt(String(ads.id)) === parseInt(adsId)) {
                                                ads.quantity = forceAbs(valueHtml);
                                            }
                                            return ads;
                                        });
                                    }
                                    return row;
                                });
                                this.setState({data: {...data, rows: rows}});
                            }
                            this.setState({ disabledSent: false });
                        }
                    }
                }
                break;
        }
    }

    onSubmit = () => {
        const isValid = checkValidations(this);
        const { formData } = this.state;
        if (isValid) {
            Swal.fire({
                html: 'Vui lòng chờ ...<br/><div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>',
                showConfirmButton: false,
                allowOutsideClick: false
            })
            this.props.apiService.postRoutePlanStores(formData).then(() => {
                Swal.fire({
                    ...Constants.SWAL_COMMON_STYLE,
                    text: 'Lưu thành công.',
                    type: 'success',
                    confirmButtonText: 'Đóng'
                }).then(() => {
                    this.props.history.push('/' + Routes.HomePageRoute.url);
                    this.props.history.push('/' + Routes.RouteStorePageRoute.url, { date: formData.date });
                });
            });
        }
    }

    // Custom Code
    render() {
        const { settings: { metadata } } = this.props;
        const { data, formData, disabledSent, fields } = this.state;
        
        const formFieldsRoute = getFormFieldsRoute(formData);

        let totalAllQuantityItems: number[] = [];
        let totalAllQuantityInStock: number[] = [];
        let totalAllQuantityAdses: number[] = [];
        let totalPrices: number[] = [];
        let totalAllPrices = 0;

        return (
            <div className="route-page">
                <div className="sticky-top">
                    <div className="row pt-2">
                        <div className="col-6">TDV: {metadata.userInfo.name}</div>
                        <div className="col-6 text-right">Nhóm: {metadata.userInfo.groupName}</div>
                    </div>
                    <h3 className="head-title mt-0 mb-0 pt-3">TẠO KẾ HOẠCH THEO TUYẾN</h3>
                    
                </div>

                <div className="holder">
                    <div className="item-ads row">
                        <div className="mt-3 col-12">
                            <div className="row">
                                {formFieldsRoute.map((field, key) =>
                                    <div key={`${formData.routeName}-${key}`} className="mt-1 col-12">
                                        <FormItem
                                            {...field.props}
                                            onChangeHandle={this.onChangeHandle}
                                            fields={fields}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="report-table mt-3">
                        {data && <table>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    {data.allItems.length > 0 && <th colSpan={data.allItems.length} className="text-center"><span>Đặt hàng</span></th>}
                                    <th></th>
                                    <th></th>
                                    {data.allItems.length > 0 && <th colSpan={data.allItems.length} className="text-center"><span>Hàng tồn</span></th>}
                                    {data.allAdses.length > 0 && <th colSpan={data.allAdses.length} className="text-center"><span>Loại vật dụng</span></th>}
                                </tr>
                                <tr>
                                    <th className="text-center">STT</th>
                                    <th className="text-center sticky-column">Tên NT</th>
                                    <th className="text-center">Số nhà</th>
                                    <th className="text-center">Đường</th>
                                    <th className="text-center">Phường/Xã</th>
                                    <th className="text-center">Quận/Huyện</th>
                                    <th className="text-center">Tỉnh/TP</th>
                                    {data.allItems.map((e, key) =>
                                        <th className="text-center" key={key}><span className="item-name">{e.shortname}<br/>{e.unitname}</span></th>
                                    )}
                                    <th>Tổng tiền (VNĐ)</th>
                                    <th className="text-center"><span className="item-name">Ngày tồn kho</span></th>
                                    {data.allItems.map((e, key) =>
                                        <th className="text-center" key={key}><span className="item-name">{e.shortname}<br/>{e.unitname}</span></th>
                                    )}
                                    {data.allAdses.map((e, key) =>
                                        <th className="text-center" key={key}><span className="item-name">{e.shortname}</span></th>
                                    )}
                                </tr>
                            </thead>
                            <tbody>
                                {data.rows.map((row, key) =>
                                    <tr key={key}>
                                        <td>{(key + 1)}</td>
                                        <td className="no-wrap sticky-column">{row.storename}</td>
                                        <td className="no-wrap">{row.streetNumber}</td>
                                        <td className="no-wrap">{row.street}</td>
                                        <td className="no-wrap">{row.wardName}</td>
                                        <td className="no-wrap">{row.districtName}</td>
                                        <td className="no-wrap">{row.cityName}</td>
                                        {row.quantitiesItem.map((item, itemKey) => {
                                            if (!totalAllQuantityItems[itemKey]) {
                                                totalAllQuantityItems[itemKey] = 0;
                                            }
                                            const quantity = item.quantity ? item.quantity : 0;
                                            totalAllQuantityItems[itemKey] += quantity;
                                            if (!totalPrices[key]) {
                                                totalPrices[key] = 0;
                                            }
                                            const totalPrice = quantity * item.price;
                                            totalPrices[key] += totalPrice;
                                            totalAllPrices += totalPrice;
                                            return <td key={itemKey} className="text-center">
                                                <FormItem
                                                    {...{
                                                        type: 'number',
                                                        className: 'text-right mw-2',
                                                        data: item.quantity ? item.quantity : '',
                                                        htmlFor: 'itemsOrder['+(`${row.id}-${row.storeId}-${item.id}`)+'][quantity]',
                                                        fieldName: 'itemsOrder['+(`${row.id}-${row.storeId}-${item.id}`)+'][quantity]',
                                                    }}
                                                    onChangeHandle={this.onChangeHandle}
                                                    fields={fields}
                                                />
                                            </td>
                                        })}
                                        <td>{formatMoney(totalPrices[key], 0)}đ</td>
                                        <td className="text-center"><span className="no-wrap">{row.inStockDate}</span></td>
                                        {row.quantitiesInStock.map((item, itemKey) => {
                                            if (!totalAllQuantityInStock[itemKey]) {
                                                totalAllQuantityInStock[itemKey] = 0;
                                            }
                                            const quantity = item.quantity ? item.quantity : 0;
                                            totalAllQuantityInStock[itemKey] += quantity;
                                            return <td key={itemKey} className="text-center">{quantity ? quantity : ''}</td>
                                        })}
                                        {row.quantitiesAds.map((ads, adsKey) => {
                                            if (!totalAllQuantityAdses[adsKey]) {
                                                totalAllQuantityAdses[adsKey] = 0;
                                            }
                                            const quantity = ads.quantity ? ads.quantity : 0;
                                            totalAllQuantityAdses[adsKey] += quantity;
                                            return <td key={adsKey} className="text-center">
                                                <FormItem
                                                    {...{
                                                        type: 'number',
                                                        className: 'text-right mw-2',
                                                        data: ads.quantity ? ads.quantity : '',
                                                        htmlFor: 'adses['+(`${row.id}-${row.storeId}-${ads.id}`)+'][quantity]',
                                                        fieldName: 'adses['+(`${row.id}-${row.storeId}-${ads.id}`)+'][quantity]',
                                                    }}
                                                    onChangeHandle={this.onChangeHandle}
                                                    fields={fields}
                                                />
                                            </td>
                                        })}
                                    </tr>
                                )}
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td colSpan={7}><b>TỔNG CỘNG</b></td>
                                    {Object.keys(totalAllQuantityItems).map((k, key) =>
                                        <td key={key} className="text-center"><b>{totalAllQuantityItems[k] ? totalAllQuantityItems[k] : ''}</b></td>
                                    )}
                                    <td><b>{formatMoney(totalAllPrices, 0)}đ</b></td>
                                    <td></td>
                                    {Object.keys(totalAllQuantityInStock).map((k, key) =>
                                        <td key={key} className="text-center"><b>{totalAllQuantityInStock[k] ? totalAllQuantityInStock[k] : ''}</b></td>
                                    )}
                                    {Object.keys(totalAllQuantityAdses).map((k, key) =>
                                        <td key={key} className="text-center"><b>{totalAllQuantityAdses[k] ? totalAllQuantityAdses[k] : ''}</b></td>
                                    )}
                                </tr>
                            </tfoot>
                        </table>}
                    </div>
                    
                </div>
                <div className="sticky-bottom">
                    <div className="group-btn">
                        <button className="btn btn-dark" onClick={() => this.props.history.push('/' + Routes.HomePageRoute.url)}>Home</button>
                        <button className="btn btn-dark" onClick={() => this.props.history.push('/' + Routes.RouteHomePageRoute.url)}>Quay lại</button>
                        <button disabled={disabledSent} className="btn btn-primary" onClick={() => this.onSubmit()}>Lưu</button>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ settings }) => ({ settings });
export default withRouter(connect(mapStateToProps)(RouteStorePage));