import { Field } from "models/Settings";
import Calendar from "models/Calendar";

export const getFields = () => {
    const fields: Field[] =  [
        {
            key: "calendar.place",
            isRequired: false,
            isVisible: true,
            regex: "",
            maxLength: 50,
            name: "Place"
        }
    ];

    return fields;
}


export const getFormFields = (formData, cells) => {
    let formFields: any[] = [];
    cells.forEach(cell => {

        const calendar: Calendar = formData.calendar[cell.day] ? formData.calendar[cell.day] : new Calendar();
        formFields.push({
            props: {
                type: 'text',
                className: '',
                data: calendar.place,
                htmlFor: 'calendar[' + cell.day + '][place]',
                fieldName: 'calendar[' + cell.day + '][place]'
            },
            staffCalendars: calendar.staffCalendars
        });
    });

    return formFields;
}