import React from 'react';
import SignaturePad from 'react-signature-pad-wrapper';

const Step3 = ({
    metadata,
    onSubmit,
    sigCanvas
}) => {
    return <React.Fragment>
        <div className="sticky-top">
            <div className="row pt-2">
                <div className="col-6">TDV: {metadata.userInfo.name}</div>
                <div className="col-6 text-right">Nhóm: {metadata.userInfo.groupName}</div>
            </div>
            <h3 className="head-title mt-0 mb-0 pt-3">NHÀ THUỐC KÝ XÁC NHẬN</h3>
        </div>
        <div className="holder">
            <div className="item-ads row">
                <div className="col-12">
                    <form id="file" onSubmit={(e) => e.preventDefault()}>
                        <SignaturePad options={{backgroundColor: 'rgba(255,255,255,1)'}} redrawOnResize={true} height={300} ref={(ref) => sigCanvas(ref)} />
                    </form>
                </div>
            </div>

        </div>
        <div className="sticky-bottom">
            <div className="group-btn">
                <button className="btn btn-dark" onClick={() => onSubmit('draft', 2)}>Quay lại</button>
                <button className="btn btn-primary" onClick={() => onSubmit('sent', 1)}>Hoàn thành</button>
            </div>
        </div>
    </React.Fragment>
}

export default Step3;