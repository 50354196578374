declare var window: Record<string, Record<string, string>>;
export const DOMAIN_URL = `${window.location.protocol}//${
    window.location.hostname
}${window.location.port ? `:${window.location.port}` : ''}`;
export const BASE_API_URL =
    DOMAIN_URL.indexOf('localhost') > -1
        ? 'https://beta.baocaohisamitsu.com/web-admin/api'
        : `${DOMAIN_URL}/web-admin/api`;

export const SERVICE_HOME_PER_PAGE = 5;
export const CATEGORY_ITEM_PER_PAGE = 24;

export const PAYMENT_MONTH = 3;

export const SWAL_COMMON_STYLE = {
    customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-dark'
    },
    buttonsStyling: false,
    focusConfirm: false
};

export const GOOGLE_MAP_API_KEY = 'AIzaSyD0OOYFdiUYhqklUmBzUcQfAkwXzDSVk64';
export const DATE_FORMAT = `DD/MM/YYYY`;
export const DATE_FORMAT_FOR_DATABASE = `YYYY-MM-DD`;

/* ----------------------------------------------------------------------------------- */
/* ---------------------------------     Redux Action    ----------------------------- */
/* ----------------------------------------------------------------------------------- */

export const SET_METADATA = 'SET_METADATA';
export const SET_LOADING = 'SET_LOADING';
export const SET_STORE = 'SET_STORE';
export const SET_COORD = 'SET_COORD';
export const SET_ROLE = 'SET_ROLE';

/* ----------------------------------------------------------------------------------- */
/* ---------------------------------     API    -------------------------------------- */
/* ----------------------------------------------------------------------------------- */

export const API_LOGIN = `${BASE_API_URL}/login`;
export const API_INFO = `${BASE_API_URL}/info`;
export const API_GET_CALENDAR = `${BASE_API_URL}/get_calendar`;
export const API_POST_CALENDAR = `${BASE_API_URL}/post_calendar`;
export const API_GET_CALENDAR_DETAIL = `${BASE_API_URL}/get_calendar_detail`;
export const API_SEARCH_STORES = `${BASE_API_URL}/search_stores`;
export const API_SEARCH_ROUTES = `${BASE_API_URL}/search_routes`;
export const API_GET_ROUTE_STORES = `${BASE_API_URL}/get_route_stores`;
export const API_POST_ROUTE_STORES = `${BASE_API_URL}/post_route_stores`;
export const API_DELETE_ROUTE_STORES = `${BASE_API_URL}/delete_route_stores`;
export const API_GET_ROUTE_PLAN_STORES = `${BASE_API_URL}/get_route_plan_stores`;
export const API_POST_ROUTE_PLAN_STORES = `${BASE_API_URL}/post_route_plan_stores`;
export const API_ITEMS = `${BASE_API_URL}/items`;
export const API_ADSES = `${BASE_API_URL}/adses`;
export const API_GET_REPORT_STORE = `${BASE_API_URL}/get_report_store`;
export const API_POST_REPORT_STORE = `${BASE_API_URL}/post_report_store`;
export const API_GET_REQUEST_ADS = `${BASE_API_URL}/get_request_ads`;
export const API_POST_REQUEST_ADS = `${BASE_API_URL}/post_request_ads`;
export const API_GET_REQUEST_PAYMENT = `${BASE_API_URL}/get_request_payment`;
export const API_POST_REQUEST_PAYMENT = `${BASE_API_URL}/post_request_payment`;
export const API_HISTORY = `${BASE_API_URL}/history`;
export const API_GET_STORE = `${BASE_API_URL}/get_store`;
export const API_POST_STORE = `${BASE_API_URL}/post_store`;
export const API_GET_MANAGER_REPORT = `${BASE_API_URL}/get_manager_report`;
export const API_GET_MANAGER_REPORT_DETAIL = `${BASE_API_URL}/get_manager_report_detail`;
export const API_GET_MANAGER_STORE_DETAIL = `${BASE_API_URL}/get_manager_store_detail`;
export const API_GET_MANAGER_REQUEST_STORE_PENDING = `${BASE_API_URL}/get_manager_request_store_pending`;
export const API_GET_MANAGER_CALENDAR = `${BASE_API_URL}/get_manager_calendar`;
export const API_POST_MANAGER_CALENDAR = `${BASE_API_URL}/post_manager_calendar`;
export const API_POST_MANAGER_CALENDAR_TARGET_PRICE = `${BASE_API_URL}/post_manager_calendar_target_price`;
export const API_DO_APPROVE = `${BASE_API_URL}/do_approve`;
export const API_DO_STORE_APPROVE = `${BASE_API_URL}/do_store_approve`;
export const API_GET_DOCUMENT = `${BASE_API_URL}/get_document`;
export const API_GET_DOCUMENT_GROUP = `${BASE_API_URL}/get_document_group`;
export const API_EXPORT_EXCEL = `${BASE_API_URL}/export_excel`;
export const API_GET_DISTRICTS_STORE_OF_STAFF_USER = `${BASE_API_URL}/get_districts_stores_of_staff_user`;
export const API_GET_PLAN_STORE = `${BASE_API_URL}/get_plan_store`;
export const API_POST_PLAN_STORE = `${BASE_API_URL}/post_plan_store`;
export const API_DELETE_PLAN_STORE = `${BASE_API_URL}/delete_plan_store`;
export const API_EXPORT_REQUEST_PAYMENT_ADS_PRICE = `${API_GET_REQUEST_PAYMENT}?storeId={0}&requestPaymentConfigId={1}&token={2}&export={3}`;