import React from 'react';
import { connect } from 'react-redux';
import { PageProps } from 'interfaces';
import { withRouter } from 'react-router-dom';
import * as Routes from 'pages/RouteLoader';
import Store, { Ads, Item } from 'models/Store';
import store from 'reduxs/index';
import { setLoading } from 'reduxs/actions/Settings';
import { getFormFieldsItemsStocked, getFormFieldsItemsOrder, getFormFieldsAdses } from './fields';
import { City, District, PeriodOption } from 'models/Settings';
import { TrackDate as TrackDateModel } from 'models/Store';
import StoreInfo from './store';
import TrackDate from './track-date';
import { formatMoney } from 'util/ControlUtils';
import ViewImgGroup from 'components/ViewImgGroup';

interface State {
    id: string;
    storeId: string;
    type: 'reportStore' | 'requestAds' | 'requestPayment' | 'requestStore' | 'planStore';
    items: Item[];
    adses: Ads[];
    store: Store;
    trackDate: TrackDateModel;
    returnURLType?: 'calendar_detail';
    returnURLParams?: {
        date?: string;
    };
    dataPlanStore?: {
        itemsOrder: Item[];
        adses: Ads[];
        status: string;
    };
    dataReportStore?: {
        itemsStocked: Item[];
        itemsOrder: Item[];
        adses: Ads[];
        adsImageEvidencePreview: string[];
        adsImageDocumentPreview: string[];
        status: string;
        note: string;
    };
    dataRequestAds?: {
        adses: Ads[];
        note: string;
        adsImageInsidePreview: string[];
        adsImageOutsidePreview: string[];
        adsImageDocumentPreview: string[];
        status: string;
    };
    dataRequestPayment?: {
        adses: Ads[];
        adsImageInsidePreview: string[];
        adsImageOutsidePreview: string[];
        requestPaymentConfigId: number;
        signatureData: string;
        status: string;
    };
}
class HistoryDetailPage extends React.Component<PageProps, State> {
    constructor(props: PageProps) {
        super(props);

        const { location: { state } } = props;

        this.state = {
            id: props.match.params.id,
            storeId: props.match.params.storeId,
            returnURLType: state ? state.returnURLType : null,
            returnURLParams: state ? state.returnURLParams : null,
            type: props.match.params.type as 'reportStore' | 'requestAds' | 'requestPayment' | 'requestStore' | 'planStore',
            items: [],
            adses: [],
            trackDate: new TrackDateModel(),
            store: new Store()
        }
    }

    componentDidMount() {
        const { id, storeId, type } = this.state;
        let data;
        if (type === 'reportStore') {
            data = this.props.apiService.getReportStore(storeId, '1900-10-10', parseInt(id));
        } else if (type === 'planStore') {
            data = this.props.apiService.getPlanStore(storeId, '1900-10-10', parseInt(id));
        } else if (type === 'requestAds') {
            data = this.props.apiService.getRequestAds(storeId, parseInt(id));
        } else if (type === 'requestPayment') {
            data = this.props.apiService.getRequestPayment(storeId, parseInt(id));
        } else if (type === 'requestStore') {
            data = {};
        }
        store.dispatch(setLoading(true));
        const items = this.props.apiService.items();
        const adses = this.props.apiService.adses(type);
        const requestStore = this.props.apiService.getRequestStore(storeId);
        Promise.all([items, adses, data, requestStore]).then(([res1, res2, res3, res4]) => {
            if (type === 'reportStore') {
                this.setState({ items: res1, adses: res2, dataReportStore: res3, store: res4, trackDate: res3.trackDate });
            } else if (type === 'planStore') {
                this.setState({ items: res1, adses: res2, dataPlanStore: res3, store: res4, trackDate: res3.trackDate });
            } else if (type === 'requestAds') {
                this.setState({ items: res1, adses: res2, dataRequestAds: res3, store: res4, trackDate: res3.trackDate });
            } else if (type === 'requestPayment') {
                this.setState({ items: res1, adses: res2, dataRequestPayment: res3, store: res4, trackDate: res3.trackDate });
            } else if (type === 'requestStore') {
                this.setState({ items: res1, adses: res2, store: res4, trackDate: res4.trackDate });
            }
            store.dispatch(setLoading(false));
        })
    }

    goBack = () => {
        const { returnURLType, returnURLParams } = this.state;
        if (returnURLType === 'calendar_detail') {
            this.props.history.push(`/${Routes.CalendarReportPageRoute.url.replace(':date', returnURLParams && returnURLParams.date ? returnURLParams.date : '')}`);
        } else {
            this.props.history.push('/' + Routes.HistoryPageRoute.url);
        }
    }

    goEdit = () => {
        const { type, storeId, trackDate } = this.state;
        const date = trackDate.createdDate.split(' ')[0].split('/').reverse().join('-');
        if (type === 'reportStore') {
            this.props.history.push(`/${Routes.ReportStorePageEditRoute.url.replace(':storeId', storeId).replace(':date', date)}`, {returnURLType: 'history_detail', returnURLParams: {date: date}});
        } else if (type === 'planStore') {
            this.props.history.push(`/${Routes.PlanStorePageEditRoute.url.replace(':storeId', storeId).replace(':date', date)}`, {returnURLType: 'history_detail', returnURLParams: {date: date}});
        } else if (type === 'requestAds') {
            this.props.history.push(`/${Routes.RequestAdsPageEditRoute.url.replace(':storeId', storeId)}`);
        } else if (type === 'requestPayment') {
            this.props.history.push(`/${Routes.RequestPaymentPageEditRoute.url.replace(':storeId', storeId)}`);
        }
    }

    // Custom Code
    render() {
        const { settings: { metadata } } = this.props;
        const { id, dataReportStore, dataRequestAds, dataPlanStore, dataRequestPayment, items, adses, store, type, trackDate } = this.state;

        let formFieldsItemsStocked, formFieldsItemsOrder, formFieldsAdses;
        const city = metadata.city.find((e: City) => e.id === store.cityId);
        const district = city ? city.district.find((e: District) => e.id === store.districtId) : null;
        const period =  metadata.allPeriods.find((e: PeriodOption) => dataRequestPayment && e.id === String(dataRequestPayment.requestPaymentConfigId));

        let totalPrice = 0;
        let totalpriceNotReachTarget = 0;
        let totalpriceBonus = 0;
        if (type === 'reportStore') {
            formFieldsItemsStocked = getFormFieldsItemsStocked(dataReportStore, items);
            formFieldsItemsOrder = getFormFieldsItemsOrder(dataReportStore, items);
            formFieldsAdses = getFormFieldsAdses(dataReportStore, adses);
        } else if (type === 'planStore') {
            formFieldsItemsOrder = getFormFieldsItemsOrder(dataPlanStore, items);
            formFieldsAdses = getFormFieldsAdses(dataPlanStore, adses);
        } else if (type === 'requestAds') {
            formFieldsAdses = getFormFieldsAdses(dataRequestAds, adses);
        } else if (type === 'requestPayment') {
            formFieldsAdses = getFormFieldsAdses(dataRequestPayment, adses);
            totalPrice = formFieldsAdses.reduce((acc, cur) => acc + cur.priceData, 0);
            totalpriceNotReachTarget = formFieldsAdses.reduce((acc, cur) => acc + cur.priceNotReachTargetData, 0);
            totalpriceBonus = formFieldsAdses.reduce((acc, cur) => acc + cur.priceBonusData, 0);
        }

        return (
            <div className="history-detail-page">
                <div className="sticky-top">
                    <div className="row pt-2">
                        <div className="col-6">TDV: {metadata.userInfo.name}</div>
                        <div className="col-6 text-right">Nhóm: {metadata.userInfo.groupName}</div>
                    </div>
                    {type === 'requestStore' && <h3 className="head-title mt-0 mb-0 pt-3">Yêu cầu tạo nhà thuốc {id}</h3>}
                    {type === 'requestStore' &&
                        <div className="item-ads row">
                            <StoreInfo store={store} district={district} city={city} />
                            <TrackDate type="requestStore" trackDate={trackDate} />
                        </div>}

                    {dataPlanStore && <h3 className="head-title mt-0 mb-0 pt-3">Kế hoạch hoạt động {id}</h3>}
                    {dataReportStore && <h3 className="head-title mt-0 mb-0 pt-3">Báo cáo hoạt động {id}</h3>}
                    {dataRequestAds && <h3 className="head-title mt-0 mb-0 pt-3">Yêu cầu thi công {id}</h3>}
                    {dataRequestPayment && <h3 className="head-title mt-0 mb-0 pt-3">Yêu cầu trả phí {id}</h3>}

                </div>

                <div className="holder">
                    {dataPlanStore &&
                        <div className="item-ads row">
                            <StoreInfo store={store} district={district} city={city} />
                            <div className="col-12 col-sm-6">
                                <div className="item-ads-table mt-3">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>ĐẶT HÀNG</th>
                                                <th className="text-center">SỐ LƯỢNG</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {formFieldsItemsOrder.map((field, key) =>
                                                <tr key={key}>
                                                    <td>{field.name}</td>
                                                    <td>{field.quantity}</td>
                                                    <td>{field.unitName}</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6">
                                <div className="item-ads-table mt-3">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>VDQC</th>
                                                <th className="text-center">SỐ LƯỢNG</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {formFieldsAdses.map((field, key) =>
                                                <tr key={key}>
                                                    <td>{field.name}</td>
                                                    <td>{field.quantity}</td>
                                                    <td>{field.unitName}</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <TrackDate type="planStore" trackDate={trackDate} />
                        </div>}

                    {dataReportStore &&
                        <div className="item-ads row">
                            <StoreInfo store={store} district={district} city={city} />
                            <div className="col-12 col-sm-6">
                                <div className="item-ads-table mt-3">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>HÀNG TỒN</th>
                                                <th className="text-center">SỐ LƯỢNG</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {formFieldsItemsStocked.map((field, key) =>
                                                <tr key={key}>
                                                    <td>{field.name}</td>
                                                    <td>{field.quantity}</td>
                                                    <td>{field.unitName}</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6">
                                <div className="item-ads-table mt-3">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>ĐẶT HÀNG</th>
                                                <th className="text-center">SỐ LƯỢNG</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {formFieldsItemsOrder.map((field, key) =>
                                                <tr key={key}>
                                                    <td>{field.name}</td>
                                                    <td>{field.quantity}</td>
                                                    <td>{field.unitName}</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="mt-3">
                                    <b>Ghi chú:</b> {dataReportStore.note ? dataReportStore.note : 'Không có'}
                                </div>
                            </div>
                            <div className="col-12 col-sm-6">
                                <div className="item-ads-table mt-3">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>VDQC</th>
                                                <th className="text-center">SỐ LƯỢNG</th>
                                                <th></th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {formFieldsAdses.map((field, key) =>
                                                <tr key={key}>
                                                    <td>{field.name}</td>
                                                    <td>{field.quantity}</td>
                                                    <td>{field.unitName}</td>
                                                    <td>{field.isReplace ? 'Thay VD cũ' : ''}</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6">
                                <div className="item-ads-table mt-3">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>CHỤP HÌNH</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <ViewImgGroup images={dataReportStore.adsImageEvidencePreview} />
                                                    <p>* Hình chụp toàn cảnh <b>bên trong</b> nhà thuốc, thấy rõ số lượng các vật dụng quảng cáo.</p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="item-ads-table mt-3">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>CHỤP BBTT</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <ViewImgGroup images={dataReportStore.adsImageDocumentPreview} showMsgIfEmpty={true} />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <TrackDate type="reportStore" trackDate={trackDate} />
                        </div>}

                    {dataRequestAds &&
                        <div className="item-ads row">
                            <StoreInfo store={store} district={district} city={city} />
                            <div className="col-12 col-sm-8">
                                <div className="item-ads-table mt-3">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>VẬT DỤNG</th>
                                                <th className="text-center">SỐ LƯỢNG</th>
                                                <th></th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {formFieldsAdses.map((field, key1) =>
                                                <tr key={key1}>
                                                    <td>{field.name}</td>
                                                    <td>{field.quantity}</td>
                                                    <td>{field.unitName}</td>
                                                    <td>
                                                        {field.isReplace ? 'Thay VD cũ ' : ''}
                                                        {field.options.map((option, key2) =>
                                                            <span key={key2}>({option.name})</span>
                                                        )}
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <form id="file" onSubmit={(e) => e.preventDefault()} className="col-12 col-sm-4">
                                <div className="item-ads-table mt-3">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>CHỤP HÌNH BÊN NGOÀI</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <ViewImgGroup images={dataRequestAds.adsImageOutsidePreview} />
                                                    <p>* Hình chụp toàn cảnh <b>bên ngoài</b> nhà thuốc, thấy rõ vị trí sẽ đặt vật dụng quảng cáo.</p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="item-ads-table mt-3">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>CHỤP HÌNH BÊN TRONG</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <ViewImgGroup images={dataRequestAds.adsImageInsidePreview} />
                                                    <p>* Hình chụp toàn cảnh <b>bên trong</b> nhà thuốc, thấy rõ vị trí sẽ đặt vật dụng quảng cáo.</p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="item-ads-table mt-3">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>CHỤP BBTT</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <ViewImgGroup images={dataRequestAds.adsImageDocumentPreview} showMsgIfEmpty={true} />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </form>
                            <div className="mt-3 col-12">
                                <b>Ghi chú:</b> {dataRequestAds.note ? dataRequestAds.note : 'Không có'}
                            </div>
                            <TrackDate type="requestAds" trackDate={trackDate} />
                        </div>}

                    {dataRequestPayment &&
                        <div className="item-ads row">
                            <StoreInfo store={store} district={district} city={city} />
                            <div className="col-12 col-sm-8">
                                <div className="item-ads-table mt-3">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>VẬT DỤNG</th>
                                                <th>SỐ LƯỢNG</th>
                                                <th>THÁNG</th>
                                                <th className="text-right">PHÍ QUẢNG CÁO</th>
                                                <th className="text-right">PHÍ CHIẾT KHẤU</th>
                                                <th className="text-right">MÚC PHÍ / QUÝ</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {formFieldsAdses.map((field, key) =>
                                                <tr key={key}>
                                                    <td>{field.name}</td>
                                                    <td>{field.quantity} {field.unitName}</td>
                                                    <td>{field.month}</td>
                                                    <td className="text-right">{field.priceNotReachTarget} VNĐ</td>
                                                    <td className="text-right">{field.priceBonus} VNĐ</td>
                                                    <td className="text-right">{field.price} VNĐ</td>
                                                </tr>
                                            )}
                                            <tr>
                                                <td><b>TỔNG CỘNG</b></td>
                                                <td colSpan={2}></td>
                                                <td className="text-right"><b>{formatMoney(totalpriceNotReachTarget, 0)} VNĐ</b></td>
                                                <td className="text-right"><b>{formatMoney(totalpriceBonus, 0)} VNĐ</b></td>
                                                <td className="text-right"><b>{formatMoney(totalPrice, 0)} VNĐ</b></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="col-12 col-sm-4">
                                <div className="item-ads-table mt-3">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>CHỤP HÌNH BÊN NGOÀI</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <ViewImgGroup images={dataRequestPayment.adsImageOutsidePreview} />
                                                    <p>* Hình chụp toàn cảnh <b>bên ngoài</b> nhà thuốc, thấy rõ vị trí đang đặt vật dụng quảng cáo.</p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="item-ads-table mt-3">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>CHỤP HÌNH BÊN TRONG</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <ViewImgGroup images={dataRequestPayment.adsImageInsidePreview} />
                                                    <p>* Hình chụp toàn cảnh <b>bên trong</b> nhà thuốc, thấy rõ vị trí đang đặt vật dụng quảng cáo.</p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="item-ads-table mt-3">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>CHỮ KÝ XÁC NHẬN</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    {dataRequestPayment.signatureData && <img className="mt-1 mb-1 img-center" src={dataRequestPayment.signatureData} />}
                                                    {!dataRequestPayment.signatureData && <span>Không có hình ảnh</span>}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="col-12 mt-3">
                                <form id="file" onSubmit={(e) => e.preventDefault()}>
                                    Phí quảng cáo <b>{period ? period.name : ''}</b> sẽ được chuyển khoản vào số tài khoản nêu trên trước ngày <b>{period ? period.deadline : ''}</b>.
                                </form>
                            </div>
                            <TrackDate type="requestPayment" trackDate={trackDate} />
                        </div>}

                </div>
                <div className="sticky-bottom">
                    <div className="group-btn">
                        <button className="btn btn-dark" onClick={() => this.goBack()}>Quay lại</button>
                        {dataPlanStore && dataPlanStore.status === 'draft' && <button className="btn btn-primary" onClick={() => this.goEdit()}>Sửa</button>}
                        {dataReportStore && dataReportStore.status === 'draft' && <button className="btn btn-primary" onClick={() => this.goEdit()}>Sửa</button>}
                        {dataRequestAds && dataRequestAds.status === 'draft' && <button className="btn btn-primary" onClick={() => this.goEdit()}>Sửa</button>}
                        {dataRequestPayment && dataRequestPayment.status === 'draft' && <button className="btn btn-primary" onClick={() => this.goEdit()}>Sửa</button>}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ settings }) => ({ settings });
export default withRouter(connect(mapStateToProps)(HistoryDetailPage));