import { addLocaleData } from 'react-intl';
import enLang from './entries/en-US';



const AppLocale = {
    'en-US': enLang
};
addLocaleData(AppLocale['en-US'].data);

export default AppLocale;
