import { Field } from "models/Settings";
import { getIdOrUndefined } from "components/Form";
import { Ads } from 'models/Store';
import { formatMoney } from 'util/ControlUtils';
import { getSearchStoreFields } from "pages/RequestStorePage/fields";
import { PAYMENT_MONTH } from 'constants/Constants';

export const getFields = () => {
    const fields: Field[] = [
        ...getSearchStoreFields(),
        {
            key: "adses.quantity",
            isRequired: false,
            isVisible: true,
            regex: "",
            maxLength: 50,
            name: "Adses Quantity"
        },
        {
            key: "adses.option",
            isRequired: false,
            isVisible: true,
            regex: "",
            maxLength: 50,
            name: "Adses Option"
        },
        {
            key: "requestPayment.period",
            isRequired: true,
            isVisible: true,
            regex: "",
            maxLength: 50,
            name: "Period"
        },
    ];

    return fields;
}

export const getFormFieldsAdses = (formData, adses: Ads[]) => {
    let formFields: any[] = [];
    adses.forEach(item => {

        const adsData: Ads = formData.adses[item.id] ? formData.adses[item.id] : new Ads();
        let options: any[] = [];
        item.options.forEach(option => {
            options.push({
                props: {
                    type: 'radio',
                    className: 'inline-block',
                    data: option.id,
                    htmlFor: 'adses[' + option.id + '][option]',
                    fieldName: 'adses[' + item.id + '][option]',
                    attribute: {
                        disabled: getIdOrUndefined(formData.store) <= 0,
                        checked: option.id === adsData.optionId
                    },
                    fieldLabel: option.name
                },
            })
        });
        const price = Math.round(adsData.price * adsData.quantity * adsData.month / PAYMENT_MONTH);
        const priceNotReachTarget = Math.round(adsData.priceNotReachTarget * adsData.quantity * adsData.month / PAYMENT_MONTH);
        const priceBonus = Math.round(adsData.priceBonus * adsData.quantity * adsData.month / PAYMENT_MONTH);
        formFields.push({
            name: item.name,
            unitName: item.unitName,
            price: formatMoney(price, 0),
            priceNotReachTarget: formatMoney(priceNotReachTarget, 0),
            priceBonus: formatMoney(priceBonus, 0),
            priceData: price,
            priceNotReachTargetData: priceNotReachTarget,
            priceBonusData: priceBonus,
            quantity: adsData.quantity,
            month: adsData.month,
            props: {
                type: 'number',
                className: 'text-right',
                data: adsData.quantity !== 0 ? adsData.quantity : '',
                htmlFor: 'adses[' + item.id + '][quantity]',
                fieldName: 'adses[' + item.id + '][quantity]',
                attribute: {
                    disabled: getIdOrUndefined(formData.store) <= 0
                }
            },
            options: options
        });
    });

    return formFields;
}

export const getFormFieldsOther = (formData) => {
    const formFields = [
        {
            props: {
                type: 'textarea',
                className: '',
                data: formData.note,
                htmlFor: 'other[note]',
                fieldName: 'other[note]',
                attribute: {
                    disabled: getIdOrUndefined(formData.store) <= 0,
                    style: {
                        width: '100%'
                    },
                    rows: 3
                },
                fieldLabel: 'GHI CHÚ (nếu có)'
            },
        }
    ];

    return formFields;
}