import React from 'react';
import { connect } from 'react-redux';
import { PageProps } from 'interfaces';
import { Field, City, District } from 'models/Settings';
import { withRouter } from 'react-router-dom';
import FormItem, { setStateByProperty, checkValidations, forceAbs, getIdOrUndefined } from 'components/Form';
import Swal from 'sweetalert2';
import * as Routes from 'pages/RouteLoader';
import { getFields, getFormFieldsAdses, getFormFieldsOther } from './fields';
import { getFormFieldsSearchStore } from 'pages/RequestStorePage/fields';
import { interpret, urltoFile } from 'util/ControlUtils';
import store from 'reduxs/index';
import { setLoading, setStore } from 'reduxs/actions/Settings';
import Store, { Ads } from 'models/Store';
import * as Constants from 'constants/Constants';
import UploadImgGroup from 'components/UploadImgGroup';
import _ from 'lodash';

interface State {
    disabledDraft: boolean;
    disabledSent: boolean;
    fields: Field[];

    // Custom Code
    formData: {
        store: Store;
        city: City;
        district: District;
        wardName: string;
        street: string;
        streetNumber: string;
        adses: Ads[];
        note: string;
        adsImageInside: (File | null)[];
        adsImageOutside: (File | null)[];
        adsImageDocument: (File | null)[];
    };
    adsImageInsidePreview: (string | ArrayBuffer | null)[];
    adsImageOutsidePreview: (string | ArrayBuffer | null)[];
    adsImageDocumentPreview: (string | ArrayBuffer | null)[];
    adses: Ads[];
    initLoaded: boolean;
}

class RequestAdsPage extends React.Component<PageProps, State> {
    constructor(props: PageProps) {
        super(props);
        store.dispatch(setStore([]));
        let fields = getFields();
        fields = fields.map(e => ({
            ...new Field(), ...e,
            isValid: false,
            isValidType: 'required'
        }));

        this.state = {
            disabledDraft: true,
            disabledSent: true,
            fields: fields,

            // Custom Code
            formData: {
                store: new Store(),
                city: localStorage.cityId ? {...new City(), id: localStorage.cityId} : new City(),
                district: localStorage.districtId ? {...new District(), id: localStorage.districtId} : new District(),
                wardName: '',
                street: '',
                streetNumber: '',
                adses: [],
                note: '',
                adsImageInside: [],
                adsImageOutside: [],
                adsImageDocument: []
            },
            adsImageInsidePreview: [],
            adsImageOutsidePreview: [],
            adsImageDocumentPreview: [],
            adses: [],
            initLoaded: true
        }

        if (props.match.params.storeId) {
            this.state = {
                ...this.state,
                formData: {
                    ...this.state.formData,
                    store: {...new Store(), id: parseInt(props.match.params.storeId)}
                },
                initLoaded: false
            }

            this.getData();
        }
    }

    static getDerivedStateFromProps(props, state) {
        const { initLoaded,formData } = state;
        const { settings: { stores, metadata } } = props;
        if (!initLoaded && stores.length && metadata.city.length) {
            const findStore = stores.find(e => e.id === getIdOrUndefined(formData.store));
            const selectedCity = metadata.city.find(e => findStore && e.id === findStore.cityId) || new City();
            const selectedDistrict = selectedCity ? selectedCity.district.find(e => findStore && e.id === findStore.districtId) || new District() : new District();
            localStorage.cityId = selectedCity.id;
            localStorage.districtId = selectedDistrict.id;

            let storeField = {};
            if (findStore) {
                storeField = {
                    wardName:findStore.wardName,
                    street: findStore.street,
                    streetNumber: findStore.streetNumber,
                }
                localStorage.wardName = findStore.wardName;
                localStorage.street = findStore.street;
                localStorage.streetNumber = findStore.streetNumber;
            }

            return { 
                ...state,
                formData: {
                    ...state.formData,
                    ...storeField,
                    city: selectedCity,
                    district: selectedDistrict
                },
                initLoaded: true
            };
        }
        return null;
    }

    componentDidMount() {
        store.dispatch(setLoading(true));
        this.props.apiService.adses('requestAds').then(res => {
            this.setState({
                adses: res
            });
            store.dispatch(setLoading(false));
        });
    }

    resetSearchForm = () => {
        this.setState(prevState => ({
            formData: {
                ...prevState.formData,
                store: new Store(),
                adses: [],
                note: '',
                adsImageInside: [],
                adsImageOutside: [],
                adsImageDocument: []
            },
            adsImageInsidePreview: [],
            adsImageOutsidePreview: [],
            adsImageDocumentPreview: [],
            disabledDraft: true,
            disabledSent: true
        }));
        (document.getElementById('file') as any).reset();
    }

    getData = () => {
        setTimeout(() => {
            const { formData } = this.state;
            const storeId = getIdOrUndefined(formData.store);
            if (storeId <= 0) {
                this.setState({
                    formData: {
                        store: new Store(),
                        city: new City(),
                        district: new District(),
                        wardName: '',
                        street: '',
                        streetNumber: '',
                        adses: [],
                        note: '',
                        adsImageInside: [],
                        adsImageOutside: [],
                        adsImageDocument: []
                    },
                    adsImageInsidePreview: [],
                    adsImageOutsidePreview: [],
                    adsImageDocumentPreview: [],
                    disabledDraft: true,
                    disabledSent: true
                });

                (document.getElementById('file') as any).reset();
            } else {
                this.props.apiService.getRequestAds(storeId).then(res => {
                    const pmAdsImageInside = urltoFile(res.adsImageInsidePreview);
                    const pmAdsImageOutside = urltoFile(res.adsImageOutsidePreview);
                    const pmAdsImageDocument = urltoFile(res.adsImageDocumentPreview);
                    Promise.all([pmAdsImageInside, pmAdsImageOutside, pmAdsImageDocument]).then(([adsImageInside, adsImageOutside, adsImageDocument]) => {
                        this.setState(prevState => ({
                            formData: {
                                ...prevState.formData,
                                adses: res.adses,
                                note:  res.note,
                                adsImageInside: adsImageInside,
                                adsImageOutside: adsImageOutside,
                                adsImageDocument: adsImageDocument
                            },
                            adsImageInsidePreview: res.adsImageInsidePreview,
                            adsImageOutsidePreview: res.adsImageOutsidePreview,
                            adsImageDocumentPreview: res.adsImageDocumentPreview,
                            disabledDraft: true,
                            disabledSent: !_.isEmpty(res.adses) || !_.isEmpty(res.adsImageInsidePreview) || !_.isEmpty(res.adsImageOutsidePreview) || !_.isEmpty(res.adsImageDocumentPreview) || res.note ? false : true
                        }))
                    });
                });

                (document.getElementById('file') as any).reset();
            }
        }, 0);
    }

    // Custom Code
    onChangeHandle = (nameHtml, valueHtml) => {
        const { settings: { stores } } = this.props;
        const { formData } = this.state;
        let adses: Ads[] = [];

        switch (nameHtml) {
            case 'searchStore[name]':
                setStateByProperty(this, 'store', nameHtml, { id: valueHtml, name: '' });
                const existStoreName = stores.find(e => e.id === valueHtml);
                if (existStoreName) {
                    setStateByProperty(this, 'city', 'searchStore[city]', { id: existStoreName.cityId, name: '' });
                    setStateByProperty(this, 'district', 'searchStore[district]', { id: existStoreName.districtId, name: '' });
                    setStateByProperty(this, 'name', 'store[name]', existStoreName.name);
                    setStateByProperty(this, 'wardName', 'store[wardName]', existStoreName.wardName);
                    setStateByProperty(this, 'street', 'store[street]', existStoreName.street);
                    setStateByProperty(this, 'streetNumber', 'store[streetNumber]', existStoreName.streetNumber);
                    localStorage.cityId = existStoreName.cityId;
                    localStorage.districtId = existStoreName.districtId;
                    localStorage.wardName = existStoreName.wardName;
                    localStorage.street = existStoreName.street;
                    localStorage.streetNumber = existStoreName.streetNumber;
                } else {
                    setStateByProperty(this, 'city', 'searchStore[city]', { id: '', name: '' });
                    setStateByProperty(this, 'district', 'searchStore[district]', { id: '', name: '' });
                    setStateByProperty(this, 'name', 'store[name]', '');
                    setStateByProperty(this, 'wardName', 'store[wardName]', '');
                    setStateByProperty(this, 'street', 'store[street]', '');
                    setStateByProperty(this, 'streetNumber', 'store[streetNumber]', '');
                }
                this.getData();
                break;
            case 'searchStore[city]':
                setStateByProperty(this, 'city', nameHtml, { id: valueHtml, name: '' });
                setStateByProperty(this, 'district', 'searchStore[district]', { id: '', name: '' });
                setStateByProperty(this, 'wardName', 'searchStore[wardName]', '');
                setStateByProperty(this, 'street', 'searchStore[street]', '');
                setStateByProperty(this, 'streetNumber', 'searchStore[streetNumber]', '');
                localStorage.cityId = valueHtml;
                localStorage.districtId = '';
                localStorage.wardName = '';
                localStorage.street = '';
                this.resetSearchForm();
                break;
            case 'searchStore[district]':
                setStateByProperty(this, 'district', nameHtml, { id: valueHtml, name: '' });
                setStateByProperty(this, 'wardName', 'searchStore[wardName]', '');
                setStateByProperty(this, 'street', 'searchStore[street]', '');
                setStateByProperty(this, 'streetNumber', 'searchStore[streetNumber]', '');
                localStorage.districtId = valueHtml;
                localStorage.wardName = '';
                localStorage.street = '';
                this.resetSearchForm();
                break;
            case 'searchStore[wardName]':
                setStateByProperty(this, 'wardName', nameHtml, valueHtml);
                setStateByProperty(this, 'street', 'searchStore[street]', '');
                setStateByProperty(this, 'streetNumber', 'searchStore[streetNumber]', '');
                localStorage.wardName = valueHtml;
                localStorage.street = '';
                this.resetSearchForm();
                break;
            case 'searchStore[street]':
                setStateByProperty(this, 'street', nameHtml, valueHtml);
                setStateByProperty(this, 'streetNumber', 'searchStore[streetNumber]', '');
                localStorage.street = valueHtml;
                this.resetSearchForm();
                break;
            case 'searchStore[streetNumber]':
                setStateByProperty(this, 'streetNumber', nameHtml, valueHtml);
                const existStoreStreetNumber = stores.find(e => e.streetNumber === valueHtml);
                if (existStoreStreetNumber) {
                    setStateByProperty(this, 'store', nameHtml, { id: existStoreStreetNumber.id, name: '' });
                    setStateByProperty(this, 'city', 'searchStore[city]', { id: existStoreStreetNumber.cityId, name: '' });
                    setStateByProperty(this, 'district', 'searchStore[district]', { id: existStoreStreetNumber.districtId, name: '' });
                    setStateByProperty(this, 'name', 'store[name]', existStoreStreetNumber.name);
                    setStateByProperty(this, 'wardName', 'store[wardName]', existStoreStreetNumber.wardName);
                    setStateByProperty(this, 'street', 'store[street]', existStoreStreetNumber.street);
                } else {
                    setStateByProperty(this, 'store', nameHtml, { id: '', name: '' });
                    setStateByProperty(this, 'city', 'searchStore[city]', { id: '', name: '' });
                    setStateByProperty(this, 'district', 'searchStore[district]', { id: '', name: '' });
                    setStateByProperty(this, 'name', 'store[name]', '');
                    setStateByProperty(this, 'wardName', 'store[wardName]', '');
                    setStateByProperty(this, 'street', 'store[street]', '');
                }
                this.getData();
                break;
            case 'other[note]':
                setStateByProperty(this, 'note', nameHtml, valueHtml);
                this.setState({ disabledDraft: false, disabledSent: false });
                break;
            default:
                const extractNameHTML = interpret(nameHtml);
                if (extractNameHTML) {
                    if (extractNameHTML['name'] === 'adses' &&
                        extractNameHTML['indices'] &&
                        extractNameHTML['indices'].length === 2) { // array of sub field
                        const extractName = extractNameHTML['indices'][1];
                        const extractIndex = extractNameHTML['indices'][0];

                        if (formData.adses) {
                            adses = formData.adses;
                        }
                        if (!adses[extractIndex]) {
                            adses[extractIndex] = new Ads();
                            adses[extractIndex].id = extractIndex;
                        }
                        if (extractName === 'quantity') {
                            adses[extractIndex].quantity = forceAbs(valueHtml);
                            Object.keys(adses).forEach(key => {
                                const ads = adses[key];
                                const existedAds = this.state.adses.find(e => e.id === ads.id);
                                if (existedAds && ads.optionId === 0 && existedAds.options.length) {
                                    const [ firstOption ] = existedAds.options;
                                    adses[key].optionId = firstOption.id;
                                }
                            });
                            setStateByProperty(this, 'adses', nameHtml, adses, 'quantity');
                            this.setState({disabledDraft: false, disabledSent: false });
                        }
                        if (extractName === 'option') {
                            adses[extractIndex].optionId = valueHtml;
                            setStateByProperty(this, 'adses', nameHtml, adses, 'option');
                            this.setState({ disabledDraft: false, disabledSent: false });
                        }
                        if (extractName === 'isReplace') {
                            adses[extractIndex].isReplace = !adses[extractIndex].isReplace;
                            setStateByProperty(this, 'adses', nameHtml, adses, 'isReplace');
                            this.setState({ disabledDraft: false, disabledSent: false });
                        }
                    }
                }
                break;
        }
    }

    fileChangedHandler = () => {
        this.setState({
            disabledDraft: false,
            disabledSent: false
        });
    }

    checkValidCapturePicture = (type: 'draft' | 'sent') => {
        const { adsImageOutsidePreview } = this.state;
        if (type === 'sent') {
            if (!_.isEmpty(adsImageOutsidePreview.filter(e => e))) {
                return true;
            } else {
                return false;
            }
        } else {
            return true;
        }
    }

    // Custom Code
    onSubmit = (type: 'draft' | 'sent') => {
        const isValid = checkValidations(this);
        const { formData } = this.state;

        if (isValid && this.checkValidCapturePicture(type)) {
            Swal.fire({
                html: 'Vui lòng chờ ...<br/><div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>',
                showConfirmButton: false,
                allowOutsideClick: false
            })
            this.props.apiService.postRequestAds(formData, type).then(() => {
                if (type === 'sent') {
                    Swal.fire({
                        ...Constants.SWAL_COMMON_STYLE,
                        text: 'Gửi yêu cầu thành công.',
                        type: 'success',
                        confirmButtonText: 'Đóng'
                    }).then(() => {
                        this.props.history.push('/' + Routes.HomePageRoute.url);
                        this.props.history.push('/' + Routes.RequestAdsPageAddRoute.url);
                    });
                } else {
                    this.getData();
                    Swal.close();
                }
            });
        } else if (!this.checkValidCapturePicture(type)) {
            Swal.fire({
                ...Constants.SWAL_COMMON_STYLE,
                text: 'Bạn cần chụp hình bên ngoài nhà thuốc trước khi gửi yêu cầu.',
                type: 'warning',
                confirmButtonText: 'Đóng'
            })
        }
    }

    // Custom Code
    render() {
        const { fields, formData, disabledDraft, disabledSent, adses, adsImageOutsidePreview, adsImageInsidePreview, adsImageDocumentPreview } = this.state;
        const { settings: { metadata }, match: { params } } = this.props;
        const formFieldsSearchStore = getFormFieldsSearchStore(formData, metadata);
        const formFieldsAdses = getFormFieldsAdses(formData, adses);
        const formFieldsOther = getFormFieldsOther(formData);

        return (
            <div className="request-ads-page">
                <div className="sticky-top">
                    <div className="row pt-2">
                        <div className="col-6">TDV: {metadata.userInfo.name}</div>
                        <div className="col-6 text-right">Nhóm: {metadata.userInfo.groupName}</div>
                    </div>
                    <h3 className="head-title mt-0 mb-0 pt-3">
                        YÊU CẦU THI CÔNG
                    </h3>
                    <div className="action row">
                        {formFieldsSearchStore.map((field, key) =>
                            <div key={`${getIdOrUndefined(formData.city)}-${getIdOrUndefined(formData.district)}-${formData.wardName}-${formData.street}-${formData.streetNumber}-${key}`} className="mt-1 col-6 col-lg-2">
                                <FormItem
                                    {...field.props}
                                    attribute={{disabled: !!(params.storeId)}}
                                    onChangeHandle={this.onChangeHandle}
                                    fields={fields}
                                />
                            </div>
                        )}
                    </div>
                </div>
                <div className="holder">
                    <div className="item-ads row">
                        <div className="col-12 col-sm-8">
                            <div className="item-ads-table mt-3">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>VẬT DỤNG</th>
                                            <th className="text-center">SỐ LƯỢNG</th>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {formFieldsAdses.map((field, key1) =>
                                            <tr key={key1}>
                                                <td>{field.name}</td>
                                                <td>
                                                    <FormItem
                                                        {...field.props}
                                                        onChangeHandle={this.onChangeHandle}
                                                        fields={fields}
                                                    /></td>
                                                <td>{field.unitName}</td>
                                                <td>
                                                    {field.options.map((option, key2) =>
                                                        <React.Fragment key={key2}>
                                                            <FormItem
                                                                {...option.props}
                                                                onChangeHandle={this.onChangeHandle}
                                                                fields={fields}
                                                            />
                                                        </React.Fragment>)}
                                                    <FormItem
                                                        {...field.propsIsReplace}
                                                        onChangeHandle={this.onChangeHandle}
                                                        fields={fields}
                                                    />
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <form id="file" onSubmit={(e) => e.preventDefault()} className="col-12 col-sm-4">
                            <div className="item-ads-table mt-3">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>CHỤP HÌNH BÊN NGOÀI *</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <UploadImgGroup 
                                                    disabled={getIdOrUndefined(formData.store) <= 0} 
                                                    files={formData.adsImageOutside} 
                                                    previews={adsImageOutsidePreview}
                                                    onFileChanged={this.fileChangedHandler}
                                                />
                                                <p>* Hình chụp toàn cảnh <b>bên ngoài</b> nhà thuốc, thấy rõ vị trí sẽ đặt vật dụng quảng cáo.</p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="item-ads-table mt-3">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>CHỤP HÌNH BÊN TRONG</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <UploadImgGroup 
                                                    disabled={getIdOrUndefined(formData.store) <= 0} 
                                                    files={formData.adsImageInside} 
                                                    previews={adsImageInsidePreview}
                                                    onFileChanged={this.fileChangedHandler}
                                                />
                                                <p>* Hình chụp toàn cảnh <b>bên trong</b> nhà thuốc, thấy rõ vị trí sẽ đặt vật dụng quảng cáo.</p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="item-ads-table mt-3">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>CHỤP BBTT</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <UploadImgGroup 
                                                    disabled={getIdOrUndefined(formData.store) <= 0} 
                                                    files={formData.adsImageDocument} 
                                                    previews={adsImageDocumentPreview}
                                                    onFileChanged={this.fileChangedHandler}
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </form>
                        <div className="mt-3 col-12">
                            {formFieldsOther.map((field, key) =>
                                <React.Fragment key={key}>
                                    <FormItem
                                        {...field.props}
                                        onChangeHandle={this.onChangeHandle}
                                        fields={fields}
                                    />
                                </React.Fragment>
                            )}
                        </div>
                    </div>
                </div>
                <div className="sticky-bottom">
                    <div className="group-btn">
                        <button className="btn btn-dark" onClick={() => this.props.history.push('/' + Routes.HomePageRoute.url)}>Home</button>
                        <button disabled={disabledDraft} className="btn btn-dark" onClick={() => this.onSubmit('draft')}>Lưu tạm</button>
                        <button disabled={disabledSent} className="btn btn-primary" onClick={() => this.onSubmit('sent')}>Tạo yêu cầu</button>
                    </div>
                </div>

            </div>
        );
    }
}

const mapStateToProps = ({ settings }) => ({ settings });
export default withRouter(connect(mapStateToProps)(RequestAdsPage));