import React from 'react';
import Error404Img from 'assets/styles/partials/images/404.png'; 
import { connect } from 'react-redux';
import PageProps from 'interfaces';
import * as Route from 'pages/RouteLoader';

class Error404 extends React.Component<PageProps, {}> {
    constructor(props: PageProps) {
        super(props);
    }
 
    render() {
        return (
            <div className="not-found-page" style={{height: '90vh'}}>
                <img src={Error404Img} onClick={() => this.props.history.push('/' + Route.HomePageRoute.url)} />
            </div>
        );
    }
}

const mapStateToProps = () => ({});
const mapDispatchToProps = () => ({});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Error404);

