import React, { useState, useEffect } from 'react';
import { Field, Option } from 'models/Settings';
import {
    FormItemInterface,
    isValidOrNot,
    renderErrorHtml,
    AdvanceOptions,
    enableValidation
} from './index';
import CommonService from 'services/common.service';
import axios from 'axios';

import AsyncCreatableSelect from 'react-select/async-creatable';

interface Props extends FormItemInterface {
    selectAllLabel: string | undefined;
    options: Option[] | undefined;
    advanceOptions: AdvanceOptions | undefined;
}

const TagBoxAsyncGroup = ((props: Props) => {
    const commonService = new CommonService();
    const {
        htmlFor,
        fieldLabel,
        fieldName,
        fieldValidation = new Field(),
        selectAllLabel,
        className = 'select-custom',
        data,
        attribute,
        options = [],
        advanceOptions,
        onChangeHandle,
        htmlLabel
    } = props;

    const [value, setValue] = useState<Option | undefined>(
        options.find(e => e.value === data)
    );
    useEffect(() => {
        const { data } = props;
        const existOption = options.find(e => e.value === data);
        if (data) {
            setValue(existOption ? existOption : {label: String(data), value: String(data)});
        }
    }, [data]);

    const onChange = event => {
        if (event) {
            setValue(event);
            onChangeHandle(fieldName, event.value);
        } else {
            onChangeHandle(fieldName, '');
        }
    };

    const onCreateOption = event => {
        if (event) {
            setValue({label: event, value: event});
            onChangeHandle(fieldName, event);
        } else {
            onChangeHandle(fieldName, '');
        }
    };

    const filterKeywords = (inputValue: string) => {
        return options.filter(i =>
            i.label.toLowerCase().includes(inputValue.toLowerCase())
        );
    };

    const promiseOptions = inputValue => {
        const { advanceOptions, data } = props;
        if (advanceOptions) {
            const {
                apiEndpoint,
                buildOptions,
                buildFilterObject,
                pageSize,
            } = advanceOptions;
            const headers = commonService.getHeader();
            return axios
                .get(apiEndpoint + `${buildFilterObject(inputValue)}&pageSize=${pageSize}`, { headers })
                .then(res => {
                    const options = buildOptions(res.data);
                    const existValue = options.find(e => e.value === data);
                    existValue && setValue(existValue);
                    return options;
                })
                .catch(err => commonService.handleErrorHttp(err));
        } else {
            return new Promise(resolve => {
                resolve(filterKeywords(inputValue));
            });
        }
    };

    const disabled = attribute && attribute.disabled;

    let isClearable = attribute && attribute.isClearable;
    if (attribute && attribute.hasOwnProperty('isClearable')) {
        delete attribute.isClearable;
    } else {
        isClearable = true;
    }

    const {
        isValidType,
        isDirty,
        isValid,
        isVisible,
        isRequired,
        regex,
        customMsg
    } = fieldValidation;
    const errorClass =
        isDirty && enableValidation(attribute) && isValidOrNot(isValid, isRequired, regex, customMsg)
            ? 'error-holder'
            : '';

    const customStyles = {
        control: base => ({
            ...base,
            height: '2em',
            minHeight: '2em'
        })
    };

    return !isVisible ? null : (
        <React.Fragment>
            {fieldLabel && (
                <label htmlFor={htmlFor}>
                    {fieldLabel} {isRequired && <span className="required">*</span>}
                </label>
            )}
            <div className={className + ' ' + errorClass}>
                <AsyncCreatableSelect
                    key={`${JSON.stringify(data)}${JSON.stringify(value)}`}
                    {...attribute}
                    value={value}
                    onChange={onChange}
                    onCreateOption={onCreateOption}
                    className="select-async"
                    name={fieldName}
                    id={htmlFor}
                    cacheOptions
                    defaultOptions={advanceOptions ? true : options}
                    loadOptions={promiseOptions}
                    classNamePrefix="select"
                    isDisabled={disabled}
                    isClearable={isClearable}
                    placeholder={selectAllLabel}
                    styles={customStyles}
                />
                {enableValidation(attribute) && renderErrorHtml(
                    isRequired,
                    isValidType,
                    isDirty,
                    fieldLabel,
                    customMsg,
                    htmlLabel
                )}
            </div>
        </React.Fragment>
    );
});

export default TagBoxAsyncGroup;
