import React from 'react';
import { Input } from 'reactstrap';
import { Field } from 'models/Settings';
import { FormItemInterface, isValidOrNot, renderErrorHtml } from './index';

const CheckBoxGroup = ((props: FormItemInterface) => {
    const {
        htmlFor,
        fieldLabel,
        fieldName,
        fieldValidation = new Field(),
        attribute,
        data,
        className = 'form-check',
        onChangeHandle,
        htmlLabel
    } = props;

    const onChange = event => {
        onChangeHandle(fieldName, event.target.checked);
    };

    const {
        isValidType,
        isDirty,
        isValid,
        isVisible,
        isRequired,
        regex,
        customMsg
    } = fieldValidation;
    const errorClass =
    isDirty && isValidOrNot(isValid, isRequired, regex, customMsg)
        ? 'error-holder'
        : '';

    return !isVisible ? null : (
        <div className={className + ' ' + errorClass}>
            <Input
                {...attribute}
                value={data}
                onChange={onChange}
                id={htmlFor}
                name={fieldName}
                type="checkbox"
            />
            <label className="icon-checked-ico" htmlFor={htmlFor}>
                <span>
                    {fieldLabel} {isRequired && <span className="required">*</span>}
                </span>
            </label>
            {renderErrorHtml(
                isRequired,
                isValidType,
                isDirty,
                fieldLabel,
                customMsg,
                htmlLabel
            )}
        </div>
    );
});

export default CheckBoxGroup;
