import { Field } from "models/Settings";
import { getIdOrUndefined } from "components/Form";
import { Item, Ads } from 'models/Store';
import { getSearchStoreFields } from "pages/RequestStorePage/fields";

export const getFields = () => {
    const fields: Field[] =  [
        ...getSearchStoreFields(),
        {
            key: "itemsOrder.quantity",
            isRequired: false,
            isVisible: true,
            regex: "",
            maxLength: 50,
            name: "Items Order Quantity"
        },
        {
            key: "adses.quantity",
            isRequired: false,
            isVisible: true,
            regex: "",
            maxLength: 50,
            name: "Adses Quantity"
        },
        {
            key: "planStore.isVisited",
            isRequired: false,
            isVisible: true,
            regex: "",
            maxLength: 50,
            name: "Is Visited"
        },
        {
            key: "planStore.date",
            isRequired: true,
            isVisible: true,
            regex: "",
            maxLength: 50,
            name: "Date"
        },
    ];

    return fields;
}

export const getFormFieldsItemsOrder = (formData, items: Item[]) => {
    let formFields: any[] = [];
    items.forEach(item => {

        const itemData: Item = formData.itemsOrder[item.id] ? formData.itemsOrder[item.id] : new Item();
        formFields.push({
            name: item.name,
            unitName: item.unitName,
            props: {
                type: 'number',
                className: 'text-right',
                data: itemData.quantity !== 0 ? itemData.quantity : '',
                htmlFor: 'itemsOrder[' + item.id + '][quantity]',
                fieldName: 'itemsOrder[' + item.id + '][quantity]',
                attribute: {
                    disabled: getIdOrUndefined(formData.store) <= 0
                }
            },
        });
    });

    return formFields;
}

export const getFormFieldsItemsStocked = (formData, items: Item[]) => {
    let formFields: any[] = [];
    items.forEach(item => {

        const itemData: Item = formData.itemsStocked[item.id] ? formData.itemsStocked[item.id] : new Item();
        formFields.push({
            name: item.name,
            unitName: item.unitName,
            props: {
                type: 'number',
                className: 'text-right',
                data: itemData.quantity !== 0 ? itemData.quantity : '',
                htmlFor: 'itemsStocked[' + item.id + '][quantity]',
                fieldName: 'itemsStocked[' + item.id + '][quantity]',
                attribute: {
                    disabled: true
                }
            },
        });
    });

    return formFields;
}

export const getFormFieldsAdses = (formData, adses: Ads[]) => {
    let formFields: any[] = [];
    adses.forEach(item => {

        const adsData: Ads = formData.adses[item.id] ? formData.adses[item.id] : new Ads();
        formFields.push({
            name: item.name,
            unitName: item.unitName,
            props: {
                type: 'number',
                className: 'text-right',
                data: adsData.quantity !== 0 ? adsData.quantity : '',
                htmlFor: 'adses[' + item.id + '][quantity]',
                fieldName: 'adses[' + item.id + '][quantity]',
                attribute: {
                    disabled: getIdOrUndefined(formData.store) <= 0
                }
            },
        });
    });

    return formFields;
}