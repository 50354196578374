import React from "react";
import CommonService from 'services/common.service';
import ApiService from 'services/api.service';

export const services = {
    commonService: new CommonService(),
    apiService: new ApiService()
};

const ApiContext = React.createContext(services);

export default ApiContext;