import React from 'react';
import { connect } from 'react-redux';
import { PageProps } from 'interfaces';
import { Metadata } from 'models/Settings';
import { withRouter } from 'react-router-dom';
import * as Route from 'pages/RouteLoader';
import logo from 'assets/images/hisamitsu_w.png';
import { getTypeName } from 'util/ControlUtils';

class HomePage extends React.Component<PageProps, {}> {
    constructor(props: PageProps) {
        super(props);

        this.state = {
            metadata: new Metadata()
        }
    }

    logout = () => {
        localStorage.token = '';
        localStorage.cityId = '';
        localStorage.districtId = '';

        this.props.history.push('/');
    }

    // Custom Code
    render() {
        const { settings: { metadata, role } } = this.props;

        return (
            <div className="home-page">
                <div className="sticky-top">
                    <div className="row pt-2">
                        <div className="col-6">{role === 'staff' ? 'TDV' : getTypeName(metadata.userInfo)}: {metadata.userInfo.name}</div>
                        <div className="col-6 text-right">Nhóm: {metadata.userInfo.groupName}</div>
                    </div>
                    <h1 className="head-title mt-3 mb-3"><img src={logo} width={200} /></h1>
                </div>
                <div className="holder">
                    {role === 'staff' && <>
                        <div className="group-btn">
                            <button className="btn btn-block btn-primary" onClick={() => this.props.history.push('/' + Route.StaffCalendarPageRoute.url)}>LỊCH CÔNG TÁC</button>
                        </div>

                        <div className="group-btn">
                            <button className="btn btn-block btn-primary" onClick={() => this.props.history.push('/' + Route.RouteHomePageRoute.url)}>KẾ HOẠCH HOẠT ĐỘNG NHÀ THUỐC</button>
                        </div>

                        <div className="group-btn">
                            <button className="btn btn-block btn-primary" onClick={() => this.props.history.push('/' + Route.ReportStorePageAddRoute.url)}>BÁO CÁO HOẠT ĐỘNG NHÀ THUỐC</button>
                        </div>

                        <div className="group-btn">
                            <button className="btn btn-block btn-primary" onClick={() => this.props.history.push('/' + Route.ManagerStoreSearchPageRoute.url)}>XEM THÔNG TIN NHÀ THUỐC</button>
                        </div>

                        <div className="group-btn">
                            <button className="btn btn-block btn-primary" onClick={() => this.props.history.push('/' + Route.RequestAdsPageAddRoute.url)}>YÊU CẦU THI CÔNG</button>
                        </div>

                        <div className="group-btn">
                            <button className="btn btn-block btn-primary" onClick={() => this.props.history.push('/' + Route.RequestPaymentPageAddRoute.url)}>YÊU CẦU TRẢ PHÍ QUẢNG CÁO</button>
                        </div>

                        <div className="group-btn">
                            <button className="btn btn-block btn-primary" onClick={() => this.props.history.push('/' + Route.RequestStorePageRoute.url)}>YÊU CẦU TẠO NHÀ THUỐC MỚI</button>
                        </div>

                        <div className="group-btn">
                            <button className="btn btn-block btn-primary" onClick={() => this.props.history.push('/' + Route.HistoryPageRoute.url)}>LỊCH SỬ LÀM VIỆC</button>
                        </div>

                        <div className="group-btn">
                            <button className="btn btn-block btn-primary" onClick={() => this.props.history.push('/' + Route.DocumentPageRoute.url)}>TÀI LIỆU THAM KHẢO</button>
                        </div>

                        <div className="group-btn">
                            <button className="btn btn-block btn-dark" onClick={() => this.logout()}>ĐĂNG XUẤT</button>
                        </div>
                    </>}

                    {role === 'user' && <>
                        <div className="group-btn">
                            <button className="btn btn-block btn-primary" onClick={() => this.props.history.push('/' + Route.ManagerCalendarPageRoute.url)}>XEM LỊCH CÔNG TÁC</button>
                        </div>

                        <div className="group-btn">
                            <button className="btn btn-block btn-primary" onClick={() => this.props.history.push('/' + Route.ManagerReportSearchPageRoute.url.replace(':type', 'planStore'))}>XEM KẾ HOẠCH HOẠT ĐỘNG NHÀ THUỐC</button>
                        </div>

                        <div className="group-btn">
                            <button className="btn btn-block btn-primary" onClick={() => this.props.history.push('/' + Route.ManagerReportSearchPageRoute.url.replace(':type', 'reportStore'))}>XEM BÁO CÁO HOẠT ĐỘNG NHÀ THUỐC</button>
                        </div>

                        <div className="group-btn">
                            <button className="btn btn-block btn-primary" onClick={() => this.props.history.push('/' + Route.ManagerStoreSearchPageRoute.url)}>XEM THÔNG TIN NHÀ THUỐC</button>
                        </div>

                        <div className="group-btn">
                            <button className="btn btn-block btn-primary" onClick={() => this.props.history.push('/' + Route.ManagerReportSearchPageRoute.url.replace(':type', 'requestAds'))}>DUYỆT YÊU CẦU THI CÔNG</button>
                        </div>

                        <div className="group-btn">
                            <button className="btn btn-block btn-primary" onClick={() => this.props.history.push('/' + Route.ManagerReportSearchPageRoute.url.replace(':type', 'requestPayment'))}>DUYỆT YÊU CẦU TRẢ PHÍ QUẢNG CÁO</button>
                        </div>

                        <div className="group-btn">
                            <button className="btn btn-block btn-primary" onClick={() => this.props.history.push('/' + Route.ManagerRequestStorePageRoute.url)}>DUYỆT YÊU CẦU TẠO NHÀ THUỐC MỚI</button>
                        </div>

                        <div className="group-btn">
                            <button className="btn btn-block btn-primary" onClick={() => this.props.history.push('/' + Route.DocumentPageRoute.url)}>TÀI LIỆU THAM KHẢO</button>
                        </div>

                        <div className="group-btn">
                            <button className="btn btn-block btn-dark" onClick={() => this.logout()}>ĐĂNG XUẤT</button>
                        </div>
                    </>}
                </div>

            </div>
        );
    }
}

const mapStateToProps = ({ settings }) => ({ settings });
export default withRouter(connect(mapStateToProps)(HomePage));