import React from 'react';
import { connect } from 'react-redux';
import { PageProps } from 'interfaces';
import { Field } from 'models/Settings';
import { withRouter } from 'react-router-dom';
import FormItem, { setStateByProperty, checkValidations } from 'components/Form';
import Swal from 'sweetalert2';
import * as Routes from 'pages/RouteLoader';
import { getFields, getFormFields } from './fields';

// Custom Code
import GoogleMap from 'components/GoogleMap';

interface State {
    disabled: boolean;
    fields: Field[];
    
    // Custom Code
    formData: {
        username: string;
        password: string;
    };
    role: 'staff' | 'user';
}

class LoginPage extends React.Component<PageProps, State> {
    constructor(props: PageProps) {
        super(props);

        let fields = getFields();
        fields = fields.map(e => ({
            ...new Field(), ...e,
            isValid: false,
            isValidType: 'required'
        }));

        this.state = {
            disabled: true,
            fields: fields,
            
            // Custom Code
            formData: {
                username: '',
                password: ''
            },
            role: 'staff'
        }
    }

    // Custom Code
    onChangeHandle = (nameHtml, valueHtml) => {
        switch (nameHtml) {
            case 'login[username]':
                setStateByProperty(this, 'username', nameHtml, valueHtml);
                break;
            case 'login[password]':
                setStateByProperty(this, 'password', nameHtml, valueHtml);
                break;
            default:
                break;
        }
        this.setState({
            disabled: false
        });
    }

    // Custom Code
    onSubmit = () => {
        const isValid = checkValidations(this);
        const { formData: { username, password }, role } = this.state;

        if (isValid) {
            Swal.fire({
                html: 'Vui lòng chờ ...<br/><div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>',
                showConfirmButton: false,
                allowOutsideClick: false
            })
            this.props.apiService.login(username, password, role).then((res) => {
                localStorage.token = res.token;
                this.props.history.push('/' + Routes.HomePageRoute.url);
                Swal.close();
            });
        }
    }

    handleChangeRole = (role) => {
        this.setState({role: role});
    }

    // Custom Code
    render() {
        const { fields, formData, disabled } = this.state;
        const { settings: { coord } } = this.props;
        const formFields = getFormFields(formData);
 
        return (
            <div className="login-page">
                <h1 className="head-title mt-0">Đăng nhập</h1>
                <form className="holder" autoComplete="off" onSubmit={(e) => e.preventDefault()}>
                    {formFields.map((field, key) =>
                        <div key={key} className="mt-1 control-group">
                            <FormItem
                                {...field.props}
                                onChangeHandle={this.onChangeHandle}
                                fields={fields}
                            />
                        </div>
                    )}
                    <div style={{ width: '100%', height: '200px' }}>
                        <GoogleMap coords={coord} />
                    </div>


                    <div className="group-btn">
                        <div>
                            <input defaultChecked={true} onChange={() => this.handleChangeRole('staff')} id="staff" type="radio" name="role" /> <label htmlFor="staff">TDV</label> 
                            &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; 
                            <input onChange={() => this.handleChangeRole('user')} id="user" type="radio" name="role" /> <label htmlFor="user">Manager</label>
                        </div>
                        <button disabled={disabled} className="btn btn-primary" onClick={() => this.onSubmit()}>Đăng nhập</button>
                    </div>
                </form>

            </div>
        );
    }
}

const mapStateToProps = ({ settings }) => ({ settings });
export default withRouter(connect(mapStateToProps)(LoginPage));