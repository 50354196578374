import React from 'react';

const Loading: React.SFC<{}> = () => {
    return (
        <div className="loading-grid-holder">
            <div className="lds-grid"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        </div>
    );
};

export default Loading;
